import { AxiosRequestHeaders } from 'axios';
import { apiClient } from '../client';

type Config = {
  id: string;
  headers?: AxiosRequestHeaders;
};

export const deleteWidget = ({ headers, id }: Config) => {
  return apiClient.delete(`/bff/widgets/${id}`, { headers });
};
