import { createContext, FC, ReactNode, useState } from 'react';

type WidgetConstructorContext = {
  currentStep: number;
  changeStep: (step: number) => void;
};
export const StepsContext = createContext<WidgetConstructorContext>({
  currentStep: 1,
  changeStep: () => {},
});

interface StepsContextProviderProps {
  children: ReactNode;
}
export const StepsContextProvider: FC<StepsContextProviderProps> = ({ children }) => {
  const [currentStep, setCurrentStep] = useState<number>(1);
  const changeStep = (step: number) => setCurrentStep(step);

  const contextData = {
    changeStep,
    currentStep,
  };
  return <StepsContext.Provider value={contextData}>{children}</StepsContext.Provider>;
};
