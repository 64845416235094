import {FC, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {Modal, Button, Tooltip, createStyles, Loader, Box} from '@mantine/core';
import { ProductSelectList } from './ProductSelectList';
import { useDispatch, useSelector } from 'react-redux';
// import {
//   selectLoadingProducts,
//   selectNonSelectedProducts,
//   selectSelectedProducts,
// } from './selectors';
import { VideoPlayer } from '../../shared/ui/VideoPlayer';
import styles from './AddProductsModal.module.scss';
import { Product } from './types';
import { VideoResponseDeprecated } from '../Library/LibraryHeader/types';
import {FetchVideosResponse} from "../../api/video/getVideos";
import {useInfiniteQuery, useQuery} from "@tanstack/react-query";
import {queryKey} from "../../shared/query/keys";
import {getTaggedProductsWithVideo} from "../../api/video/products/getTaggedProductsWithVideo";
import {getProducts} from "../../api/products/getProducts";
import {useGetAllProductsWithTaggedVideo} from "../../shared/hooks/tanstackQuery/useGetAllProductsWithTaggedVideo";
import {useVideoTaggedProducts} from "../../shared/hooks/tanstackQuery/useVideoTaggedProducts";
import {queryClient} from "../../api/client";
import {GetTaggedProductsWithVideoResponse} from "../../api/video/products/getAllProductsWithTaggedVideo";

const useStyles = createStyles(() => ({
  modalContent: {
    boxShadow:
      '0px 11px 15px 0px rgba(0, 0, 0, 0.20), 0px 9px 46px 0px rgba(0, 0, 0, 0.12), 0px 24px 28px 0px rgba(0, 0, 0, 0.14)',
  },
}));

interface Props {
  opened: boolean;
  onClose: () => void;
  onSave: (v: {
    toDelete: string[],
    toAdd: string[],
  }) => void;
  video: FetchVideosResponse[0];
}

export const AddProductsModal: FC<Props> = ({ opened, onClose, video, onSave }) => {

  const [changes, setChanges,] = useState<{
    toDelete: string[],
    toAdd: string[],
  }>({
    toDelete: [],
    toAdd: [],
  })


  const { classes } = useStyles();
  const {data: taggedProducts} = useVideoTaggedProducts({
    videoId: video.id,
    enabled: !!video.id,
  })

  const taggedProductsRef = useRef(taggedProducts);

  useEffect(() => {
    taggedProductsRef.current = taggedProducts;
  }, [taggedProducts])


  const {data: allProductsPages, isLoading: isProductsLoading} = useGetAllProductsWithTaggedVideo({
    videoId: video.id,
    enable: !!video.id && opened,
  })

  const allProducts = useMemo(() => {
   return allProductsPages?.pages.flat(2) ?? []
  }, [allProductsPages])

  const allProductsRef = useRef(allProducts);

  useEffect(() => {
    allProductsRef.current = allProducts;
  }, [allProducts])


  const closeModal = () => {
    setChanges({
      toDelete: [],
      toAdd: [],
    })
    queryClient.refetchQueries({
      queryKey: queryKey.taggedProductsWithVideoId({
        videoId: video.id,
      })
    })
    queryClient.refetchQueries({
      queryKey: queryKey.allProductsWithTaggedVideo({
        videoId: video.id,
      })
    })
    onClose();
  };
  const onConfirm = () => {
    onSave(changes)
    closeModal();
  };


  const onChange = useCallback((data: {
    selectedProducts: string[]
    nonSelectedProducts: string[]
  }) => {
    const toAdd = new Set(data.selectedProducts);
    const toDelete = new Set(data.nonSelectedProducts);



    data.nonSelectedProducts.forEach((id) => {
      toAdd.delete(id);
    })
    data.selectedProducts.forEach((id) => {
      toDelete.delete(id);
    })

    const allProductsOnId= allProductsRef.current.reduce((acc, product) => {
      acc[product.id] = product;
      return acc;
    }, {} as Record<string, GetTaggedProductsWithVideoResponse[0]>)

    queryClient.setQueryData<GetTaggedProductsWithVideoResponse>(queryKey.taggedProductsWithVideoId({
      videoId: video.id,
    }), (prevData) => {
      if(!prevData) return prevData;
      const shallowCopy = [...prevData];
      const taggedProductIds = new Set(shallowCopy.map((product) => product.id));
      toAdd.forEach((id) => {
        if (!taggedProductIds.has(id)) {
          shallowCopy.push(allProductsOnId[id]);
        }
      })
      toDelete.forEach((id) => {
        const index = shallowCopy.findIndex((product) => product.id === id);
        if(index !== -1) {
          shallowCopy.splice(index, 1);
        }
      })
      return shallowCopy
    })

    queryClient.setQueryData<{
      pages: GetTaggedProductsWithVideoResponse[]
    }>(queryKey.allProductsWithTaggedVideo({
      videoId: video.id
    }), (prevData) => {
      if(!prevData) return prevData;
      const shallowCopy = prevData.pages.map((page) => {
        return page.map((product) => {
          const isAdded = !toDelete.has(product.id) && (toAdd.has(product.id) || product.isTagged);
          return {
            ...product,
            isTagged: isAdded,
          }})
      })
      return  {
        ...prevData,
        pages: shallowCopy
      }
    })
    setChanges(prevState => {
      const toAdd = new Set([...prevState.toAdd, ...data.selectedProducts]);
      const toDelete = new Set([...prevState.toDelete, ...data.nonSelectedProducts]);
      return {
        toAdd: Array.from(toAdd),
        toDelete: Array.from(toDelete),
      }
    })
  }, [video.id])

  const isShowTransferTooltip = !!taggedProducts?.length && !video.isShoppable

  return (
    <Modal
      id="add-products-modal"
      opened={opened}
      onClose={closeModal}
      withCloseButton={false}
      size="100%"
      classNames={{
        inner: styles.modalInner,
        body: styles.modalBody,
        content: classes.modalContent,
        root: styles.modalRoot,
      }}
      overlayProps={{
        blur: '5px',
      }}
    >
      <Modal.CloseButton size={29} onClick={closeModal} className={styles.closeButton} />
      <div className={styles.root}>
        <div className={styles.video}>
          <VideoPlayer id={video.mux} coverVideo={true} />
        </div>
        <div className={styles.tags}>
          <h2 className={styles.header}>Tag Products</h2>
          <div className={styles.selectedProductListWrapper}>
            {isProductsLoading ? <Box className={styles.loader}>
              <Loader />
            </Box>: <ProductSelectList
              onChange={onChange}
              allProducts={allProducts}
              selectedProducts={taggedProducts}
              isAutoFocus={!video.isShoppable}
            />}
          </div>
          <div className={styles.buttons}>
            <Button variant="default" onClick={closeModal}>
              Cancel
            </Button>
            <Tooltip
              label="Transfer products to Tagged products list to tag them"
              disabled={!isShowTransferTooltip}
            >
              <Button
                onClick={onConfirm}
                loading={isProductsLoading}
                color="green.1"
                className={styles.btn__confirm}
              >
                Confirm
              </Button>
            </Tooltip>
          </div>
        </div>
      </div>
    </Modal>
  );
};
