import {MainVideoFilter, SourceFilter, VideoFilter} from "../../components/Library/LibraryLayout/LibraryLayout";
import {QueryVideoFilter} from "../../api/video/getVideos";

export const videoFilterToVideoQueryFilter = (filter: VideoFilter) => {
  if (filter.type === MainVideoFilter.All) {
    return  QueryVideoFilter.ALL
  } else if (filter.type === MainVideoFilter.Inbox) {
    return QueryVideoFilter.INBOX
  } else if (filter.type === MainVideoFilter.Shoppable) {
    return QueryVideoFilter.SHOPPABLE
  }
  else if (filter.type === MainVideoFilter.Source) {
    if (filter.subFilter?.includes(SourceFilter.Instagram)) {
      return QueryVideoFilter.INSTAGRAM
    } else if (filter.subFilter?.includes(SourceFilter.Upload)) {
      return QueryVideoFilter.UPLOAD
    }
  }
  return QueryVideoFilter.ALL
}
