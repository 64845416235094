import {useQuery} from "@tanstack/react-query";
import {getCollections} from "../../../api/collections/getCollections";
import {queryKey} from "../../query/keys";

export const useGetCollections = () => {
  return useQuery({
    queryKey: queryKey.collections,
    queryFn: async () => getCollections().then((res) => res.data)
  })
}
