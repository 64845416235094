import { FC } from 'react';
import { Menu, Tooltip } from '@mantine/core';
import styles from './WidgetCreationHeader.module.scss';
import { ReactComponent as IconPencilSquare } from 'src/shared/icons/pencilSquare.svg';
import { ReactComponent as IconDuplicate } from 'src/shared/icons/duplicate.svg';
import { ReactComponent as IconDelete } from 'src/shared/icons/delete.svg';
import { ReactComponent as IconArrowDownFilled } from 'src/shared/icons/arrowDownFilled.svg';
import classNames from 'classnames';

interface WidgetMenuProps {
  widgetName: string;
  onRename: () => void;
  onDelete: () => void;
  onDuplicate: () => void;
  disableRenameBtn?: boolean;
  disableCopyBtn?: boolean;
  disableDeleteBtn?: boolean;
}
export const WidgetMenu: FC<WidgetMenuProps> = ({
  widgetName,
  onRename,
  onDelete,
  onDuplicate,
  disableCopyBtn,
  disableDeleteBtn,
  disableRenameBtn,
}) => {
  const iconRenameStyles = classNames({ [styles.disabledIcon]: disableRenameBtn });
  const iconCopyStyles = classNames({ [styles.disabledIcon]: disableCopyBtn });
  const iconDeleteStyles = classNames({ [styles.disabledIcon]: disableDeleteBtn });
  const disableButtonText = classNames(styles.deleteBtn__text, {
    [styles.disabledText]: disableDeleteBtn,
  });
  return (
    <>
      <Menu width={163} position="right-start" offset={10}>
        <Menu.Target>
          <div className={styles.menu__target}>
            <Tooltip label={widgetName}>
              <span>{widgetName}</span>
            </Tooltip>
            <IconArrowDownFilled />
          </div>
        </Menu.Target>
        <Menu.Dropdown className={styles.dropdown}>
          <Menu.Item
            className={styles.dropdown__item}
            onClick={onRename}
            icon={<IconPencilSquare className={iconRenameStyles} />}
            disabled={disableRenameBtn}
          >
            <span>Rename</span>
          </Menu.Item>
          <Menu.Item
            className={styles.dropdown__item}
            onClick={onDuplicate}
            icon={<IconDuplicate className={iconCopyStyles} />}
            disabled={disableCopyBtn}
          >
            <span>Duplicate</span>
          </Menu.Item>
          <Menu.Item
            className={styles.dropdown__item}
            onClick={onDelete}
            icon={<IconDelete className={iconDeleteStyles} />}
            disabled={disableDeleteBtn}
          >
            <span className={disableButtonText}>Delete</span>
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </>
  );
};
