import { Thumbnail } from 'src/shared/ui/Thumbnail/Thumbnail';

import styles from './WidgetCardGalleryStories.module.scss'

import { FC } from 'react'
import { ThumbnailProps } from 'src/shared/ui/Thumbnail/Thumbnail';

export const WidgetCardGalleryStories: FC<ThumbnailProps> = (props) => {
    const { thumbnail } = props

    return (
      <div className={styles.wrapper}>
        {/*TODO webp here*/}
        {thumbnail ? (
          <picture>
            <Thumbnail {...props} />
          </picture>
        ) : null}
      </div>
    );
  };