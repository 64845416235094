import { Box, createStyles, Image, Portal, Stepper } from '@mantine/core';
import { useContext, useState } from 'react';
import {
  StepOne,
  StepThree,
  StepThreeError,
  StepThreeSuccess,
  StepTwo,
} from './Installation.steps';
import { useSelector } from 'react-redux';
import { selectActiveWidget } from 'src/components/Widget/selectors';
import { IconCircleX } from '@tabler/icons-react';
import installationStepOne from 'src/shared/images/installation-steps/activate-chatpoint.gif';
import installationStepTwo from 'src/shared/images/installation-steps/add-widget-block.gif';
import { InstallationContext } from './InstallationProvider';

const renderPreviewInstruction = (step: number) => {
  switch (step) {
    case 0:
      return <Image src={installationStepOne} alt="installation first step" />;
    case 1:
      return <Image src={installationStepTwo} alt="installation second step" />;
    default:
      return null;
  }
};
const useStyles = createStyles((theme) => ({
  stepIcon: {
    '&[data-progress]': {
      border: `2px solid ${theme.colors.green[1]}`,
    },
    '&[data-completed]': {
      backgroundColor: theme.colors.green[1],
      border: `2px solid ${theme.colors.green[1]}`,
    },
  },
  verticalSeparatorActive: {
    borderColor: theme.colors.green[1],
  },
  stepLabel: {
    color: theme.colors.greyLight[2],
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '16px',
    marginTop: '11px',
  },
  previewContainer: {
    padding: '28px 48px',
    display: 'flex',
    justifyContent: 'center',
    flexGrow: 1,
  },
  stepper__completedStep: {
    paddingLeft: '55px',
    marginTop: '-44px',
  },
}));
export const Installation = () => {
  const { classes } = useStyles();
  const { loading, checked, checkInstallation, error, data } = useContext(InstallationContext);
  const [activeStep, setActiveStep] = useState(0);
  const activeWidget = useSelector(selectActiveWidget);

  const stepThreeCallback = () => setActiveStep(3);
  return (
    <Box>
      <Stepper
        active={activeStep}
        onStepClick={setActiveStep}
        breakpoint="sm"
        orientation="vertical"
        allowNextStepsSelect={false}
        classNames={{
          stepIcon: classes.stepIcon,
          verticalSeparatorActive: classes.verticalSeparatorActive,
          stepLabel: classes.stepLabel,
        }}
      >
        <Stepper.Step
          description={activeStep === 0 && <StepOne nextStep={() => setActiveStep(1)} />}
          label={activeStep !== 0 && 'Activate VideoPoint'}
          allowStepClick={false}
        />
        <Stepper.Step
          description={
            activeStep === 1 && (
              <StepTwo
                nextStep={() => setActiveStep(2)}
                widgetId={activeWidget?.id ?? 'widgetId'}
              />
            )
          }
          label={activeStep !== 1 && 'Add widget block'}
          allowStepClick={false}
        />
        <Stepper.Step
          description={
            activeStep === 2 && (
              <StepThree
                checkInstallation={() => checkInstallation(stepThreeCallback)}
                checked={checked}
              />
            )
          }
          allowStepClick={false}
          label={activeStep !== 2 && 'Check widget installation'}
          loading={loading}
          color={error ? 'red.0' : 'green.0'}
          completedIcon={error && <IconCircleX size="1.1rem" />}
        />
        <Stepper.Completed>
          <Box className={classes.stepper__completedStep}>
            {error && <StepThreeError prevStep={() => setActiveStep(2)} contact={() => {}} />}
            {!error && activeWidget?.id && (
              <StepThreeSuccess
                preview_url={data?.preview_url ?? 'url'}
                themeName={data?.theme_name ?? 'default'}
                template={data?.page_type ?? 'default'}
                widgetId={activeWidget.id}
              />
            )}
          </Box>
        </Stepper.Completed>
      </Stepper>
      <Portal target="#tabs-portal">
        <Box className={classes.previewContainer}>{renderPreviewInstruction(activeStep)}</Box>
      </Portal>
    </Box>
  );
};
