import { Box, Button, createStyles, Modal, Text } from '@mantine/core';
import { FC } from 'react';

interface ConfirmDeletionModalProps {
  open: boolean;
  onClose: () => void;
  onCancel: () => void;
  onConfirm: () => void;
  title: string;
  bodyText: string;
}

const useStyles = createStyles(() => ({
  cancelBtn: {
    color: '#fff',
    padding: '7px 19px',
    height: '36px',
    marginRight: '8px',
    backgroundColor: '#25262b',
    border: '1px solid #373a40',
    ' &:hover': {
      backgroundColor: '#373a40',
    },
  },
  btnContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
  },
  modalContent: {
    border: '1px dashed #373a40',
  },
  modalBodyText: {
    color: '#c1c2c5',
    fontFamily: '"Segoe UI", sans-serif',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '21px',
    marginBottom: '16px',
  },
}));

export const ConfirmDeletionModal: FC<ConfirmDeletionModalProps> = ({
  open,
  onClose,
  onCancel,
  onConfirm,
  title,
  bodyText,
}) => {
  const { classes } = useStyles();
  return (
    <Modal
      id="confirm-deletion-modal"
      opened={open}
      onClose={onClose}
      title={title}
      centered
      classNames={{
        content: classes.modalContent,
      }}
    >
      <Box>
        <Text className={classes.modalBodyText}>{bodyText}</Text>
        <Box className={classes.btnContainer}>
          <Button onClick={onCancel} mr={16} className={classes.cancelBtn}>
            Cancel
          </Button>
          <Button variant="dangerous" onClick={onConfirm} >
            Confirm
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
