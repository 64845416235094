import {useInfiniteQuery} from "@tanstack/react-query";
import {queryKey} from "../../query/keys";
import {getWidgets} from "../../../api/widget/getWidgets";

export const useGetWidgets = () => {
  return useInfiniteQuery({
    queryKey: queryKey.widgetsInfinite,
    queryFn: async () => {
      return getWidgets().then((res) => res.data);
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages) => {
      return 0;
    }
  })
}
