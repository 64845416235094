import { AxiosRequestConfig } from 'axios';
import { apiClient } from '../client';

interface Config extends AxiosRequestConfig {
  instagramId: string;
  longTermToken: string;
}

type Page = {
  access_token: string;
  category: string;
  category_list: {
    id: string;
    name: string;
  }[];
  name: string;
  id: string;
  tasks: string[];
  businessAccountId?: string | null;
};

export type SaveInstagramResponse = {
  pagesWithAccount: Page[];
};
export const saveInstagram = ({ instagramId, longTermToken }: Config) =>
  apiClient.post<SaveInstagramResponse>(`/bff/connect_instagram`, { instagramId, longTermToken });
