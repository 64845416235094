
//eslint-disable-next-line
// @ts-ignore
window.dataLayer = window.dataLayer || [];
//eslint-disable-next-line
// @ts-ignore
const dataLayer = window.dataLayer || [];

interface PushCurrentUserToIntercomRequest {
    email: string,
    userId: string,
    created_at?: string,
    name: string
    user_hash: string
}

export const pushCurrentUserToIntercom =  (data: PushCurrentUserToIntercomRequest) => {
  dataLayer.push({
    'event': 'user',
    'logged_in' : true, //either true or false (boolean)
    'email': data.email, //required
    'user_id': data.userId, //required
    'created_at': data.created_at, //optional
    'name': data.name,
    'user_hash': data.user_hash
  });
}