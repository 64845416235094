import { VideoResponseDeprecated } from './LibraryHeader/types';
import styles from './LibraryLayout/LibraryLayout.module.scss';
import classNames from 'classnames';
import {MainVideoFilter, SourceFilter, VideoFilter} from './LibraryLayout/LibraryLayout';

export const filterVideos = (videos: VideoResponseDeprecated[]) => {
  const shoppableVideos: VideoResponseDeprecated[] = [];
  const inboxVideos: VideoResponseDeprecated[] = [];
  const instagramVideos: VideoResponseDeprecated[] = [];
  const uploadedVideos: VideoResponseDeprecated[] = [];
  videos.forEach((vid) => {
    if (vid.products.length > 0) {
      shoppableVideos.push(vid);
    } else {
      inboxVideos.push(vid);
    }
    if(vid.type === 'Instagram'){
      instagramVideos.push(vid);
    } else  if (vid.type === 'Upload'){
      uploadedVideos.push(vid);
    }
  });
  return { shoppableVideos, inboxVideos, instagramVideos, uploadedVideos };
};

export const getLinkStyles = (currentFilter: VideoFilter | null, require: VideoFilter) => {

  const isActiveMainFilter = currentFilter?.type === require.type;
  let isActiveSubFilter = true;
  if(currentFilter?.subFilter && require?.subFilter){
    isActiveSubFilter = currentFilter.subFilter.some((subFilter) => {
      return require.subFilter?.includes(subFilter);
    });
  }
  const isActive = isActiveMainFilter && isActiveSubFilter;

  return classNames(styles.filter__item, {
    [styles.filter__itemActive]: isActive,
  });
};
