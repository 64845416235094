import React from 'react';
import styles from "../LoginScreen/LoginScreen.module.scss";
import FacebookLogin from "@greatsumini/react-facebook-login";
import {useOnSuccessMetaConnected} from "../../shared/hooks/useOnSuccessMetaConnected";
import {useNavigate} from "react-router-dom";
import {SuccessResponse} from "@greatsumini/react-facebook-login/dist/types/response.type";

interface FacebookConnectWrapperProps {
  children: React.ReactNode;
  onSuccessfulInstagramConnection?: () => void;
}

export const FacebookConnectWrapper: React.FC<FacebookConnectWrapperProps> = ({
                                                                                children,
                                                                                onSuccessfulInstagramConnection
                                                                              }) => {
  const {onSuccess, scope} = useOnSuccessMetaConnected();
  const onSuccessHandler = async (response: SuccessResponse) => {
    await onSuccess(response, {
      onSuccess: () => {
        onSuccessfulInstagramConnection && onSuccessfulInstagramConnection();
      }
    });

  }
  return (
    <FacebookLogin
      appId="2769783553336873"
      initParams={{
        version: 'v16.0',
      }}
      onSuccess={onSuccessHandler}
      scope={scope}
      className={styles.reset}
      loginOptions={{
        auth_type: 'rerequest',
      }}
    >
      {children}
    </FacebookLogin>
  );
};

