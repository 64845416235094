import { uploadVideo } from 'src/api/video/uploadVideo';
import { SuccessResponse } from '@greatsumini/react-facebook-login/dist/types/response.type';
import { patchMe } from '../../api/me/patchMe';

interface Media {
  media_url: string;
  media_type: 'IMAGE' | 'VIDEO';
  caption: string;
}

export interface FacebookUser {
  accessToken?: string;
  data_access_expiration_time?: number;
  expiresIn?: number;
  graphDomain?: string;
  id?: string;
  name?: string;
  signedRequest?: string;
  userID?: string;
}
declare global {
  interface Window {
    fbAsyncInit: () => void;
    FB: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      getLoginStatus(arg0: (response: any) => Promise<void>): unknown;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      XFBML: any;
      init: (params: { appId: string; cookie?: boolean; xfbml?: boolean; version: string }) => void;
    };
  }
}

export const initFacebookSDK = (): Promise<boolean> => {
  return new Promise((resolve) => {
    try {
      window.fbAsyncInit = function () {
        window.FB.init({
          appId: '2769783553336873',
          cookie: false,
          xfbml: true,
          version: 'v17.0',
        });
      };
      (function (d, s, id) {
        const fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {
          return;
        }
        const js = d.createElement(s) as HTMLScriptElement;
        js.id = id;
        js.src = 'https://connect.facebook.net/en_US/sdk.js';
        js.onload = () => {
          resolve(true);
        };
        fjs.parentNode?.insertBefore(js, fjs);
      })(document, 'script', 'facebook-jssdk');
      resolve(true);
    } catch (err) {
      resolve(false);
    }
  });
};

export const renderFacebookButton = (): void => {
  window.FB.XFBML.parse();
};

async function uploadVideosWithInterval(
  videos: { url: string; title: string }[],
  interval: number
) {
  await uploadVideo(videos);
  await new Promise((resolve) => setTimeout(resolve, interval * 1000));
}
