import { useSelector, useDispatch } from 'react-redux';

import { Group, Menu, ActionIcon } from '@mantine/core';
import { IconChevronDown } from '@tabler/icons-react';

import { GradientButton } from 'src/components/GradientButton/GradientButtom';

import { widgetsActions } from 'src/components/Widget/store';
import { selectSelectedWidgets } from 'src/components/Widget/selectors';

import { FC } from 'react'
import {Widget, WidgetStatus} from 'src/components/Widget/types';
import {useGetWidgets} from "../../../../shared/hooks/tanstackQuery/useGetWidgets";
import {usePatchBulkWidgets} from "../../../../shared/hooks/tanstackQuery/usePatchBulkWidgets";
import {patchWidgetsBulk} from "../../../../api/widget/patchWidgetsBulk";
import {useFeatureRestriction} from "../../../../shared/hooks/useFeatureRestriction";

export const BulkActions:FC = () => {

  const {data: allWidgetsPages} = useGetWidgets();
  const allWidgets = allWidgetsPages?.pages.flat(2) as  Widget[]  || [];
  const selectedWidgets = useSelector(selectSelectedWidgets);
  const  {mutate: patchWidgets} = usePatchBulkWidgets()
  const restrictions = useFeatureRestriction()
  const bulkHandler = (newStatus: WidgetStatus) => {
    if(restrictions.isLoading) {
      return
    }
    const ids = selectedWidgets
    .filter((id) => {
      const currentWidget = allWidgets.find((widget) => widget.id === id)
      if (currentWidget) {
        return [WidgetStatus.draft, WidgetStatus.published].includes(currentWidget.status) &&  currentWidget.status !== newStatus
      }

      return false;
    });

    if(newStatus === WidgetStatus.published) {
      restrictions.onPublishWidget(() => {
        patchWidgets({ids, data: {status: newStatus}})
      }, ids.length)
      return;
    }
    patchWidgets({ids, data: {status: newStatus}})
  }

  const hasPublished = selectedWidgets.some((id) => allWidgets.find((widget) => widget.id === id)?.status === WidgetStatus.published);
  const hasUnpublished = selectedWidgets.some((id) => allWidgets.find((widget) => widget.id === id)?.status === WidgetStatus.draft);


  if (!hasPublished && !hasUnpublished) {
    return null
  }
  return (
    <Group spacing={1}>
      <GradientButton onClick={() => bulkHandler(WidgetStatus[hasUnpublished ? 'published' : 'draft'])} sx={{ borderRadius: hasPublished && hasUnpublished ? '4px 0px 0px 4px' : undefined }}>
        {hasUnpublished ? 'Publish' : 'Unpublish'}
      </GradientButton>

      {hasPublished && hasUnpublished ?
        <Menu transitionProps={{ transition: 'pop' }} position="bottom-end" withinPortal>
          <Menu.Target>
            <ActionIcon size={36} variant="gradient" gradient={{ from: '#00ad60', to: '#1222b2', deg: 225 }} sx={{ borderRadius: '0px 4px 4px 0px', marginLeft: '0.5px' }}>
              <IconChevronDown stroke={1.5} />
            </ActionIcon>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Item onClick={() => bulkHandler(WidgetStatus.draft)}>Unpublish</Menu.Item>
          </Menu.Dropdown>
        </Menu>
      : null}
    </Group>
  )
}
