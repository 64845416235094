import { queryClient } from 'src/api/client';
import { QUERY_KEY } from 'src/shared/consts/queryKey';

import { OnboardingResponce } from 'src/shared/types/responses/onboarding';

export const checkOnboardingData = () => {
  const onboardingData: OnboardingResponce | undefined = queryClient.getQueryData([QUERY_KEY.ONBOARDING])

  return onboardingData
}
