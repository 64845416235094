import { Accordion, Box, createStyles, Portal, ScrollArea } from '@mantine/core';
import {useEffect, useState} from 'react';
import { BehaviorSettings } from './Behavior/BehaviorSettings';
import { BrandingSettings } from './Branding/BrandingSettings';
import { BuyButtonSettings } from './BuyButton/BuyButtonSettings';
import { CarouselLayoutSettings } from './CarouselLayout/CarouselLayoutSettings';
import { ShoppingCardPreview } from './Previews/ShoppingCard';
import { BrandingPreview } from './Previews/Branding';
import { LayoutPreview } from './Previews/Layout';
import { useSelector } from 'react-redux';
import { selectActiveWidget } from 'src/components/Widget/selectors';
import {ProductDetailsSettings} from "./ProductDetails/ProductDetailsSettings";
import {ProductCardPreview} from "./Previews/ProductCardPreview";
import {useQueryClient} from "@tanstack/react-query";
import {queryKey} from "../../../../../shared/query/keys";
import {getPreviewProduct} from "../../../../../api/widget/getPreviewProduct";

const sections = [
  {
    value: 'carouselLayout',
    title: 'Carousel Layout',
    component: <CarouselLayoutSettings />,
  },
  {
    value: 'behavior',
    title: 'Behavior',
    component: <BehaviorSettings />,
  },
  {
    value: 'productDetails',
    title: 'Product details',
    component: <ProductDetailsSettings />
  },
  {
    value: 'buyButton',
    title: 'Buy button',
    component: <BuyButtonSettings />,
  },
  // {
  //   value: 'branding',
  //   title: 'Branding',
  //   component: <BrandingSettings />,
  // },
];
const renderPreview = (section: string | null) => {
  switch (section) {
    case 'buyButton':
      return <ProductCardPreview mode="buy-button" />;
    case 'branding':
      return <BrandingPreview />;
    case 'productDetails':
      return <ProductCardPreview mode="product-details" />;
    default:
      return <LayoutPreview />;
  }
};
const useStyles = createStyles((theme) => ({
  accordionWrapper: {
    width: '435px',
  },
  accordionItem: {
    backgroundColor: theme.colors.greyDark[5],
    '&[data-active="true"]': {
      backgroundColor: theme.colors.black[0],
      border: `1px solid ${theme.colors.greyDark[1]}`,
    },
  },
  accordionChevron: { color: theme.colors.whitePrimary },
  accordionLabel: { color: theme.colors.whitePrimary },
  accordionContent: {
    padding: 0,
  },
  scrollableArea__content: {
    padding: '0 16px 0 18px',
  },
}));

export const Settings = () => {
  const queryClient = useQueryClient();
  useEffect(() => {
    queryClient.prefetchQuery({
      queryKey: queryKey.widgetPreviewProductFn,
      queryFn: () => {
        return getPreviewProduct().then((response) => response.data);
      },
    })
    queryClient.prefetchQuery({
      queryKey: queryKey.widgetPreviewProduct,
      queryFn: () => {
        return getPreviewProduct().then((response) => response.data);
      },
    })
  }, []);
  const { classes } = useStyles();
  const [section, setSection] = useState<string | null>('carouselLayout');

  const activeWidget = useSelector(selectActiveWidget);
  const widgetType = activeWidget?.settings.general.widgetViewType;

  if (widgetType === 'stories') {
    sections[0].title = 'Stories Layout';
  } else {
    sections[0].title = 'Carousel Layout';
  }

  return (
    <Box id="settingsAccordionWrapper" className={classes.accordionWrapper}>
      <Accordion
        value={section}
        onChange={(val) => setSection(val)}
        variant="separated"
        classNames={{
          item: classes.accordionItem,
          label: classes.accordionLabel,
          chevron: classes.accordionChevron,
          content: classes.accordionContent,
        }}
      >
        <Box>
          {sections.map(({ value, title, component }) => (
            <Accordion.Item value={value} key={value}>
              <Accordion.Control>{title}</Accordion.Control>
              <Accordion.Panel>
                <ScrollArea.Autosize
                  mx="auto"
                  placeholder={''}
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                >
                 <Box className={classes.scrollableArea__content}>{component}</Box>
                </ScrollArea.Autosize>
              </Accordion.Panel>
            </Accordion.Item>
          ))}
        </Box>
      </Accordion>

      {/* temporary preview */}
      <Portal target="#tabs-portal">
        <Box
          sx={() => ({
            // intentionally left inline styles as this components will be deleted
            padding: '28px 48px 20px 0',
            display: 'flex',
            flexGrow: 1,
          })}
        >
          {renderPreview(section)}
        </Box>
      </Portal>
    </Box>
  );
};
