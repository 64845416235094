import { MantineTheme } from '@mantine/core';
import type { ButtonStylesParams, ContextStylesParams } from '@mantine/core';

const getVariant = (theme: MantineTheme, variant?: string) => {
  switch (variant) {
      case 'outline':
        return {
          root: {
            color: theme.colors.greyLight[2],
            backgroundColor: theme.colors.greyDark[5],
            borderColor: theme.colors.greyDark[1],
            fontWeight: 400,
          }
        };

      case 'filled':
        return {
          root: {
            '&:hover': {
              backgroundColor: '#01854a',
            },
          }
        };

    case 'dangerous':
      return {
        root: {
          backgroundColor: '#E03131',
          '&:hover': {
            backgroundColor: '#c92a2a',
          },
          color: '#FFFFFF',
        }
      };
  }
}

export const buttonOverride = {
  Button: {
    styles: (theme: MantineTheme, params: ButtonStylesParams, context: ContextStylesParams) => ({
      ...(getVariant(theme, context.variant) || {}),
    }),
  },
};
