import {apiClient} from "../client";

interface PostCreateVideoFromFileRequest {
  videoName: string;
  lastModified: number;
}

export const postCreateVideoFromFile=(body: PostCreateVideoFromFileRequest)=>  apiClient.post<{
  videoId: string
  uploadUrl: string
  uploadId: string
}>('/bff/video/file/create', body);
