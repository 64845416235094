import {useQuery} from "@tanstack/react-query";
import {queryKey} from "../../query/keys";
import {getTaggedProductsWithVideo} from "../../../api/video/products/getTaggedProductsWithVideo";

export const useVideoTaggedProducts = ({
                                         videoId,
                                         enabled,
                                       }: {
  videoId: string
  enabled: boolean
}) => {
  return useQuery({
    queryKey: queryKey.taggedProductsWithVideoId({
      videoId: videoId,
    }),
    enabled: enabled,
    queryFn: () => {
      return getTaggedProductsWithVideo({videoId}).then((response) => response.data);
    },
  })
}
