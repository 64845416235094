import React, {FC, startTransition, useCallback, useState} from "react";
import {Box, Button, Checkbox, createStyles, Skeleton, Text} from "@mantine/core";


const useStyles = createStyles((theme) => ({
  imageContainer: {
    width: 56,
    height: 56,
    marginRight: 16,
  },
  image: {
    // width: 57,
    // height: 57,
    objectFit: 'cover',
    borderRadius: 4,
    imageRendering: 'pixelated',
  },
  hiddenImage: {
    opacity: 0,
  },
  selectItem: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '4px 12px 4px 10px',
    borderRadius: '4px',
    cursor: 'pointer',
    height: '67px',
    '&:not(:last-child)': {
      marginBottom: '4px',
    },
    '&[data-selected="true"]': {
      backgroundColor: theme.colors.greyDark[3],
    },
    '&:hover': {
      backgroundColor: theme.colors.greyDark[3],
    },
  },
  selectItem__infoWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  selectDropdown__buttonsWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '12px 17px',
  },
  checkBox: {
    '& input': {
      cursor: 'pointer',
    },
  }
}));

export interface SelectItemProps {
  onClick: (props: {
    value: string
    checked: boolean,
  }) => void;
  checked?: boolean;
  image: string | null;
  title: string;
  value: string;
  withCheckboxes?: boolean;
}


export const SelectItem: FC<SelectItemProps> = React.memo(({onClick, checked, image, title, value, withCheckboxes}) => {
  const {classes} = useStyles();

  const handleCheckboxChange = useCallback(() => {
    onClick({value: value, checked: !checked});
  }, [checked, onClick, value])

  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const handleImageLoad = useCallback(() => {
    startTransition(()=>{
      setIsImageLoaded(true);
    })
  }, []);
const modifiedImage = image ? `${image}&width=114` : undefined;
  return (
    <Box  className={classes.selectItem} onClick={handleCheckboxChange} data-selected={checked}>
      <Box className={classes.selectItem__infoWrapper}>
        <Box className={classes.imageContainer}>
          <img src={modifiedImage} onLoad={handleImageLoad} key={image} width="57px" height="57px" loading="lazy"  className={classes.image}
                alt="product image"/>
          {!isImageLoaded && <Skeleton w="100%" h="100%"/>}
        </Box>
        <Text color="greyLight.2">{title}</Text>
      </Box>
      {withCheckboxes && <Checkbox
        className={classes.checkBox}
        checked={checked}
        color="green.1"
        onChange={handleCheckboxChange}
      />}
    </Box>
  );
});

interface SelectDropdownButtonsProps {
  onCancel: () => void;
  onConfirm: () => void;
  disableConfirmButton: boolean;
}

export const SelectDropdownButtons: FC<SelectDropdownButtonsProps> = ({
                                                                        onCancel,
                                                                        onConfirm,
                                                                        disableConfirmButton,
                                                                      }) => {
  const {classes} = useStyles();
  return (
    <Box className={classes.selectDropdown__buttonsWrapper}>
      <Button variant="default" mr={12} onClick={onCancel}>
        Cancel
      </Button>
      <Button color="green.1" onClick={onConfirm} disabled={disableConfirmButton}>
        Save
      </Button>
    </Box>
  );
};
