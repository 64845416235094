import React, {FC, forwardRef, PropsWithChildren, useEffect} from 'react';
import { Box, createStyles, Skeleton, Flex } from '@mantine/core';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import {
  selectWidgetSettingsLayout,
  selectWidgetSettingsGeneral,
} from 'src/components/Widget/selectors';
import { widgetSettingsInit } from 'src/components/Widget/store';
import { WidgetViewType } from 'src/components/Widget/types';
import { CarouselSkeletonFooter } from './components/CarouselSkeletonFooter';
import { StoriesSkeletonFooter } from './components/StoriesSkeletonFooter';

import styles from './DesktopView.module.scss'

const useStyles = createStyles((theme) => ({
  root: {
    position: 'relative',
    border: `5px solid ${theme.colors.greyDark[1]}`,
    '&:before': {
      content: '""',
      position: 'absolute',
      top: 1,
      right: 1,
      left: 1,
      bottom: 1,
      border: `1px solid ${theme.colors.greyDark[1]}`,
    },
  },
  content: {
    position: 'absolute',
    top: 1,
    right: 1,
    left: 1,
    bottom: 1,
    border: `1px solid ${theme.colors.greyDark[1]}`,
    padding: '8px 40px',
    overflowX: 'hidden',
  },
}));
interface DesktopViewProps {
  children: React.ReactNode;
  getViewport: (ref: HTMLDivElement | null) => void;
}
export const DesktopView: FC<DesktopViewProps> =  (props) => {
  const { children, getViewport } = props;
  const viewportRef = React.useRef<HTMLDivElement>(null);
  useEffect(() => {
    getViewport(viewportRef.current);
  }, []);

  const { classes } = useStyles();
  const layout = useSelector(selectWidgetSettingsLayout) ?? widgetSettingsInit.layout;
  const { widgetViewType } = useSelector(selectWidgetSettingsGeneral) ?? widgetSettingsInit.general;
  return (
    <Box
      ref={viewportRef}
      className={classNames(
      classes.root,
      styles.wrapper,
    )}>
      <Box
        className={classes.content}
        sx={{
          backgroundColor: layout.background,
        }}
      >
        <Flex mb={6}>
          <Skeleton height={45} width={45} radius="md" mr={12} />
          <Flex direction="column" sx={{ flexGrow: 1 }}>
            <Skeleton width="100%" height={4} mb={6} />
            <Skeleton width="100%" height={4} mb={6} />
            <Skeleton width="100%" height={4} mb={6} />
            <Skeleton width="45%" height={4} />
          </Flex>
        </Flex>
        <Box w="100%">
          {children}
        </Box>
        {widgetViewType === WidgetViewType.stories ? (
          <StoriesSkeletonFooter size={layout.size} showHeading={layout.showHeading} />
        ) : null}
        {widgetViewType === WidgetViewType.carousel ? (
          <CarouselSkeletonFooter
            size={layout.size}
            showDots={layout.showDots}
            showHeading={layout.showHeading}
          />
        ) : null}
      </Box>
    </Box>
  );
}
