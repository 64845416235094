import { Box, createStyles, Radio, Text } from '@mantine/core';
import { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectActiveWidget } from 'src/components/Widget/selectors';
import { TargetingSelect } from './TargetingSelect';
import { PageType } from 'src/components/Widget/types';
import { SelectedPageItem } from './SelectedPageItem';
import { widgetsActions } from 'src/components/Widget/store';
import { usePagesData } from 'src/shared/hooks/usePagesData';
import { useTargetingSelect } from 'src/shared/hooks/useTargetingSelect';
import {sendEventWidgetTargetingSelectedEvent} from "../../../../../shared/eventLogger/eventLogger";

const productData = [
  {
    value: 'all',
    label: 'All product pages',
  },
  {
    value: 'specific',
    label: 'Select specific product page(s)',
  },
];
const collectionsData = [
  {
    value: 'all',
    label: 'All collection pages',
  },
  {
    value: 'specific',
    label: 'Select specific collection page(s)',
  },
];

const useStyles = createStyles(() => ({
  selectedPagesList: {
    display: 'flex',
    flexDirection: 'column',
    width: '320px',
  },
}));
export const Targeting = () => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const activeWidget = useSelector(selectActiveWidget);
  const [currentOption, setCurrentOption] = useState<string>(
    activeWidget?.allPages ? 'all' : 'specific'
  );
  const { widgetPagesToRender } = usePagesData();
  const { onCancel } = useTargetingSelect();

  const options = useMemo(
    () => (activeWidget?.page === 'collection' ? collectionsData : productData),
    [activeWidget?.page]
  );

  const onDelete = (id: string) => {
    if (!activeWidget) return;
    const filteredPages = activeWidget.pages.filter((pageId) => pageId !== id);
    dispatch(widgetsActions.setPages(filteredPages));
    onCancel();
  };
  const onChange = (value: string) => {
    if (!activeWidget) return;
    sendEventWidgetTargetingSelectedEvent({targetingType: value})
    setCurrentOption(value);
    dispatch(widgetsActions.setAllPages(value === 'all'));
  };

  return (
    <Box>
      <Text color="greyLight.2" mb={12}>
        Define the widget visibility
      </Text>
      <Radio.Group value={currentOption} onChange={onChange} mb={28}>
        {options.map(({ value, label }) => (
          <Radio
            key={value}
            value={value}
            label={label}
            sx={() => ({
              '&:not(:last-child)': {
                marginBottom: '12px',
              },
            })}
          />
        ))}
      </Radio.Group>
      {currentOption === 'specific' && activeWidget?.page !== PageType.home && (
        <>
          <TargetingSelect page={activeWidget?.page ?? PageType.product} />
          {widgetPagesToRender.length > 0 && (
            <Box className={classes.selectedPagesList}>
              {widgetPagesToRender.map((page) => (
                <SelectedPageItem
                  image={page.image}
                  title={page.title}
                  onDelete={() => onDelete(page.value)}
                />
              ))}
            </Box>
          )}
        </>
      )}
    </Box>
  );
};
