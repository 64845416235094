import {useQuery} from "@tanstack/react-query";
import {queryKey} from "../../query/keys";
import {getWidget} from "../../../api/widget/getWidget";

export interface UseGetSingleWidgetProps {
  widgetId?: string;
  enabled?: boolean;
}

export const useGetSingleWidget = ({widgetId, enabled}: UseGetSingleWidgetProps) => {
  return useQuery({
    queryKey: queryKey.singleWidget({widgetId: widgetId || ''}),
    queryFn: async () => {
      if(widgetId) {
        return getWidget({widgetId}).then(res => res.data)
      }
    },
    enabled: enabled,
  })
}
