import { apiClient } from '../client';



export type GetPreviewProduct = {
  images: string[];
  handle: string;
  url: string;
  id: number;
  price: number;
  title: string;
  description: string;
  variants: {
    id: number
    title: string
    option1: string | null
    option2: string | null
    option3: string | null
    name: string
    public_title: string
    available: boolean
    compare_at_price: number | null
    quantity_rule: {
      min: number;
      max: number | null;
      increment: number;
    }
    options: string[];
    price: number;
  }[];
  options: {
    name: string;
    position: number;
    values: string[];
  }[];
}

export const getPreviewProduct = () => {
  return apiClient.get<GetPreviewProduct>(`/bff/products/preview-product`);
};
