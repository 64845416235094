import { apiClient } from '../client';
import { AxiosRequestConfig } from 'axios';


export enum QueryVideoFilter {
  ALL = 'ALL',
  UPLOAD = 'UPLOAD',
  INSTAGRAM = 'INSTAGRAM',
  SHOPPABLE = 'SHOPPABLE',
  INBOX = 'INBOX'
}
interface Config extends AxiosRequestConfig {
  params: {
    cursor?: string;
    take?: number;
    skip?: number;
    filter?: QueryVideoFilter;
  }
}

export type FetchVideosResponse = {
  mux: string;
  preview: string;
  gif: string;
  title: string;
  duration: string;
  muxAsset: string;
  url: string;
  id: string;
  createdAt: string;
  updatedAt: string;
  videoCreatedAt: string;
  type: 'Instagram' | 'Upload';
  status: 'Data' | 'Loading' | 'Error';
  userId: string;
  mediaId: string;
  mediaType: string;
  isShoppable: boolean;
}[]



export const getVideos = (config: Config) => {
  return apiClient.get<FetchVideosResponse>(`/bff/video/platform`, config);
};
