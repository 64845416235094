import React from 'react';
import {Outlet, Navigate, useLocation} from 'react-router-dom';
import {useGetMaintenanceStatus} from "../../shared/hooks/tanstackQuery/useGetMaintenanceStatus";

export const BasePage = () => {
  const {data} = useGetMaintenanceStatus();
  const location = useLocation();

  if (data && data.isInMaintenance && location.pathname !== '/maintenance') {
    return <Navigate to="/maintenance" replace />;
  }

  return <Outlet />;
};
