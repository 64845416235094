import { MantineTheme } from '@mantine/core';

export const skeletonOverride = {
  Skeleton: {
    defaultProps: {
      animate: false,
    },
    styles: (theme: MantineTheme) => ({
      root: {
        '&.mantine-Skeleton-visible': {
          '&::before, &::after': {
            background: theme.colors.greyLight[2],
          },
        },
      },
    }),
  },
};
