import { queryClient } from 'src/api/client';
import { QUERY_KEY } from 'src/shared/consts/queryKey';

import { checkOnboardingData } from './checkOnboardingData';

export const checkAndRefetchOnboardingData = async () => {
  const onboardingData = checkOnboardingData()

  if (onboardingData?.isOnboarded === false) {
    await queryClient.refetchQueries({ queryKey: [QUERY_KEY.ONBOARDING] })
  }
}
