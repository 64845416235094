import {apiClient} from "../client";
import {AxiosRequestConfig, AxiosRequestHeaders} from "axios";

interface Config extends AxiosRequestConfig{
  widgetId: string;
}

export interface PostDuplicateWidgetResponse {
  id: string;
}

export const postDuplicateWidget = async ({widgetId}: Config) => {
  return await apiClient.post<PostDuplicateWidgetResponse>(`bff/widgets/${widgetId}/duplicate`);
}
