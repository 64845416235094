import { PropWrapper } from '../PropWrapper';
import { PropWithSwitchAutoScroll } from '../PropWithSwitchAutoScroll/PropWithSwitchAutoScroll';
import { useDispatch, useSelector } from 'react-redux';
import { selectWidgetSettings } from 'src/components/Widget/selectors';
import { BuyButtonKey, BuyButtonValue, widgetsActions } from 'src/components/Widget/store';
import {
  TextInput,
  ColorInput,
  Slider,
  NumberInput,
  Switch,
  Select,
  Radio,
  useMantineTheme,
  createStyles,
  Tooltip,
} from '@mantine/core';
import styles from './BuyButtonSettings.module.scss';
import { BuyButtonIcon, BuyButtonType } from 'src/components/Widget/types';
import { ChevronDown, ShoppingBag, ShoppingCart } from 'tabler-icons-react';
import { BorderSubmodule } from './BuyButtonSettings.submodules';

type IconProps = {
  size?: number;
  color?: string;
};
export const iconsList = [
  {
    value: BuyButtonIcon.cart,
    label: 'Cart',
    icon: ({ size = 14, color }: IconProps) => (
      <ShoppingCart size={size} strokeWidth={1} color={color} className={styles.select__icon} />
    ),
  },
  {
    value: BuyButtonIcon.bag,
    label: 'Bag',
    icon: ({ size = 14, color }: IconProps) => (
      <ShoppingBag size={size} strokeWidth={1} color={color} className={styles.select__icon} />
    ),
  },
];
const buyButtonBehaviorData = [
  {
    value: BuyButtonType.add,
    label: 'Add to cart',
    tooltip: 'Shopper stays in video & can add other products to the cart.',
  },
  {
    value: BuyButtonType.virtual,
    label: 'Direct to checkout',
    tooltip: 'Shopper goes directly to the checkout page.',
  },
  {
    value: BuyButtonType.direct,
    label: 'Direct to cart',
    tooltip: 'Shopper goes directly to the cart page.',
  },
];

const useStyles = createStyles(() => ({
  select__icon: {
    left: '8px',
    '&::after': {
      display: 'block',
      content: '"-"',
      marginLeft: '4px',
    },
  },
  select__input: {
    '&[data-with-icon]': {
      paddingLeft: '42px',
    },
    paddingRight: 0,
  },
}));

export const BuyButtonSettings = () => {
  const { colors } = useMantineTheme();
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const { buyButton } = useSelector(selectWidgetSettings) ?? {};
  const handler = (key: BuyButtonKey, value: BuyButtonValue) => {
    dispatch(widgetsActions.setBuyButton({ key, value }));
  };

  const buttonTypeHandler = (key: 'type', value: string) => {
    dispatch(widgetsActions.setBuyButton({ key, value }));
    if (value === BuyButtonType.add) {
      handler('text', 'Add to cart');
    } else {
      handler('text', 'Buy now');
    }
  };

  return (
    <>
      <PropWrapper propName="Buy button behavior" columnContent>
        <Radio.Group value={buyButton?.type} onChange={(val) => buttonTypeHandler('type', val)}>
          {buyButtonBehaviorData.map(({ value, label, tooltip }) => (
            <Tooltip key={value} label={tooltip}>
              <Radio
                value={value}
                label={label}
                sx={() => ({
                  '&:not(:last-child)': {
                    marginBottom: '12px',
                  },
                })}
              />
            </Tooltip>
          ))}
        </Radio.Group>
      </PropWrapper>
      <PropWrapper propName="Button text" alignContentLeft>
        <TextInput
          w={228}
          value={buyButton?.text}
          onChange={(e) => handler('text', e.target.value)}
          placeholder="Your Text"
        />
      </PropWrapper>
      <PropWrapper propName="Icon" alignContentLeft>
        <Select
          w={98}
          icon={iconsList
            .filter((i) => i.value === buyButton?.icon)[0]
            ?.icon({ size: 14, color: '#c1c2c5' })}
          data={iconsList}
          value={buyButton?.icon}
          onChange={(val) => handler('icon', val ?? '')}
          rightSection={<ChevronDown color={colors.greyLight[2]} size={16} />}
          classNames={{
            input: classes.select__input,
            icon: classes.select__icon,
          }}
          styles={() => ({
            wrapper: {
              width: '100px',
            },
          })}
        />
      </PropWrapper>
      <PropWrapper propName="Text/icon color" alignContentLeft>
        <ColorInput w={171} value={buyButton?.color} onChange={(val) => handler('color', val)} />
      </PropWrapper>
      <PropWrapper propName="Background color" alignContentLeft>
        <ColorInput
          w={171}
          value={buyButton?.backgroundColor}
          onChange={(val) => handler('backgroundColor', val)}
        />
      </PropWrapper>

      <PropWrapper propName="Button radius" alignContentLeft>
        <div className={styles.slider}>
          <Slider
            value={buyButton?.borderRadius}
            onChange={(val) => handler('borderRadius', val)}
            w={160}
            mr={12}
          />
          <NumberInput
            value={typeof buyButton?.borderRadius === 'number' ? buyButton.borderRadius : 0}
            onChange={(val) => handler('borderRadius', val)}
            hideControls
            styles={() => ({
              input: { width: '42px', height: '26px', minHeight: '26px' },
            })}
            min={0}
            max={100}
            step={1}
            mr={6}
          />
          <div className={styles.slider__units}>px</div>
        </div>
      </PropWrapper>

      <PropWithSwitchAutoScroll
        propName="Border"
        subModule={
          !buyButton?.showBorder ? null : <BorderSubmodule handler={handler} buyButton={buyButton} />
        }
        checked={buyButton?.showBorder}
        strictScrollingDown
        onChange={() => handler('showBorder', !buyButton?.showBorder)}
      />
    </>
  );
};
