import { createStyles, UnstyledButton, keyframes } from '@mantine/core';
import { IconPlayerPlayFilled } from '@tabler/icons-react';
import { IconPlayerPauseFilled } from '@tabler/icons-react';
import classNames from 'classnames';
import { FC } from 'react';
import { BehaviorAnimation } from 'src/components/Widget/types';

export const pulse = keyframes({
  '100%': { transform: 'scale(2)', opacity: 0 },
});

export const shine = keyframes({
  '0%': { transform: 'rotate(25deg) translate(-200%, -55px)' },
  '100%': { transform: 'rotate(25deg) translate(400%, -55px)' },
});

const useStyles = createStyles((theme) => ({
  root: {
    position: 'relative',
    width: '142px',
    height: '142px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(26, 27, 30, 0.50)',
    borderRadius: '100%',
    zIndex: 100,
    color: theme.colors.whitePrimary,
  },
  pulseAnimation: {
    '&:before, &:after': {
      content: "''",
      width: 'inherit',
      height: 'inherit',
      opacity: 1,
      position: 'absolute',
      borderRadius: '100%',
      zIndex: -1,
      backgroundColor: 'rgba(26, 27, 30, 0.50)',
    },
    '&:before': {
      animation: `${pulse} 2s ease-out infinite`,
    },
    '&:after': {
      animation: `${pulse} 2s 1s ease-out infinite`,
    },
  },
  shineAnimation: {
    overflow: 'hidden',
    '&:before': {
      content: "''",
      position: 'absolute',
      height: '500px',
      width: '40px',
      top: '-110px',
      left: '-55px',
      backgroundColor: '#ffffff10',
      filter: 'blur(1px)',
      animation: `${shine} 3s  infinite`,
    },
  },
}));

interface PlayStopButtonProps {
  state: 'play' | 'pause';
  onClick: () => void;
  size?: number;
  animation?: BehaviorAnimation;
}
export const PlayStopButton: FC<PlayStopButtonProps> = ({ state, onClick, size, animation }) => {
  const { classes } = useStyles();
  const buttonSize = size ?? 142;
  const iconSize = size ? size / 2 : 80;
  const styles = classNames(classes.root, {
    [classes.shineAnimation]: animation === BehaviorAnimation.shine,
    [classes.pulseAnimation]: animation === BehaviorAnimation.pulse,
  });
  return (
    <UnstyledButton
      className={styles}
      onClick={onClick}
      sx={{ width: buttonSize, height: buttonSize }}
    >
      {state === 'pause' ? (
        <IconPlayerPlayFilled size={iconSize} />
      ) : (
        <IconPlayerPauseFilled size={iconSize} />
      )}
    </UnstyledButton>
  );
};
