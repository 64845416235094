import React from 'react';
import {modals} from "@mantine/modals";
import {Button, List, rem, ThemeIcon} from "@mantine/core";
import {IconCircleCheck, IconCircleDashed} from "@tabler/icons-react";
import {useSelector} from "react-redux";
import {selectUser} from "../User/selectors";

export const openSuccessModalFB = (
  r: Array<{ name: string; id: string; businessAccountId?: string | null }>
) => {


    modals.open({
      title: 'Connected pages',
      children: (
        <>
          <List spacing="xs" size="sm" center>
            {r?.map(({name, id, businessAccountId}) => (
              <List.Item
                key={id}
                icon={
                  businessAccountId ? (
                    <ThemeIcon color="teal" size={24} radius="xl">
                      <IconCircleCheck style={{width: rem(16), height: rem(16)}}/>
                    </ThemeIcon>
                  ) : (
                    <ThemeIcon color="gray" size={24} radius="xl">
                      <IconCircleDashed style={{width: rem(16), height: rem(16)}}/>
                    </ThemeIcon>
                  )
                }
              >
                {name}
              </List.Item>
            ))}
          </List>
          <Button fullWidth mt="md" onClick={() => modals.closeAll()} color="green.1">
            Confirm
          </Button>
        </>
      ),
    });
}
