//let's keep new api hooks in /tanstackQuery folder trying to decrease a mess before refactoring it

import { useQuery } from "@tanstack/react-query";
import { apiClient } from "src/api/client";

import { QUERY_KEY } from "src/shared/consts/queryKey";

import { AxiosResponse } from "axios";
import { OnboardingResponce } from "src/shared/types/responses/onboarding";

export const useGetOnboardingData = () => {
  return useQuery<OnboardingResponce>({
      queryKey: [QUERY_KEY.ONBOARDING],
      queryFn: async () => {
        return await apiClient.get('/bff/onboarding')
        .then((res: AxiosResponse) => {
          return res.data
        })
      }
    });
}
