import { apiClient } from '../client';
import { AxiosRequestConfig } from 'axios';

type Data = {
  id: string;
  product_id: string;
  product_type: string | null;
  image: string | null;
  title: string;
  myshopify_domain: string | null;
  collectionIds: string[];
}[];

export const getProducts = (config?: AxiosRequestConfig) =>
  apiClient.get<Data>('/bff/products', config);
