import {useInfiniteQuery, useQuery} from "@tanstack/react-query";
import {queryKey} from "../../query/keys";
import {getTaggedProductsWithVideo} from "../../../api/video/products/getTaggedProductsWithVideo";
import {getAllProductsWithTaggedVideo} from "../../../api/video/products/getAllProductsWithTaggedVideo";

interface GetTaggedProductsWithVideoProps  {
  videoId: string;
  enable?: boolean;
}

export const useGetAllProductsWithTaggedVideo = ({enable, videoId}: GetTaggedProductsWithVideoProps) => {
  return useInfiniteQuery({
    queryKey: queryKey.allProductsWithTaggedVideo({
      videoId: videoId,
    }),
    enabled: enable,
    queryFn: () => {
      return getAllProductsWithTaggedVideo({videoId: videoId}).then((response) => response.data);
    },
    initialPageParam: {
      skip: 0,
      take: 25,
    },
    getNextPageParam: (lastPage, allPages) => {
      if (lastPage?.length === 0) {
        return undefined;
      }
      return {
        skip: allPages.flat(2).length,
        take: 25,
      }
    }
  })
}
