import {useMutation} from "@tanstack/react-query";
import {patchWidget} from "../../../api/widget/patchWidget";
import {UpdateWidget} from "../../../components/Widget/types";
import {queryClient} from "../../../api/client";
import {queryKey} from "../../query/keys";

interface UsePutWidgetProps {
  onSuccess?: () => void
}

export const usePutWidget = (props?: UsePutWidgetProps) => {
  return useMutation<unknown, unknown, UpdateWidget>({
    mutationFn: async (data) => {
      await patchWidget(data)
      queryClient.invalidateQueries({
        queryKey: queryKey.publishedWidgetsCount
      })
      queryClient.invalidateQueries({
        queryKey: queryKey.widgetsInfinite
      })
    },
    onSuccess: (_data, variables) => {
      props?.onSuccess?.()
    }
  })
}
