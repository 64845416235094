export const getCarouselConfiguration = (showDots: boolean, showHeading: boolean) => {
  if (!showDots && !showHeading) return 'none';
  if (!showDots && showHeading) return 'onlyHeading';
  if (showDots && !showHeading) return 'onlyDots';
  return 'both';
};

export const getStoriesConfiguration = (showHeading: boolean) => {
  return showHeading ? 'headingShown' : 'default';
};
