import axios, { AxiosError } from 'axios';
import { getEnv } from '../shared/lib/getEnv';

import Cookies from 'js-cookie';
import { refreshToken } from './updateToken';

import { QueryClient } from '@tanstack/react-query'
import {queryKey} from "../shared/query/keys";

export const baseURL = getEnv('REACT_APP_API_HOST') || '/';

export const getToken = () => Cookies.get('access_token');
export const apiClient = axios.create({
  baseURL,
  withCredentials: false,
});
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      gcTime: 0,
    },
  },
})
apiClient.interceptors.request.use((config) => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});
let isRefreshing = false;

const onRejectedResponse =  (error: AxiosError) => {
  const { response, config } = error;
  if (response?.status === 403 && !isRefreshing) {
    isRefreshing = true;
    const refresh =  refreshToken().then( async (data) => {
      queryClient.resetQueries().catch((e) => {
        console.error('invalidateQueries: ', e)
      }).finally(() => {
        console.log('invalidateQueries finally')
      })
      return apiClient({
        ...config,
        headers: {
          ...(config?.headers || {}),
          Authorization: `Bearer ${data.accessToken}`,
        },
      });
    }).then(()=>{
      isRefreshing = false;
    }).catch((response) => {
      window.location.href = '/unauthorized?client=web';
      return response;
    });
    return refresh;
  } else if(response?.status === 403 && isRefreshing) {
    return new Promise((resolve) => {
      const interval = setInterval(() => {
        if (!isRefreshing) {
          clearInterval(interval);
          resolve(apiClient({
            ...config,
            headers: {
              ...(config?.headers || {}),
              Authorization: `Bearer ${getToken()}`,
            },
          }));
        }
      }, 50);
    });
  }
  return Promise.reject(error);
};
const onFulfilledRequest = <T>(d: T): T => d;
apiClient.interceptors.response.use(onFulfilledRequest, onRejectedResponse);



