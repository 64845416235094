export async function getVideoFileDuration(file: File): Promise<number> {
  return new Promise<number>((resolve, reject) => {
    const video = document.createElement('video');
    video.preload = 'metadata';

    video.onloadedmetadata = () => {
      resolve(video.duration);
      URL.revokeObjectURL(video.src);
    };

    video.onerror = (event) => {
      reject(new Error(`Failed to load video metadata: ${event}`));
      URL.revokeObjectURL(video.src);
    };

    video.src = URL.createObjectURL(file);
    // Load video metadata
    video.load();
  });
}
