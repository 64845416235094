import { apiClient } from '../client';
import { AxiosRequestConfig } from 'axios';
import { DefaultParams } from '../types';
import { VideoResponseDeprecated } from '../../components/Library/LibraryHeader/types';
import {FetchVideosResponse} from "./getVideos";
interface Config extends AxiosRequestConfig {
  videoId: string;
}

export const getVideo = ({ videoId, ...config }: Config) => {
  return apiClient.get<FetchVideosResponse[0]>(`/bff/video/${videoId}`, config);
};
