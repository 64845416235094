import { Accordion, Box, createStyles, Navbar } from '@mantine/core';
import { FC } from 'react';
import classNames from 'classnames';
import { Category, filterItems, WidgetsFilter } from 'src/shared/hooks/useFilterWidgetsList';
import styles from './WidgetLayout.module.scss';

const useStyles = createStyles(() => ({
  accordion__item: {
    borderBottom: 'none',
  },
  accordion__chevron: {
    transform: 'rotate(-90deg)',
    '&[data-rotate]': {
      transform: 'rotate(-180deg)'
    }
  },
  accordion__label: {
    display: 'flex',
    alignItems: 'center',
  },
}));

interface WidgetFilterProps {
  title: string;

  active: boolean;
  onClick: () => void;
}
const WidgetFilter: FC<WidgetFilterProps> = ({ title, active, onClick }) => {
  const classes = classNames(styles.filter__item, {
    [styles.filter__itemActive]: active,
  });
  return (
    <Box className={classes} onClick={onClick}>
      <span>{title}</span>
    </Box>
  );
};
interface WidgetLayoutProps {
  filter: WidgetsFilter;
  onChange: (category: Category, key: keyof WidgetsFilter[Category]) => void;
}
export const WidgetLayout: FC<WidgetLayoutProps> = ({ filter, onChange }) => {
  const { classes } = useStyles();
  return (
    <>
      <Navbar
        width={{ sm: 240 }}
        style={{
          padding: '0',
          borderBlockColor: '#c1c2c5',
          borderRight: '1px solid #2C2E33',
          zIndex: 1,
        }}
      >
        <Navbar.Section
          style={{ background: '#1A1B1E', height: '60px', borderBottom: '1px solid #25262B' }}
        >
          <h1 className={styles.title}>Widgets</h1>
        </Navbar.Section>
        <Navbar.Section style={{ background: '#25262B' }} grow>
          <Accordion
            multiple
            defaultValue={['target_page', 'widgetview__type', 'status']}
            classNames={{
              chevron: classes.accordion__chevron,
              item: classes.accordion__item,
              label: classes.accordion__label,
            }}
          >
            <Accordion.Item value="target_page">
              <Accordion.Control>
                Target page type
              </Accordion.Control>
              <Accordion.Panel>
                <Box className={styles.filter}>
                  {filterItems.pageType.map(({ title, category, key }) => (
                    <WidgetFilter
                      key={key}
                      title={title}
                      onClick={() => onChange(category, key)}
                      active={filter[category][key]}
                    />
                  ))}
                </Box>
              </Accordion.Panel>
            </Accordion.Item>

            <Accordion.Item value="widgetview__type">
              <Accordion.Control>
                Widget type
              </Accordion.Control>
              <Accordion.Panel>
                <Box className={styles.filter}>
                  {filterItems.widgetType.map(({ title, category, key }) => (
                    <WidgetFilter
                      key={key}
                      title={title}
                      onClick={() => onChange(category, key)}
                      active={filter[category][key]}
                    />
                  ))}
                </Box>
              </Accordion.Panel>
            </Accordion.Item>

            <Accordion.Item value="status">
              <Accordion.Control>
                Status
              </Accordion.Control>
              <Accordion.Panel>
                <Box className={styles.filter}>
                  {filterItems.widgetStatus.map(({ title, category, key }) => (
                    <WidgetFilter
                      key={key}
                      title={title}
                      onClick={() => onChange(category, key)}
                      active={filter[category][key]}
                    />
                  ))}
                </Box>
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>
        </Navbar.Section>
      </Navbar>
    </>
  );
};
