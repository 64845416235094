import {Box, Button, createStyles, Modal, ScrollArea, Tooltip} from '@mantine/core';
import {FC, useEffect, useRef, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {LibraryLayout, MainVideoFilter, VideoFilter} from 'src/components/Library/LibraryLayout/LibraryLayout';
import { LibraryVideoList } from 'src/components/Library/LibraryVideoList/LibraryVideoList';
import { VideoResponseDeprecated } from 'src/components/Library/LibraryHeader/types';
import { selectCheckedVideos } from 'src/components/Widget/selectors';
import { widgetsActions } from 'src/components/Widget/store';
import { AddVideoModalHeader } from './AddVideoModalHeader/AddVideoModalHeader';
import {useVideosCountForAllFilters} from "../../../../../../shared/hooks/useVideosCountForAllFilters";
import {videoFilterToVideoQueryFilter} from "../../../../../../shared/helpers/videoFilterToVideoQueryFilter";
import {useVideos} from "../../../../../../shared/hooks/useVideos";
import {FetchVideosResponse} from "../../../../../../api/video/getVideos";

interface AddVideosModalProps {
  open: boolean;
  onClose: () => void;
  onCancel: () => void;
  onConfirm: (videos: FetchVideosResponse) => void;
}

const useStyles = createStyles((theme) => ({
  modalContent: {
    display: 'flex',
  },
  modalBody: {
    padding: 0,
    display: 'flex',
    overflow: 'hidden',
    flexGrow: 1,
    position: 'relative',
  },
  modalBody__main: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    position: 'relative',
    width: 800,
  },
  modalBody__btnContainer: {
    display: 'flex',
    // position: 'absolute',
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: theme.colors.black[0],
    justifyContent: 'flex-end',
    padding: '26px 30px 32px 30px',
  },
  modalBody__rootArea: {
    flex: 1,
  },
  modalBody__viewportArea: {
    padding: '18px 22px 18px 30px',
  },
  modalCloseBtn: {
    position: 'absolute',
    top: 14,
    right: 14,
    zIndex: 1
  },
  confirmButton: {
    '&[data-disabled]': { pointerEvents: 'all' },
  },
}));
export const AddVideosModal: FC<AddVideosModalProps> = ({ open, onClose, onCancel, onConfirm }) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const [currentFilter, setCurrentFilter] = useState<VideoFilter>({
    type: MainVideoFilter.Shoppable,
  });
  const [modalCloseOnEsc, setModalCloseOnEsc] = useState(true);
  const checkedVideos = useSelector(selectCheckedVideos);


  const filterHandler = (filter: VideoFilter) => {
    setCurrentFilter(filter);
  };

  const addVideoToWidget = (id: VideoResponseDeprecated['id']) => {
    dispatch(widgetsActions.checkVideo(id));
  };

  const handleClearAll = () => {
    dispatch(widgetsActions.uncheckAllVideos())
  }

  const handleSelectAll = () => {
    dispatch(widgetsActions.checkVideo(videos.map((video) => video.id)))
  }

  useEffect(() => {
    if (currentFilter && checkedVideos.length > 0) {
      dispatch(widgetsActions.uncheckAllVideos())
    }
  }, [currentFilter]);


  const scrollAreaRef = useRef<HTMLDivElement>(null);
  const videoQueryFilter = videoFilterToVideoQueryFilter(currentFilter)

  const {
    data: videoPages,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useVideos({
    videoQueryFilter
  });

  const videos = videoPages ? videoPages.pages.flat(2) : [];

  const onConfirmHandler = () => {
    const selectedVideos = videos.filter((video) => checkedVideos.includes(video.id));
    onConfirm(selectedVideos);
  }

  return (
    <>
      <Modal
        trapFocus={false}
        closeOnEscape={modalCloseOnEsc}
        opened={open}
        onClose={onClose}
        w={640}
        classNames={{ content: classes.modalContent, body: classes.modalBody }}
        withCloseButton={false}
        size={1039}
      >
        <Modal.CloseButton size="lg" iconSize="24px" onClick={onClose} className={classes.modalCloseBtn} />
        <LibraryLayout  isPartOfModal currentFilter={currentFilter} filterOnChange={filterHandler} />
        <div className={classes.modalBody__main} >
          <AddVideoModalHeader
            allLength={videos.length}
            selectedLength={checkedVideos.length}
            onSelectAll={handleSelectAll}
            onClearAll={handleClearAll}
          />

          <ScrollArea
            type="auto"
            viewportRef={scrollAreaRef}
            classNames={{
              root: classes.modalBody__rootArea,
              viewport: classes.modalBody__viewportArea,
            }}
          >
             <LibraryVideoList
               parentRef={scrollAreaRef}
               gapHeight={26}
               gapWidth={24}
               currentFilter={currentFilter}
               getInnerModalState={setModalCloseOnEsc}
               selectedVideos={checkedVideos}
               addVideoToWidget={addVideoToWidget}
               fetchNextPage={fetchNextPage}
               isFetchingNextPage={isFetchingNextPage}
               hasNextPage={hasNextPage}
               videos={videos}
             />
          </ScrollArea>
          <div className={classes.modalBody__btnContainer}>
            <Button onClick={onCancel} mr={16} variant="default">
              Cancel
            </Button>
            <Tooltip
              label="Select videos to add them to the widget"
              disabled={!!checkedVideos.length}
            >
              <Button
                color="green.1"
                onClick={onConfirmHandler}
                disabled={!checkedVideos.length}
                className={classes.confirmButton}
              >
                Add selected videos
              </Button>
            </Tooltip>
          </div>
        </div>
      </Modal>
    </>
  );
};
