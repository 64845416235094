import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectActiveWidget } from 'src/components/Widget/selectors';
import {useGetProducts} from "./tanstackQuery/useGetProducts";
import {useGetCollections} from "./tanstackQuery/useGetCollections";

type UsePagesSelectData = {
  value: string;
  image: string | null;
  title: string;
  label: string;
};
export const usePagesData = () => {
  const {data: products} = useGetProducts()
  const {data: collections} = useGetCollections()
  const activeWidget = useSelector(selectActiveWidget);

  const productsData: UsePagesSelectData[] = useMemo(() => {
    if (!products) return [];
    return products.map((prod) => ({
      value: prod.product_id,
      image: prod.image,
      title: prod.title,
      label: prod.title,
    }));
  }, [products]);

  const collectionsData: UsePagesSelectData[] = useMemo(() => {
    if(!collections) return []
    return collections
    .map((col) => ({
      value: col.shopify_collection_id.toString(),
      image: col.image,
      title: col.title,
      label: col.title,
      handle: col.handle,
    }))
    .filter((col) => col.handle !== 'frontpage');
  }, [collections]);

  const selectData = useMemo(
    () => (activeWidget?.page === 'collection' ? collectionsData : productsData),
    [activeWidget?.page, collectionsData, productsData]
  );
  const widgetPagesToRender = useMemo(() => {
    if (!activeWidget?.pages.length) return [];
    const selectedPages = Object.fromEntries(activeWidget.pages.map((el, idx) => [el, true]));
    return selectData.filter((item) => selectedPages[item.value]);
  }, [activeWidget, selectData]);

  return {
    selectData,
    productsData,
    collectionsData,
    widgetPagesToRender,
  };
};
