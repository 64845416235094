import {useQuery} from "@tanstack/react-query";
import {queryKey} from "../../query/keys";
import {getProducts} from "../../../api/products/getProducts";

export const useGetProducts = () => {
  return useQuery({
    queryKey: queryKey.products,
    queryFn: () => getProducts().then(res => res.data)
  })
}
