import React, {FC, useEffect, useMemo, useRef} from 'react';
import styles from './VideoCard.module.scss';

import { VCContent, VCError, VCLoader, VCThumbnailContainer } from './VideoCard.components';
import { VideoCardProps } from './VideoCard.types';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { videoListActions } from '../Library/store';
import {useVideoPreviewFromMuxId} from "../../shared/hooks/useVideoPreviewFromMuxId";

export const VideoCard: FC<VideoCardProps> = React.memo(({
  id,
  title,
  type,
  checked,
  onChecked,
  onDelete,
  onRename,
  onAddProducts = () => {},
  isShoppable,
  state,
  readOnly = false,
  hasDeleteButton,
  duration,
  muxId,
  buttonText,
}) => {

  const preview = useVideoPreviewFromMuxId(muxId, 250);

  const thumbnailClasses = classNames(styles.thumbnail, {
    [styles.thumbnailHeight__readOnly]: readOnly,
  });
  return (
    <div className={styles.card}>
      <div className={thumbnailClasses}>
        {state === 'Loading' && <VCLoader />}
        {state === 'Error' && <VCError />}
        {state === 'Data' && (
          <VCThumbnailContainer
            id={id}
            type={type}
            checked={checked}
            onChecked={onChecked}
            onDelete={onDelete}
            onRename={onRename}
            isShoppable={isShoppable}
            readOnly={readOnly}
            hasDeleteButton={hasDeleteButton}
            duration={duration}
            animateGif={preview?.animateGif}
            animateWebp={preview?.animateWebp}
            imageJpg={preview?.imageJpg}
            imageWebp={preview?.imageWebp}
          />
        )}
      </div>
      <VCContent
        title={title}
        onAddProducts={() => onAddProducts(id)}
        isDataLoaded={state === 'Data'}
        readOnly={readOnly}
        text={buttonText}
      />
    </div>
  );
});
