import { Thumbnail } from 'src/shared/ui/Thumbnail/Thumbnail';

import styles from './WidgetCardGalleryCarousel.module.scss'

import { FC } from 'react'
import { ThumbnailProps } from 'src/shared/ui/Thumbnail/Thumbnail';

export const WidgetCardGalleryCarousel: FC<ThumbnailProps> = (props) => {
    const { thumbnail } = props

    return (
      <div
        className={styles.wrapper}
      >
        {thumbnail ? (
            <Thumbnail {...props} />
          ) : null}
      </div>
    );
  };