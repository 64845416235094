import React, {FC, useState, useCallback} from 'react';
import {
  DndContext,
  closestCenter,
  MouseSensor,
  TouchSensor,
  DragOverlay,
  useSensor,
  useSensors,
  DragStartEvent,
  DragEndEvent,
} from '@dnd-kit/core';
import {arrayMove, SortableContext, rectSortingStrategy} from '@dnd-kit/sortable';
import Grid from './Grid';
import SortableItem from './SortableItem';
import DraggableVideo from './DraggableVideo/DraggableVideo';
import {WidgetVideo} from "../../../../types";
import {widgetsActions} from "../../../../store";
import {useDispatch} from "react-redux";
import {Box} from "@mantine/core";
import {RenderVideoCard} from "./interface";
import styles from './DraggableVideoList.module.scss'
export interface DraggableVideoListProps {
  videos: WidgetVideo[];
  renderVideo:RenderVideoCard;
}


export const DraggableVideoList: FC<DraggableVideoListProps> = ({videos, renderVideo}) => {
  // const [items, setItems] = useState(Array.from({ length: 20 }, (_, i) => (i + 1).toString()));
  const [activeId, setActiveId] = useState<string | null>(null);
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));
  const dispatch = useDispatch();

  const handleDragStart = useCallback((event: DragStartEvent) => {
    setActiveId(event.active.id as string);
  }, []);
  const handleDragEnd = useCallback((event: DragEndEvent) => {
    const {active, over} = event;
    if (over && active.id !== over.id) {
      const newVideos = [...videos]
      const oldIndex = newVideos.findIndex((vid) => vid.id === active.id);
      const newIndex = newVideos.findIndex((vid) => vid.id === over.id);
      const changedVideos = arrayMove(newVideos, oldIndex, newIndex);
      dispatch(widgetsActions.setVideosToWidget(changedVideos));
    }
    setActiveId(null);
  }, [videos, dispatch]);
  const handleDragCancel = useCallback(() => {
    setActiveId(null);
  }, []);

  const draggableVideoCard = videos.find((vid) => vid.id === activeId);

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}
    >
      <SortableContext items={videos} strategy={rectSortingStrategy}>
        <Box
          className={styles.videoList}

          >
          {videos.map((vid) => (
            <Box className={styles.videoCard}>
              <SortableItem renderVideo={renderVideo} key={vid.id} video={vid}/>
            </Box>
          ))}
        </Box>
      </SortableContext>
      <DragOverlay adjustScale style={{transformOrigin: '0 0 '}}>
        {draggableVideoCard ?
          <DraggableVideo isDragging renderVideo={renderVideo} video={draggableVideoCard}  {...draggableVideoCard}  /> : null}
      </DragOverlay>
    </DndContext>
  );
};
