import { useEffect } from 'react';
import { Analytics } from 'src/components/Analytics/Analytics';
import { useInitialDispatch } from 'src/shared/hooks/useInitialDispatch';
import { checkOnboardingData } from 'src/api/onboarding/checkOnboardingData';
import { useUpdateOnboardingStep } from 'src/shared/hooks/tanstackQuery/useUpdateOnboardingStep';

export const AnalyticsPage = () => {
  const updateOnboardingStepMutation = useUpdateOnboardingStep();

  useEffect(() => {
    const onboardingData = checkOnboardingData()
    if (onboardingData && onboardingData.isOnboarded === false && onboardingData.steps.AnalyticsChecked?.isDone === false) {
      updateOnboardingStepMutation.mutate({
        stepName: 'AnalyticsChecked',
        isDone: true
      })
    }
  }, [])

  return <Analytics />;
};
