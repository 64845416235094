import { Box, createStyles } from '@mantine/core';
import { VideoPlayer } from 'src/shared/ui/VideoPlayer';
import chatPointLogo from 'src/shared/images/logoWhite.svg';
import { selectWidgetSettingsBranding } from 'src/components/Widget/selectors';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { BrandingType } from 'src/components/Widget/types';

const useStyles = createStyles(() => ({
  playerWrapper: {
    width: '562px',
    height: '594px',
  },
}));
export const BrandingPreview = () => {
  const { classes } = useStyles();
  const brandingSettings = useSelector(selectWidgetSettingsBranding);
  const icon = useMemo(() => {
    if (!brandingSettings?.showLogo) return undefined;
    if (brandingSettings.type === BrandingType.chatpoint) {
      return chatPointLogo;
    } else {
      return brandingSettings.url;
    }
  }, [brandingSettings]);
  return (
    <Box className={classes.playerWrapper}>
      <VideoPlayer id={'id'} icon={icon} />
    </Box>
  );
};
