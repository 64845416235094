import { FC } from 'react'

import styles from './Thumbnail.module.scss'

export interface ThumbnailProps {
    thumbnail?: string | null;
    thumbnailWebp?: string | null;
}

export const Thumbnail:FC<ThumbnailProps> = (props) => {
  const { thumbnail, thumbnailWebp } = props

  return (
    <picture>
        {thumbnailWebp && <source srcSet={thumbnailWebp} className={styles.thumbnailImg} type="image/webp"/>}
        {thumbnail && <img src={thumbnail} className={styles.thumbnailImg}/>}
    </picture>
  )
}