import styles from './DefaultHeader.module.scss';
import { GradientButton } from '../../GradientButton/GradientButtom';
import {Button, Menu} from '@mantine/core';
import { IconChevronUp } from '@tabler/icons-react';
// import { LibrarySearch } from '../../Search/Search';
import { useOpen } from 'src/shared/hooks/useOpen';
import React, { FC } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import {VideoFileUploaderModal} from "../../VideoFileUploader/VideoFileUploaderModal/VideoFileUploaderModal";

interface DefaultHeaderProps {
  isInModal?: boolean;
}
export const DefaultHeader: FC<React.PropsWithChildren<DefaultHeaderProps>> = (props) => {
  const { isInModal, children } = props
  const { isOpen, toggleOpen } = useOpen();
  const  uploadFilePopupManager = useOpen()

  const rightContent = (
    <div className={styles.sort}>
      <Menu width={100} shadow="md">
        <Menu.Target>
          <div className={styles.button} onClick={toggleOpen}>
            <span>Sort</span>
            <IconChevronUp className={!isOpen ? styles.button__icon : styles.button__down} />
          </div>
        </Menu.Target>

        <Menu.Dropdown className={styles.dropdown}>
          <Menu.Item className={styles.dropdown__item} component="a">
            Sort 1
          </Menu.Item>
          <Menu.Item className={styles.dropdown__item} component="a">
            Sort 2
          </Menu.Item>
          <Menu.Item className={styles.dropdown__item} component="a">
            Sort 3
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </div>
  );
  const headerStyles = classNames(styles.container, {
    [styles.startContent]: isInModal,
  });
  return (
    <div className={headerStyles}>
      {children}
      <VideoFileUploaderModal isOpen={uploadFilePopupManager.isOpen} close={uploadFilePopupManager.close}/>

      {/* <LibrarySearch /> */}
      {!isInModal && (
        <div className={styles.header_right}>
          {/* {rightContent} */}
          <Button variant="outline" onClick={uploadFilePopupManager.open}>
            Add video
          </Button>
          <Link to={'/widgets/new'}>
            <GradientButton>Create Widget</GradientButton>
          </Link>
        </div>
      )}
    </div>
  );
};
