import { ContextStylesParams, MantineTheme } from '@mantine/core';

const getHeight = (size: string | number | undefined) => {
  switch (size) {
    case 'sm':
      return 26;
    case 'md':
      return 36;
    case 'lg':
      return 40;
    default:
      return 36;
  }
};

export const segmentedControlOverride = {
  SegmentedControl: {
    styles: (theme: MantineTheme, params: unknown, { size }: ContextStylesParams) => ({
      root: {
        '&[data-variant="primary"]': {
          backgroundColor: theme.colors.greyDark[5],
          border: `1px solid ${theme.colors.greyDark[1]}`,
          padding: 0,
          height: `${getHeight(size)}px`,
          '& .mantine-SegmentedControl-control': {
            '&:not(:last-child)': {
              border: 'none',
              borderRight: `1px solid ${theme.colors.greyDark[1]}`,
              borderRadius: 0,
            },
            '&:last-child': {
              border: 'none',
            },
          },
          '& .mantine-SegmentedControl-label': {
            color: theme.colors.whitePrimary,
            padding: `${size === 'sm' ? 5 : 10}px 18px`,
            fontSize: '14px',
            fontStyle: 'normal',
            lineHeight: '14px',
            fontWeight: size === 'sm' ? 400 : 600,
            height: '100%',
            '&:hover': {
              color: theme.colors.greyLight[2],
            },
            '&[data-active="true"]': {
              color: theme.colors.green[0],
              backgroundColor: theme.colors.green[2],
              borderRadius: 0,
              '&:hover': {
                color: theme.colors.whitePrimary,
              },
            },
          },
        },
        '&[data-variant="secondary"]': {
          backgroundColor: theme.colors.black[1],
          borderRadius: '4px',
          height: '40px',
          '& .mantine-SegmentedControl-control': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '114px',
            svg: {
              path: {
                stroke: theme.colors.greyLight[2],
              },
            },
          },
          '& .mantine-SegmentedControl-controlActive': {
            backgroundColor: theme.colors.greyDark[4],
            svg: {
              path: {
                stroke: theme.colors.whitePrimary,
              },
            },
          },
          '& .mantine-SegmentedControl-label': {
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '21.7px',
            padding: '5px 10px',
          },
        },
      },
      indicator: {
        display: 'none',
      },
    }),
  },
};
