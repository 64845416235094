import { useDisclosure } from '@mantine/hooks';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { widgetsActions } from 'src/components/Widget/store';
import { Widget, WidgetStatus } from 'src/components/Widget/types';
import {useGetWidgets} from "./tanstackQuery/useGetWidgets";
import {useGetWidgetsManger} from "./tanstackQuery/useGetWidgetsManger";
import {useDeleteWidget} from "./tanstackQuery/useDeleteWidget";
import {usePutWidget} from "./tanstackQuery/usePutWidget";
import {usePostDuplicateWidget} from "./tanstackQuery/usePostDuplicateWidget";
import {useNavigate, useNavigation} from "react-router-dom";


interface  OnEditProps {
  id: string;
  name?: string;
}

interface UseWidgetsListProps {
  onSuccess?: (numOfWidgets: number) => void;
  onEdit?: (widgets: OnEditProps[]) => void;
}

export const useWidgetsList = (props: UseWidgetsListProps) => {

  const dispatch = useDispatch();
  const {data: allWidgetsPages, isLoading: isAllWidgetsLoading} = useGetWidgets();
  const getWidgetsManger = useGetWidgetsManger()
  const allWidgets = allWidgetsPages?.pages.flat(2) as  Widget[]  || [];
  const [deletionModalState, setDeletionModalState] = useDisclosure();
  const [currentWidgetId, setCurrentWidgetId] = useState<string | null>();
  const navigate = useNavigate()
 const {mutate: deleteWidget} = useDeleteWidget({
   onSuccess: () => {
     setCurrentWidgetId(null);
     setDeletionModalState.close();
   },
 });

  useEffect(() => {
    if(allWidgets.length === 0 && !isAllWidgetsLoading) {
      navigate('/widgets/new', { replace: true })
    }
  }, [isAllWidgetsLoading, allWidgets]);

  const confirmDelete = () => {
    if (!currentWidgetId) return;
    deleteWidget({id: currentWidgetId});
  };
  const onDelete = (id: string) => {
    setCurrentWidgetId(id);
    setDeletionModalState.open();
  };

  const {mutate: duplicateWidget} = usePostDuplicateWidget()

  const onDuplicate = (id: Widget['id']) => {
    duplicateWidget({widgetId: id})
  };
  const onChecked = (id: Widget['id']) => {
    dispatch(widgetsActions.selectWidget(id));
  };

  const {mutate: putWidget} = usePutWidget()

  const onRename = (id: Widget['id'], newName: string) => {
    putWidget({id, name: newName})
    props.onEdit?.([{id, name: newName}]);
  };

  return {
    onChecked,
    onDuplicate,
    onDelete,
    onRename,
    confirmDelete,
    deletionModalState,
    openDeletionModal: setDeletionModalState.open,
    closeDeletionModal: setDeletionModalState.close,
    allWidgets,
    isWidgetsLoading: isAllWidgetsLoading,
  };
};
