import { useEffect } from 'react'

import { Navbar, Accordion } from '@mantine/core'

import { OnboardingStepperHeader } from './OnboardingStepperHeader/OnboardingStepperHeader'
import { OnboardingStepperList } from './OnboardingStepperList/OnboardingStepperList'
import { OnboardingStepperCollapsed } from './OnboardingStepperCollapsed/OnboardingStepperCollapsed'

import { useGetOnboardingData } from 'src/shared/hooks/tanstackQuery/useGetOnboardingData';

import styles from './OnboardingStepper.module.scss'

import { FC } from 'react'

interface OnboardingStepperProps {
  isCollapsed: boolean
  setGrowSection: (value: string) => void
}

export const OnboardingStepper: FC<OnboardingStepperProps> = (props) => {
  const { isCollapsed, setGrowSection } = props

  const { data: onboardingData, isPending, isError } = useGetOnboardingData();

  const showOnboarding = !isPending && !isError && typeof onboardingData !== 'undefined' && onboardingData.isOnboarded === false

  useEffect(() => {
    setGrowSection(showOnboarding ? 'onboarding' : 'links')
  }, [showOnboarding])

  if (!showOnboarding) return null

  const { steps } = onboardingData

  const stepsTransformed = Object.entries(steps)
  .map(([key, data]) => {
    return {
      id: key,
      ...data
    }
  })
  .sort((a, b) => a.order - b.order)

  const accordionValue = 'accordionWrapper'

  return (
    <Navbar.Section className={styles.wrapper} grow>
          {!isCollapsed ?
            <Accordion defaultValue={accordionValue}>
              <Accordion.Item value={accordionValue} className={styles.inner}>
                <OnboardingStepperHeader
                  count={stepsTransformed.length}
                  passedCount={stepsTransformed.filter((step) => step.isDone).length}
                />

                <Accordion.Panel>
                  <OnboardingStepperList steps={stepsTransformed} />
                </Accordion.Panel>
              </Accordion.Item>
            </Accordion>
          :
            <OnboardingStepperCollapsed steps={stepsTransformed} />
          }
    </Navbar.Section>
  )
}
