import { Box, Flex, Skeleton } from '@mantine/core';
import { FC } from 'react';
import { getCarouselConfiguration } from '../../config/helperFunctions';
import { CarouselSkeletonFooterProps } from '../../config/types';

const skeletonConfig = {
  onlyDots: {
    sm: 68,
    md: 50,
    lg: 40,
  },
  onlyHeading: {
    sm: 66,
    md: 48,
    lg: 42,
  },
  none: {
    sm: 84,
    md: 64,
    lg: 64,
  },
  both: {
    sm: 50,
    md: 32,
    lg: 20,
  },
};

export const CarouselSkeletonFooter: FC<CarouselSkeletonFooterProps> = ({
  size,
  showDots,
  showHeading,
}) => {
  const configuration = getCarouselConfiguration(showDots, showHeading);
  const blockHeight = skeletonConfig[configuration][size];
  return (
    <Box sx={{ bottom: 26, width: '100%', padding: '0 12px' }}>
      <Flex gap={4}>
        <Skeleton width="33%" height={blockHeight} mb={4} />
        <Skeleton width="33%" height={blockHeight} mb={4} />
        <Skeleton width="33%" height={blockHeight} mb={4} />
      </Flex>
      <Skeleton width="100%" height={2} mb={4} />
      <Flex gap={6} px={12} mb={3}>
        {Array.from([1, 2, 3, 4, 5]).map((id) => (
          <Skeleton height={45} width={45} radius="md" key={id} />
        ))}
      </Flex>
      <Box>
        <Skeleton width="100%" height={12} mb={4} />
      </Box>
      <Flex gap={4} mb={6}>
        <Box w="50%">
          <Skeleton width="100%" height={6} mb={4} />
          <Skeleton width="100%" height={6} mb={4} />
          <Skeleton width="100%" height={6} mb={4} />
          <Skeleton width="100%" height={6} mb={4} />
        </Box>
        <Box w="50%">
          <Skeleton width="100%" height={6} mb={4} />
          <Skeleton width="100%" height={6} mb={4} />
          <Skeleton width="100%" height={6} mb={4} />
          <Skeleton width="100%" height={6} mb={4} />
        </Box>
      </Flex>
      <Flex align="center" justify="center" direction="column">
        <Skeleton width="100%" height={6} mb={3} />
        <Skeleton width="80%" height={6} mb={3} />
        <Skeleton width="50%" height={6} />
      </Flex>
    </Box>
  );
};
