import { FC, ReactNode } from 'react';
import { Button, DefaultProps } from '@mantine/core';

type Props = {
  sx?: DefaultProps['sx'];
  onClick?: () => void;
  children: ReactNode;
};

export const GradientButton: FC<Props> = ({ children, sx, onClick }) => {
  return (
    <Button
      variant="gradient"
      gradient={{ from: '#00ad60', to: '#1222b2', deg: 225 }}
      sx={sx}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};
