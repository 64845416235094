import React, {useState} from 'react';
import {ActionIcon, Box, Button, Center, Flex, List, Loader, Paper, ScrollArea, Text, Tooltip} from "@mantine/core";
import classes from './TariffPlansPage.module.scss';
import {useMantle} from "@heymantle/react";
import {Tex} from "tabler-icons-react";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {GradientButton} from "../../components/GradientButton/GradientButtom";
import {MockTariffPlanInfo, mockTariffPlans} from "../../shared/mock/tariffPlans";
import Layout from 'antd/es/layout';
import {formatNumberWithSeparator} from "../../shared/helpers/formatNumberWithSeparator";
import {IconChevronLeft, IconInfoSquareRounded} from '@tabler/icons-react';
import {widgetsActions} from "../../components/Widget/store";

export const TariffPlansPage = () => {
  const {customer, subscription, plans, subscribe, cancelSubscription, refetch, sendUsageEvent, loading} = useMantle();
  const navigate = useNavigate();

  const currentPlanText = 'Current plan'

  const goBack = () => {
    navigate('/account', { replace: true });
  };

  if(loading) {
    return <Center  sx={{height: '100vh'}}>
      <Loader variant="oval" size="lg" />
    </Center>
  }
  return (
    <Box className={classes.layout}>
     <Box sx={{
       marginLeft: 10,
        marginTop: 10,
     }}>
       <ActionIcon  variant="transparent" onClick={goBack}>
         <IconChevronLeft />
       </ActionIcon>
     </Box>
            <Box className={classes.subscriptionsContent}>

              <Box className={classes.subscriptions}>
                {plans.map((plan) => {
                  const isFree = plan.amount === 0;
                  const isTrial = plan.trialDays > 0;
                  const isCurrent = subscription?.plan.id === plan.id;
                  let submitText = 'Start'
                  if (isFree) {
                    submitText = 'Start Free'
                  } else if (isTrial) {
                    submitText = `Start Free ${plan.trialDays}-Day Trial `
                  }


                  const mockDataIndex = mockTariffPlans.findIndex((mockPlan) => mockPlan.planId === plan.id)
                  const mockData:  MockTariffPlanInfo | undefined = mockTariffPlans[mockDataIndex]
                  const mockDataPrevPlan: MockTariffPlanInfo | undefined = mockTariffPlans[mockDataIndex - 1]
                  const prevPlan = plans.find((plan) => plan.id === mockDataPrevPlan?.planId)
                  const formattedVisitors = mockData?.visitors && formatNumberWithSeparator(mockData?.visitors)

                  return (
                    <Paper key={plan.id} className={classes.card}>
                      <Text className={classes.card__title}>{plan.name}</Text>
                     <Box className={classes.card__mainInfoWrapper}>
                       {formattedVisitors && <Box className={classes.card__textWithQuestion}>
                         <Text className={classes.card__firstInfo}>{formattedVisitors} video views/month</Text>
                         <Tooltip label="Video view is a video watched for 3 or more seconds">
                           <IconInfoSquareRounded stroke={2} />
                         </Tooltip>
                       </Box>}
                       {mockData?.additionalVisitorsPrice && <Text className={classes.card__firstInfo}>${mockData?.additionalVisitorsPrice} per 1,000 additional
                         video views</Text>
                       }
                     </Box>
                      <Text className={classes.card__price}>{plan.amount}$</Text>
                      <Box className={classes.card__subscribeButton}>
                        <Button
                          variant="gradient"
                          size="xl"
                          disabled={isCurrent}
                          gradient={{ from: '#00ad60', to: '#1222b2', deg: 225 }}
                          onClick={async () => {
                            if(isCurrent) return;
                            const subscription = await subscribe({ planId: plan.id });
                            if(subscription.active){
                              console.log('naviagate')
                              await  refetch()
                              navigate('/');
                            }
                            if(subscription.confirmationUrl){
                              // eslint-disable-next-line no-restricted-globals
                              open(subscription.confirmationUrl, "_top");
                            }
                          }}
                        >
                          {isCurrent ? currentPlanText: submitText }
                        </Button>
                      </Box>
                      {mockData?.description && <List>
                        {mockData.description.map((item) => {
                        return <List.Item key={item.text}>
                          <Text>{item.text}</Text>
                        </List.Item>
                        })}
                      </List>}

                    </Paper>
                  )
                })}
              </Box>
            </Box>
    </Box>
);
};

