import type {CSSObject} from "@mantine/styles/lib/tss";
import {ContextStylesParams, MantineTheme} from "@mantine/core";

export const navbarOverride = {
  Navbar: {
    styles: (theme: MantineTheme, params: unknown, {variant}: ContextStylesParams) => {
      const variants: Record<string, Record<string, CSSObject>> = {
        dashboard: {
          root: {
            background: '#1A1B1E',
            height: '60px',
            maxHeight: '60px',
            borderBottom: '1px solid #25262B',
          }
        },
      }
      if (!variant) return {};
      return variants[variant] || {};
    }
  }
}
