import { apiClient } from '../client';
import {UserResponse} from "../../components/User/types";
import {AxiosRequestConfig} from "axios";



export const getUser = (config?: AxiosRequestConfig) => {
  console.log('getUser::', config)
  return apiClient.get<UserResponse>('/bff/me', config).finally(()=>{
    console.log('getUser.res::', )
  })
};
