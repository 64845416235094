import {useMutation} from "@tanstack/react-query";
import {patchMe} from "../../../api/me/patchMe";
import {queryClient} from "../../../api/client";
import {queryKey} from "../../query/keys";

interface UsePatchUserProps {
  onSuccess?: () => void
}

export const usePatchUser = (props?: UsePatchUserProps) => {
  return useMutation({
    mutationFn: ({instagramToken}: {
      instagramToken: string
    }) => {
     return patchMe(instagramToken)
    },
    onSuccess: () => {
      queryClient.refetchQueries({
        queryKey: queryKey.user
      })
      props?.onSuccess?.()
    }
  })
}
