import { WidgetStartBlock } from 'src/shared/ui/WidgetStartBlock/WidgetStartBlock';

import { FC } from 'react'
import { WidgetViewType } from 'src/components/Widget/types';

interface WidgetTypeStepProps {
  onSelect: (val: WidgetViewType) => void;
}

export const WidgetTypeStep: FC<WidgetTypeStepProps> = (props: WidgetTypeStepProps) => {
  const { onSelect } = props;

  return (
    <WidgetStartBlock onSelect={onSelect} marginTop={true} />
  );
};
