import { Box, Flex, Skeleton } from '@mantine/core';
import { FC } from 'react';
import { getStoriesConfiguration } from '../../config/helperFunctions';
import { StoriesSkeletonFooterProps } from '../../config/types';

const skeletonConfig = {
  default: {
    sm: 78,
    md: 68,
    lg: 54,
  },
  headingShown: {
    sm: 62,
    md: 50,
    lg: 16,
  },
};
export const StoriesSkeletonFooter: FC<StoriesSkeletonFooterProps> = ({ size, showHeading }) => {
  const configuration = getStoriesConfiguration(showHeading);
  const blockHeight = skeletonConfig[configuration][size];
  return (
    <Box sx={{ bottom: 26, width: '100%', padding: '0 12px' }}>
      <Flex gap={4}>
        <Skeleton width="33%" height={blockHeight} mb={4} />
        <Skeleton width="33%" height={blockHeight} mb={4} />
        <Skeleton width="33%" height={blockHeight} mb={4} />
      </Flex>
      <Skeleton width="100%" height={12} mb={4} />
      <Flex gap={6} px={12} mb={3}>
        {Array.from([1, 2, 3, 4, 5]).map((id) => (
          <Skeleton height={45} width={45} radius="md" key={id} />
        ))}
      </Flex>
      <Box>
        <Skeleton width="100%" height={12} mb={4} />
      </Box>
      <Flex gap={4} mb={6}>
        <Box w="50%">
          <Skeleton width="100%" height={6} mb={4} />
          <Skeleton width="100%" height={6} mb={4} />
          <Skeleton width="100%" height={6} mb={4} />
          <Skeleton width="100%" height={6} mb={4} />
        </Box>
        <Box w="50%">
          <Skeleton width="100%" height={6} mb={4} />
          <Skeleton width="100%" height={6} mb={4} />
          <Skeleton width="100%" height={6} mb={4} />
          <Skeleton width="100%" height={6} mb={4} />
        </Box>
      </Flex>
      <Flex align="center" justify="center" direction="column">
        <Skeleton width="100%" height={6} mb={3} />
        <Skeleton width="80%" height={6} mb={3} />
        <Skeleton width="50%" height={6} />
      </Flex>
    </Box>
  );
};
