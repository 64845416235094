import { Typography } from 'antd';
import { useEffect } from 'react';

export const SuperuserPage = () => {
  useEffect(() => {
    localStorage.setItem('chatpoint_admin_role', 'true');
  }, []);
  return (
    <div style={{ textAlign: 'center' }}>
      <Typography.Title level={1}>You signed as superuser</Typography.Title>
    </div>
  );
};
