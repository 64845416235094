import {useMutation} from "@tanstack/react-query";
import {saveInstagram, SaveInstagramResponse} from "../../../api/connect_instagram/saveInstagram";
import {queryClient} from "../../../api/client";
import {queryKey} from "../../query/keys";
import {invalidateQueryVideos} from "../../query/invalidateQueryVideos";
import {QueryVideoFilter} from "../../../api/video/getVideos";


interface UsePutInstagramProps {
  onSuccess?: (data: SaveInstagramResponse) => void
}

export const usePutInstagram = (props?: UsePutInstagramProps) => {
  return useMutation({
    mutationFn: (props: { instagramId: string;
      longTermToken: string;})=> {
      return saveInstagram(props).then(res => res.data)
    },
    onSuccess: (data, variables, context) => {
      queryClient.refetchQueries({
        queryKey: queryKey.user
      })
      queryClient.refetchQueries({
        queryKey: queryKey.videosCountPerFilter
      })
      invalidateQueryVideos({filters: Object.values(QueryVideoFilter)})
      props?.onSuccess?.(data)
    }
  })
}
