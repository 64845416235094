import { MantineTheme } from '@mantine/core';

export const sliderOverride = {
  Slider: {
    defaultProps: {
      color: 'green.1',
    },
    styles: (theme: MantineTheme) => ({
      thumb: {
        height: '16px',
        width: '16px',
        backgroundColor: theme.colors.whitePrimary,
        borderWidth: 1,
        boxShadow: theme.shadows.sm,
      },
      track: {
        height: '4px',
      },
    }),
  },
};
