import React, {useEffect} from 'react';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { QueryClientProvider } from '@tanstack/react-query'
import './App.scss';
import { BaseLayout } from '../components/BaseLayout';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { store } from './store';
import { createBrowserRouter, Navigate, RouterProvider, useLocation, redirect } from 'react-router-dom';
import { LoginPage } from '../pages/login';
import { ConfigProvider } from 'antd';
import { SuperuserPage } from '../pages/i-am-superuser';
import { VideosPage } from 'src/pages/video';
import { initFacebookSDK } from '../shared/helpers/Facebook';
import { Library } from 'src/pages/library/Library';
import { Widgets } from 'src/pages/widgets';
import { NewWidget } from 'src/pages/widgets/new';
import { StepsContextProvider } from 'src/pages/widgets/new/StepsContext';
import { ThemeProvider } from '../styles/theme/ThemeProvider';
import { AnalyticsPage } from 'src/pages/analytics/AnalyticsPage';
import { AccountPage } from '../pages/account/AccountPage';
import { EditWidget } from 'src/pages/widgets/edit';
import { InstallationProvider } from 'src/components/Widget/Steps/Setup/Installation/InstallationProvider';
import { Unauthorized } from "../pages/unauthorized/Unauthorized";
import { queryClient } from 'src/api/client';
import { selectUser } from "../components/User/selectors";
import { ErrorNotFound } from 'src/components/Error/ErrorNotFound/ErrorNotFound';
import { refreshToken } from "../api/updateToken";
import { userActions } from "../components/User/store";
import { Box, Center, Flex, Loader } from "@mantine/core";
import * as amplitude from '@amplitude/analytics-browser';
import {pushCurrentUserToIntercom} from "../shared/googleDataLayer/pushCurrentUserToIntercome";
import {RequireMantleAccessToken} from "./RequireMantleAccessToken";
import {TariffPlansPage} from "../pages/tarriff-plans";
import {useLogin} from "../shared/hooks/useLogin";
import {initEventLogger, sendPageViewedEvent, setUserIdForEvents} from "../shared/eventLogger/eventLogger";
import {useGetUser} from "../shared/hooks/tanstackQuery/useGetUser";
import {MaintenancePage} from "../pages/maintenance/MaintenancePage";
import {BasePage} from "../components/BasePage/BasePage";
import {getMaintenanceStatus} from "../api/maintenance/getMaintenanceStatus";
import {MantleTariffPlans} from "../pages/tarriff-plans/mantle-tariff-plans";
import {FeatureRestrictionProvider} from "../shared/featureRestrictions/FeatureRestrictionProvider";

initEventLogger()

Sentry.init({
  dsn: 'https://fde30d27fc814b3ea04f6898cb700f40@o4504576421527552.ingest.sentry.io/4504576554237952',
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

function RequireAuth({ children }: { children: JSX.Element }) {
  const {isLoggedIn, user} = useLogin();
  const location = useLocation();
  useEffect(() => {
    if(!user) return;
    if(user.id) {
      setUserIdForEvents({userId: user.id})
    }
    if (user.email && user.full_name && user.id && user.hash) {
      pushCurrentUserToIntercom({
        userId: user.id,
        email: user.email,
        name: user.full_name,
        user_hash: user.hash,
      })
    }
  }, [user])




  const isShowLoader = !isLoggedIn || !user;
  const isShowContent = isLoggedIn

  if (isShowLoader) {
    return <Center sx={{ height: '100vh' }}>
      <Loader variant="oval" size="lg" />
    </Center>
  }


  if (isShowContent) {
    return (
      <RequireMantleAccessToken>
        <FeatureRestrictionProvider>
          {children}
        </FeatureRestrictionProvider>
      </RequireMantleAccessToken>
    )
  }


  return <Navigate to="/unauthorized" state={{ from: location }} />;
}



const router = createBrowserRouter([
  {
    path: '/',
    errorElement: <ErrorNotFound />,
    element:
      <ThemeProvider>
        <BasePage/>
      </ThemeProvider>,
    children: [
      {
        index: true,
        loader: async () => {
          const result = await getMaintenanceStatus();
          if (result && result.isInMaintenance) {
            return redirect('/maintenance');
          } else {
            return redirect('/library');
          }
        },
        action: () => {
        }
      },
      {
        path: 'login',
        element: <LoginPage />,
      },
      {
        path: 'library',
        element: (
          <RequireAuth>
            <BaseLayout>
              <Library />
            </BaseLayout>
          </RequireAuth>
        ),
      },
      {
        path: 'library/:filter',
        element: (
          <RequireAuth>
            <BaseLayout>
              <Library />
            </BaseLayout>
          </RequireAuth>
        ),
      },
      {
        path: 'i-am-superuser',
        element: (
          <RequireAuth>
            <BaseLayout>
              <SuperuserPage />
            </BaseLayout>
          </RequireAuth>
        ),
      },
      {
        path: 'videos',
        element: (
          <RequireAuth>
            <BaseLayout>
              <VideosPage />
            </BaseLayout>
          </RequireAuth>
        ),
      },
      {
        path: 'widgets',
        element: (
          <RequireAuth>
            <BaseLayout>
              <Widgets />
            </BaseLayout>
          </RequireAuth>
        ),
      },
      {
        path: 'widgets/new',
        element: (
          <RequireAuth>
            <BaseLayout>
              <StepsContextProvider>
                <InstallationProvider>
                  <NewWidget />
                </InstallationProvider>
              </StepsContextProvider>
            </BaseLayout>
          </RequireAuth>
        ),
      },
      {
        path: 'widgets/edit/:widget_id',
        element: (
          <RequireAuth>
            <BaseLayout>
              <StepsContextProvider>
                <InstallationProvider>
                  <EditWidget />
                </InstallationProvider>
              </StepsContextProvider>
            </BaseLayout>
          </RequireAuth>
        ),
      },
      {
        path: 'analytics',
        element: (
          <RequireAuth>
            <BaseLayout>
              <AnalyticsPage />
            </BaseLayout>
          </RequireAuth>
        ),
      },
      {
        path: 'account',
        element: (
          <RequireAuth>
            <BaseLayout>
              <AccountPage />
            </BaseLayout>
          </RequireAuth>
        )
      },
      {
        path: 'unauthorized',
        element: <Unauthorized />,
      },
      {
        path: '/maintenance',
        element: <MaintenancePage />,
      },
      {
        path: '/pricing-plans',
        element: <RequireAuth>
          <TariffPlansPage />
        </RequireAuth>,
      },
    ]
  },
]);
router.subscribe((state) => {
  sendPageViewedEvent({ pageName: state.location.pathname })
})

function App() {
  useEffect(() => {
    async function checkFacebookLoginStatus() {
      try {
        const initialized = await initFacebookSDK();
      } catch (error) {
        console.log('error: ', error);
      }
    }
    checkFacebookLoginStatus();
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: '#001EFF',
              colorSuccess: '#1ac468',
              colorError: '#ff4d4f',
              colorInfo: '#1677ff',
            },
          }}
        >
          <Provider store={store}>
            <RouterProvider router={router} />
          </Provider>
        </ConfigProvider>
    </QueryClientProvider>
  );
}

export default App;
