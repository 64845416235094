import { ColorInput, NumberInput, Slider, Switch } from '@mantine/core';
import { useDispatch, useSelector } from 'react-redux';
import { SegmentSwitch } from 'src/components/SegmentSwitch/SegmentSwitch';
import { PropWrapper } from '../PropWrapper';
import { PropWithSwitchAutoScroll } from '../PropWithSwitchAutoScroll/PropWithSwitchAutoScroll';
import styles from './CarouselLayoutSettings.module.scss';
import { LayoutKey, LayoutValue, widgetsActions } from 'src/components/Widget/store';
import { selectWidgetSettings } from 'src/components/Widget/selectors';
import { BorderSubmodules, HeadingSubmodule } from './CarouselLayoutSettings.submodules';
import { WidgetViewType } from 'src/components/Widget/types';

const sizeData = [
  {
    value: 'sm',
    label: 'Small',
  },
  {
    value: 'md',
    label: 'Medium',
  },
  {
    value: 'lg',
    label: 'Large',
  },
];

export const CarouselLayoutSettings = () => {
  const dispatch = useDispatch();
  const { layout, general } = useSelector(selectWidgetSettings) ?? {};

  const handler = (key: LayoutKey, value: LayoutValue) => {
    dispatch(widgetsActions.setLayout({ key, value }));
  };
  const showProperty = general?.widgetViewType === WidgetViewType.carousel;

  return (
    <>
      <PropWrapper propName="Size" alignContentLeft>
        <SegmentSwitch
          value={layout?.size ?? 'sm'}
          data={sizeData}
          onChange={(val) => handler('size', val)}
        />
      </PropWrapper>
      {showProperty && (
        <PropWrapper propName="Thumbnail radius" alignContentLeft>
          <div className={styles.slider}>
            <Slider
              value={layout?.borderRadius}
              onChange={(val) => handler('borderRadius', val)}
              w={160}
              mr={12}
              min={0}
              max={25}
            />
            <NumberInput
              color="green"
              value={typeof layout?.borderRadius === 'number' ? layout.borderRadius : 0}
              onChange={(val) => handler('borderRadius', val)}
              hideControls
              styles={() => ({
                input: { width: '42px', height: '26px', minHeight: '26px' },
              })}
              min={0}
              max={25}
              step={1}
              mr={6}
            />
            <div className={styles.slider__units}>px</div>
          </div>
        </PropWrapper>
      )}
      <PropWrapper propName="Background" alignContentLeft>
        <ColorInput
          format="hex"
          placeholder="Pick a color"
          value={layout?.background}
          onChange={(val) => handler('background', val)}
          w={171}
        />
      </PropWrapper>
      <PropWrapper propName="Navigation arrows">
        <Switch
          checked={layout?.showArrows}
          onChange={() => handler('showArrows', !layout?.showArrows)}
        />
      </PropWrapper>
      {showProperty && (
        <PropWrapper propName="Navigation dots">
          <Switch
            checked={layout?.showDots}
            onChange={() => handler('showDots', !layout?.showDots)}
          />
        </PropWrapper>
      )}

      <PropWithSwitchAutoScroll
        propName='Border'
        subModule={
          !layout?.showBorder ? null : (
            <BorderSubmodules
              handler={handler}
              layout={layout}
              showBorderThickness={showProperty}
            />
          )
        }
        checked={layout?.showBorder}
        onChange={() => handler('showBorder', !layout?.showBorder)}
      />

      <PropWithSwitchAutoScroll
        propName='Heading'
        subModule={
          !layout?.showHeading ? null : <HeadingSubmodule handler={handler} layout={layout} />
        }
        checked={layout?.showHeading}
        onChange={() => handler('showHeading', !layout?.showHeading)}
      />
    </>
  );
};
