export const getOptimizedThumbnail = (thumbnail?: string | null | undefined, width?: number) => {
  try {
    if (typeof thumbnail === 'string') {
      const thumbnailWidth = width || 250
      const url = new URL(thumbnail)
      url.searchParams.set('width', String(thumbnailWidth))
      url.searchParams.set('time', '0')
      url.href = url.href.replace('animated.webp', 'animated.gif')
      const gifUrl = url.toString()
      url.href = url.href.replace('animated.gif', 'animated.webp')
      const webpUrl = url.toString()
      return {
        gifUrl, webpUrl,
      }
    }
  } catch (e) {
    console.error(e)
  }
}
