import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { checkAppBlock } from 'src/api/widget/checkAppBlock';
import { WidgetStatus, WidgetViewType } from 'src/components/Widget/types';
import { widgetsActions } from 'src/components/Widget/store';
import { selectActiveWidget } from 'src/components/Widget/selectors';

export interface Block {
  activate_url: string | null;
  page_type: WidgetViewType;
  preview_url: string;
  status: string;
  theme_name: string;
}
export const useCheckWidgetInstallation = (
  widgetId: string,
  setLoading: (loading: boolean) => void
) => {
  const [checked, setChecked] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState<Block | null>(null);

  const dispatch = useDispatch();
  const currentWidget = useSelector(selectActiveWidget);


  const checkInstallation = async (callback?: () => void) => {
    setLoading(true);
    setError(false);
    try {
      await checkAppBlock(widgetId).then(async ({ data }) => {
        setData(data);
        if (data.status === 'OK') {
          //if we have widget on the Shopify side and current status is notInstalled, then we need to set status to draft
          if (currentWidget?.status === WidgetStatus.notInstalled) {
            dispatch(widgetsActions.setStatus({ newStatus: WidgetStatus.draft, widgetId: null }));
          }

          if (callback) {
            callback();
          }
          setChecked(true);
          setError(false);
          return;
        } else {
          //if we lost widget on the Shopify side, then we need to set status to notInstalled
          if (currentWidget?.status !== WidgetStatus.notInstalled) {
            dispatch(widgetsActions.setStatus({ newStatus: WidgetStatus.notInstalled, widgetId: null }));
          }
          setChecked(false);
          setError(true);
        }
      });
    } catch (e) {
      setChecked(false);
      setError(true);
    }
    setLoading(false);
  };
  return {
    checkInstallation,
    error,
    checked,
    data,
  };
};
