import {useQuery} from "@tanstack/react-query";
import {queryKey} from "../query/keys";
import {getVideosCountForAllFilters} from "../../api/video/getVideosCountForAllFilters";
import {QueryVideoFilter} from "../../api/video/getVideos";

export const useVideosCountForAllFilters = () => {
  return  useQuery({
    queryKey: queryKey.videosCountPerFilter,
    refetchInterval: 5_000,
    queryFn: async () => {
      return getVideosCountForAllFilters().then((res) => res.data)
    },
    select: (data)=>{
      return data.reduce((acc: {
        [key in QueryVideoFilter]?: number;
      }, item) => {
        acc[item.type] = item.count;
        return acc;
      }, {})
    },
  })
}
