import {useMutation} from "@tanstack/react-query";
import {deleteVideo} from "../../api/video/deleteVideo";

export const useDeleteVideos = (data?: {
  onSuccess?: () => void;
  onMutate?: (prosp: { ids: string[] }) => void;
}) => {
 return  useMutation({
    mutationFn: async ({ids}:{ids: string[]}) => {
      return Promise.all(ids.map((id) => {
        deleteVideo({params: {id}})
      }))
    },
    onSuccess: data?.onSuccess,
    // onMutate: data?.onMutate,
   onMutate: data?.onMutate
  })
}
