import React from 'react';
import {Box, Loader, Progress} from "@mantine/core";
import styles from './VideosUploadingLoader.module.scss';


export const VideosUploadingLoader = () => {
  return (
     <Box className={styles.container}>
      <Loader size="xl" />
    </Box>
  );
};

