import { Button } from '@mantine/core';
import { FC, ReactElement } from 'react';
import styles from './PageTypeStep.module.scss';

interface PageTypeButtonProps {
  title: string;
  icon: ReactElement;
  onClick: () => void;
}
export const PageTypeButton: FC<PageTypeButtonProps> = ({ title, icon, onClick }) => {
  return (
    <Button className={styles.btn} onClick={onClick}>
      {icon}
      <span className={styles.btn__title}>{title}</span>
    </Button>
  );
};
