import React from 'react';
import styles from './LoginButtonWrapper.module.scss'
import {Button, Tooltip, Text} from "@mantine/core";
import cn from "classnames";
import {GradientButton} from "../../../GradientButton/GradientButtom";
interface LoginButtonWrapperProps {
  text: string;
  Icon: React.FC;
  buttonText: string;
  onClick?: () => void;
  isAvailable?: boolean;
}

export const LoginButtonWrapper: React.FC<LoginButtonWrapperProps> = ({Icon,text, buttonText,onClick, isAvailable = true}) => {
  return (
    <div className={styles.container} onClick={onClick}>
     <div className={styles.leftContainer}>
       <Icon />
       {/* <p className={styles.title}>{text}</p> */}
       <Text className={styles.title} component='p' weight={500}>{text}</Text>
     </div>
      {isAvailable
        ? <GradientButton>{buttonText}</GradientButton>
        :   <Tooltip label="Soon!">
          <Button
            className={cn(styles.baseButton)}
            data-disabled
            sx={{ '&[data-disabled]': { pointerEvents: 'all' } }}
            onClick={(event) => event.preventDefault()}
          >
            <Text weight={600}>{buttonText}</Text>
          </Button>
        </Tooltip>
      }

    </div>
  );
};

