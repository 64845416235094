import { rootSaga } from './saga';
import { configureStore, PayloadAction } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { rootReducer } from './reducer';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type PayloadCallbacks<D = any, E = Error> = {
  callback?: {
    onSuccess?: (response?: D) => void;
    onError?: (error?: E) => void;
    onEnd?: () => void
  };
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type PayloadActionWithCallbacks<T, D = any, E = Error> = PayloadAction<
  T & PayloadCallbacks<D, E>
>;

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: rootReducer,
  middleware: [sagaMiddleware],
});

sagaMiddleware.run(rootSaga);

export type RootState = ReturnType<typeof store.getState>;
