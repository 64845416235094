import {Text, Box, rem, Switch} from '@mantine/core';
import {DragDropContext, Draggable, Droppable} from "@hello-pangea/dnd";
import styles from './ProductDetailsSettings.module.scss';
import {useListState} from "@mantine/hooks";
import {IconGripVertical} from "@tabler/icons-react";
import {PropWrapper} from "../PropWrapper";
import {useDispatch, useSelector} from "react-redux";
import {selectWidgetProductDetails} from "../../../../selectors";
import {widgetsActions} from "../../../../store";
import {WidgetProductDetails} from "../../../../types";


const mockData = Array.from({length: 5}).map((_, index) => ({
  id: crypto.randomUUID(),
  title: `Item ${index}`,
}));


export const ProductDetailsSettings = () => {
  const dispatch = useDispatch();
  const productDetails = useSelector(selectWidgetProductDetails)

  const changeVisibility = ({widgetStoreProductDetailId}: {
    widgetStoreProductDetailId: string,
  }) => {
    if (productDetails) {
      const updatedProductDetails = productDetails.map((item) => {
        if (item.id === widgetStoreProductDetailId) {
          return {
            ...item,
            isVisible: !item.isVisible
          }
        }
        return item;
      });
      dispatch(widgetsActions.setProductDetails(updatedProductDetails));
    }
  }


    const items = productDetails?.map((item, index) => {
    const isLast = index === productDetails.length - 1;

    return (
      <Draggable key={item.id} index={index} draggableId={item.id}>
        {(provided, snapshot) => (
          <Box
            ref={provided.innerRef}
            {...provided.draggableProps}
          >
            <PropWrapper showBorder={!isLast}>
              <Box className={styles.item}>
                <Box {...provided.dragHandleProps} className={styles.item__dots}>
                  <IconGripVertical color="#C1C2C5" size="fit-content" stroke={1.5}/>
                </Box>
                <Text className={styles.item__title}>{item.name}</Text>
                <Switch
                  checked={item.isVisible}
                  onChange={() => changeVisibility({widgetStoreProductDetailId: item.id})}
                />
              </Box>

            </PropWrapper>

          </Box>
        )}
      </Draggable>
    )
  });

  return (
    <>
      <Box className={styles.container}>
        <Text className={styles.title}>Add blocks to the Product Details page</Text>
        {productDetails && <DragDropContext
          onDragEnd={({destination, source}) => {
            if (destination) {
              const reordedProductDetails = Array.from(productDetails);
              const [reorderedItem] = reordedProductDetails.splice(source.index, 1);
              reordedProductDetails.splice(destination.index, 0, reorderedItem);
              const indexedProductDetails: WidgetProductDetails[] = reordedProductDetails.map((item, index) => ({
                ...item,
                order: index
              }));
              dispatch(widgetsActions.setProductDetails(indexedProductDetails));
            }
          }
          }
        >
          <Droppable droppableId="dnd-list" direction="vertical">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {items}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>}

      </Box>
    </>
  );
};
