import React from 'react';
import {Box, Text} from "@mantine/core";

export const Unauthorized = () => {
  return (
    <Box>
      <Text sx={{
        fontSize: 32,
        fontWeight: 600,
        marginBottom: 20,
        textAlign: 'center',
      }}>
        Unauthorized <br/> Please login via Shopify
      </Text>
    </Box>
  );
};

