import { MantineTheme } from '@mantine/core';

export const selectOverride = {
  Select: {
    styles: (theme: MantineTheme) => ({
      item: {
        '&:not(:last-child)': {
          marginBottom: '4px',
        },
        '&:hover': {
          backgroundColor: theme.colors.greyDark[3],
        },
        '&[data-selected="true"]': {
          backgroundColor: theme.colors.green[2],
          '&:hover': {
            backgroundColor: theme.fn.rgba(theme.colors.green[2], 0.9),
          },
        },
      },
    }),
  },
};
