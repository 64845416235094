import styles from './Analytics.module.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import { useDisclosure } from '@mantine/hooks';
import { Loader, Navbar } from '@mantine/core';
import cn from 'classnames';
import {useGetAnalytics} from "../../shared/hooks/tanstackQuery/useGetAnalytics";

export const Analytics = () => {
  const {data: analyticsData} = useGetAnalytics();
  const [isLoading, { close }] = useDisclosure(true);
  return (
    <div className={styles.root}>
      <div
        style={{ background: '#1A1B1E', height: '60px', borderBottom: '1px solid #25262B' }}
      >
        <h1 className={styles.title}>Analytics</h1>
      </div>

      <div className={styles.container}>
        {/*<h1 className={styles.title}>*/}
        {/*  Analytics will be available <br />*/}
        {/*  after widget installation*/}
        {/*</h1>*/}
        {isLoading && <Loader color={'rgba(23, 232, 133, 0.5)'} size={'xl'} />}

        {analyticsData?.iframeUrl && (
          <iframe
            className={cn(isLoading && styles.hidden, styles.analyticsIframe)}
            src={analyticsData?.iframeUrl}
            frameBorder={0}
            width={1000}
            height={700}
            allowTransparency
            onLoad={close}
          />
        )}
      </div>
    </div>
  );
};
