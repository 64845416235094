import {useContext} from "react";
import {FeatureRestrictionsContext} from "../featureRestrictions/featureRestrictionsContext";

export const useFeatureRestriction = () => {
  const context = useContext(FeatureRestrictionsContext)
  if(!context) {
    console.error('useFeatureRestriction must be used within a FeatureRestrictionsProvider')
  }
  return context
}
