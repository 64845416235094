import { apiClient } from '../client';
import {ActiveWidget} from "../../components/Widget/types";

type Config = {
  widgetId: string;
};


export const getWidget = ({ widgetId }: Config) => {
  // TODO create a type for the response
  return apiClient.get<ActiveWidget>(`/bff/widgets/${widgetId}`);
};
