import { MantineTheme } from '@mantine/core';

export const scrollAreaOverride = {
  ScrollArea: {
    styles: (theme: MantineTheme) => ({
      scrollbar: {
        backgroundColor: 'transparent',
        padding: 2,
        '&:hover': {
          backgroundColor: 'transparent',
        },
        '&[data-orientation="vertical"]': {
          width: '8px',
          '.mantine-ScrollArea-thumb': {
            borderRadius: '100px',
            backgroundColor: 'rgba(193, 194, 197, 0.20)',
          },
        },
      },
    }),
  },
};
