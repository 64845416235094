export const getFilterFromSessionStorage = (key: string) => {
  const storedFilters = sessionStorage.getItem(key);
    if (storedFilters) {
      const parsedFilters = JSON.parse(storedFilters);
      const urlFilters = new URLSearchParams();

      Object.keys(parsedFilters).forEach((key) => {
        if (typeof parsedFilters[key] === 'object') {
          Object.keys(parsedFilters[key]).forEach((subKey) => {
            if (parsedFilters[key][subKey]) {
              urlFilters.set(subKey, 'true');
            }
          });
        } else {
          if (parsedFilters[key]) {
            urlFilters.set(key, 'true');
          }
        }
      });

      return urlFilters;
    }
}
