import {DataRetrieval} from "@chatpoint/widget-lib/dist/providers/interface/dataRetrieval";
import {useSelector} from "react-redux";
import {selectActiveWidget} from "../../components/Widget/selectors";
import {useVideos} from "./useVideos";
import {QueryVideoFilter} from "../../api/video/getVideos";
import {WidgetCreationType, WidgetVideo} from "../../components/Widget/types";
export interface UseWidgetPreviewDataRetrievalRes{
  dataRetrieval: {
    fetchVideos: DataRetrieval['fetchVideos'];
    videoCount: number;
  };
  isPreparing: boolean;
}
export const useWidgetPreviewDataRetrieval = (): UseWidgetPreviewDataRetrievalRes => {

  const activeWidget = useSelector(selectActiveWidget);

  const {data: storeVideosPages, isLoading} = useVideos({
    videoQueryFilter: QueryVideoFilter.ALL
  })

  const videos: WidgetVideo[] = activeWidget?.type === WidgetCreationType.automated ? storeVideosPages?.pages.flat(2) || [] : activeWidget?.videos || [];
  const fetchVideos: DataRetrieval['fetchVideos'] = (data) => {
    const {take, skip} = data;
    if (take && take < 0 || skip && skip < 0) {
      return [];
    }
    if(!take && skip) {
      return videos.slice(skip);
    }
    if(take && skip) {
      return videos.slice(skip, skip + take);
    }
    if(take && !skip) {
      return videos.slice(0, take);
    }
    return videos;
  }


  return {
    dataRetrieval: {
      fetchVideos,
      videoCount: videos.length,
    },
    isPreparing: isLoading,
  }
}
