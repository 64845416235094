import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PayloadActionWithCallbacks } from 'src/app/store';
import { VideoResponseDeprecated } from '../Library/LibraryHeader/types';
import {
  ActiveWidget,
  BehaviorAnimation,
  BehaviorAutoplay,
  BrandingType,
  BuyButtonIcon,
  BuyButtonType,
  CreateAutomatedWidget,
  HeadingAlignment,
  HeadingFontWeight,
  LayoutSize,
  PageType,
  UpdateWidget,
  Widget,
  WidgetCreationType,
  WidgetSettings,
  WidgetStatus,
  WidgetViewType,
} from './types';
import { RequestStatus } from "../../shared/types";

export type LayoutKey = keyof WidgetSettings['layout'];
export type LayoutValue = WidgetSettings['layout'][keyof WidgetSettings['layout']];

export type BehaviorKey = keyof WidgetSettings['behavior'];
export type BehaviorValue = WidgetSettings['behavior'][keyof WidgetSettings['behavior']];

export type BuyButtonKey = keyof WidgetSettings['buyButton'];
export type BuyButtonValue = WidgetSettings['buyButton'][keyof WidgetSettings['buyButton']];

export type BrandingKey = keyof WidgetSettings['branding'];
export type BrandingValue = WidgetSettings['branding'][keyof WidgetSettings['branding']];

const spacingFromSize = {
  sm: 18,
  md: 24,
  lg: 36,
};
export const widgetSettingsInit = {
  general: {
    widgetViewType: WidgetViewType.carousel,
  },
  layout: {
    spacing: 24,
    size: LayoutSize.sm,
    borderRadius: 10,
    background: '#ffffff',
    showArrows: false,
    showDots: false,
    showBorder: false,
    border: false,
    borderThickness: 2,
    borderColor: '#777777',
    showHeading: false,
    headingText: 'Heading',
    headingTextSize: 24,
    headingFontWeight: HeadingFontWeight.regular,
    headingTextColor: '#000000',
    headingAlignment: HeadingAlignment.center,
    headingBottomPadding: 24,
  },
  behavior: {
    animation: BehaviorAnimation.pulse,
    autoplay: BehaviorAutoplay.enable,
  },
  buyButton: {
    type: BuyButtonType.add,
    text: 'Add to cart',
    icon: BuyButtonIcon.cart,
    color: '#ffffff',
    backgroundColor: '#373A40',
    borderRadius: 0,
    showBorder: false,
    borderThickness: 2,
    borderColor: '#ffffff',
  },
  branding: {
    showLogo: false,
    type: BrandingType.chatpoint,
    url: '',
    fileName: '',
  },
};

type CreateWidgetInit = {
  creationType: WidgetCreationType;
  viewType: WidgetViewType;
  settings: WidgetSettings;
  page: PageType;
  status: WidgetStatus;
  name: string;
};
const createWidgetInit = {
  creationType: WidgetCreationType.automated,
  viewType: WidgetViewType.carousel,
  settings: widgetSettingsInit,
  page: PageType.home,
  status: WidgetStatus.notInstalled,
  name: '',
};

const initialState: {
  widgets: Widget[];
  activeWidget: ActiveWidget | null;
  activeWidgetUpdatingStatus?: RequestStatus;
  createWidget: CreateWidgetInit | null;
  selectedWidgets: Widget['id'][];
  checkedVideos: VideoResponseDeprecated['id'][];
} = {
  widgets: [],
  activeWidget: null,
  createWidget: null,
  selectedWidgets: [],
  checkedVideos: [],
};

export const WidgetsSlice = createSlice({
  name: 'widgets',
  initialState,
  reducers: {
    setActiveWidget(state, action: PayloadAction<ActiveWidget | null>) {
      state.activeWidget = action.payload;
    },
    setWidgetType(state, action: PayloadAction<ActiveWidget['type']>) {
      if (state.activeWidget) {
        state.activeWidget.type = action.payload;
      }
    },
    setVideosToWidget(state, action: PayloadAction<ActiveWidget['videos']>) {
      if (state.activeWidget) {
        state.activeWidget.videos = action.payload;
      }
    },
    deleteVideosFromWidget(state, action: PayloadAction<VideoResponseDeprecated['id'][]>) {
      if (!state.activeWidget) return;
      state.activeWidget.videos = state.activeWidget.videos.filter((vid) => !action.payload.some((id) => id === vid.id));
    },
    setWidgets(state, action: PayloadAction<Widget[]>) {
      state.widgets = action.payload;
    },
    updateWidgetInStore(state, action: PayloadAction<Widget>) {
      state.widgets = state.widgets.map((widget) =>
        widget.id === action.payload.id ? action.payload : widget
      );
    },
    setNewWidget(
      state,
      action: PayloadAction<{
        key: keyof CreateWidgetInit;
        value: PageType | WidgetViewType | string;
      } | null>
    ) {
      if (state.activeWidget) {
        return;
      }
      if (!action.payload) {
        state.createWidget = null;
        return;
      }
      if (!state.createWidget) {
        state.createWidget = {
          ...createWidgetInit,
        };
      }
      state.createWidget = {
        ...state.createWidget,
        [action.payload.key]: action.payload.value,
      };
    },
    setStatus(state, action: PayloadAction<{
      widgetId: string | null
      newStatus: WidgetStatus
    }>) {
      if (state.activeWidget) {
        if (state.activeWidget.id === action.payload.widgetId || action.payload.widgetId === null) {
           state.activeWidget.status = action.payload.newStatus;
        }
      }
    },
    setWidgetName(state, action: PayloadAction<{
      widgetId: string | null
      newName: string
    }>) {
      if (state.activeWidget) {
        if (state.activeWidget.id === action.payload.widgetId || action.payload.widgetId === null) {
          state.activeWidget.name = action.payload.newName;
        }
      }

      if (action.payload.widgetId !== null) {
        const currentWidget = state.widgets.find((widget) => widget.id === action.payload.widgetId);
        if (currentWidget) {
          currentWidget.name = action.payload.newName;
        }
      }
    },
    setLayout(
      state,
      action: PayloadAction<{
        key: LayoutKey;
        value: LayoutValue;
      }>
    ) {
      if (!state.activeWidget) return state;
      state.activeWidget.settings.layout = {
        ...state.activeWidget.settings.layout,
        [action.payload.key]: action.payload.value,
      };
      if (action.payload.key === 'size') {
        state.activeWidget.settings.layout.spacing =
          spacingFromSize[action.payload.value as LayoutSize];
      }
    },
    setBehavior(state, action: PayloadAction<{ key: BehaviorKey; value: BehaviorValue }>) {
      if (!state.activeWidget) return state;
      state.activeWidget.settings.behavior = {
        ...state.activeWidget.settings.behavior,
        [action.payload.key]: action.payload.value,
      };
    },
    setBuyButton(state, action: PayloadAction<{ key: BuyButtonKey; value: BuyButtonValue }>) {
      if (!state.activeWidget) return state;
      state.activeWidget.settings.buyButton = {
        ...state.activeWidget.settings.buyButton,
        [action.payload.key]: action.payload.value,
      };
    },
    setBranding(state, action: PayloadAction<{ key: BrandingKey; value: BrandingValue }>) {
      if (!state.activeWidget) return state;
      state.activeWidget.settings.branding = {
        ...state.activeWidget.settings.branding,
        [action.payload.key]: action.payload.value,
      };
    },
    selectWidget(state, action: PayloadAction<Widget['id']>) {
      if (state.selectedWidgets.includes(action.payload)) {
        state.selectedWidgets = state.selectedWidgets.filter((id) => id !== action.payload);
      } else {
        state.selectedWidgets = [...state.selectedWidgets, action.payload];
      }
    },
    selectWidgets(state, action: PayloadAction<Widget['id'][]>) {
      state.selectedWidgets = action.payload;
    },
    clearSelectedWidgetsList(state) {
      state.selectedWidgets = [];
    },
    selectAllWidgets(state) {
      state.selectedWidgets = state.widgets.map((widget) => widget.id);
    },
    setAllPages(state, action: PayloadAction<boolean>) {
      if (!state.activeWidget) return state;
      if (action.payload) {
        state.activeWidget.allPages = true;
        state.activeWidget.pages = [];
      } else {
        state.activeWidget.allPages = false;
      }
    },
    setPages(state, action: PayloadAction<string[]>) {
      if (!state.activeWidget) return state;
      state.activeWidget.pages = action.payload;
    },
    checkVideo(state, action: PayloadAction<VideoResponseDeprecated['id'] | VideoResponseDeprecated['id'][]>) {
      if (Array.isArray(action.payload)) {
        state.checkedVideos = action.payload;
        return;
      }
      if (state.checkedVideos.includes(action.payload)) {
        state.checkedVideos = state.checkedVideos.filter((id) => id !== action.payload);
      } else {
        state.checkedVideos = [...state.checkedVideos, action.payload];
      }
    },
    uncheckAllVideos(state) {
      state.checkedVideos = [];
    },
    setWidgetUpdatingStatus(state, action: PayloadAction<RequestStatus>) {
      state.activeWidgetUpdatingStatus = action.payload;
    },
    setProductDetails(state, action: PayloadAction<ActiveWidget['productDetails']>) {
      if (!state.activeWidget) return state;
      state.activeWidget.productDetails = action.payload;
    },
  },
});

export const widgetsActions = WidgetsSlice.actions;
