import {useMutation} from "@tanstack/react-query";
import {postDuplicateWidget, PostDuplicateWidgetResponse} from "../../../api/widget/postDuplicateWidget";
import {useGetWidgetsManger} from "./useGetWidgetsManger";

interface UsePostDuplicateWidgetProps {
  onSuccess?: (res: PostDuplicateWidgetResponse) => void;
}

export const usePostDuplicateWidget = (props?: UsePostDuplicateWidgetProps) => {
  const getWidgetsManger = useGetWidgetsManger()

  const onSuccess = (res: PostDuplicateWidgetResponse) => {
    getWidgetsManger.refetch()
    props?.onSuccess?.(res)
  }

  return useMutation({
    mutationFn: async ({widgetId}: {widgetId: string}) => {
      return postDuplicateWidget({widgetId}).then(res => res.data)
    },
    onSuccess,
  })
}
