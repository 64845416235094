import cn from 'classnames';

import { CounterAndSelection } from 'src/shared/ui/CounterAndSelection/CounterAndSelection';

import styles from './add-video-modal-header.module.scss';

import type { FC } from 'react';

interface AddVideoModalHeaderProps {
  allLength: number;
  selectedLength: number;
  onSelectAll: () => void;
  onClearAll: () => void;
}

export const AddVideoModalHeader:FC<AddVideoModalHeaderProps> = (props) => {
  const { selectedLength } = props;

  const hasSelected = selectedLength > 0;

  return (
    <div className={cn(styles.wrapper, hasSelected && styles.active)}>
      {hasSelected ?
        <CounterAndSelection {...props} />
      : null}
    </div>
  )
}
