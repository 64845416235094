import { StatusesByVideoId, VideoResponseDeprecated } from './LibraryHeader/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PayloadActionWithCallbacks, PayloadCallbacks } from 'src/app/store';
import {postUploadVideoFromFileWorker, postVideoWasUploadedWorker} from "./workers";
import {PostVideoWasUploadedRequest} from "../../api/video/postVideoWasUploaded";

export interface DirectUploadLink {
  uploadUrl: string
  uploadId: string
}

const initialState: {
  statusesByVideoId: StatusesByVideoId;
  checked: VideoResponseDeprecated['id'][];
  directUpload?: DirectUploadLink;
  deletedVideoIds: string[];
} = {
  statusesByVideoId: {},
  checked: [],
  deletedVideoIds: [],
};

export const videosListSlice = createSlice({
  name: 'videoList',
  initialState,
  reducers: {
    checkVideo(state, action: PayloadAction<VideoResponseDeprecated['id']>) {
      if (state.checked.includes(action.payload)) {
        state.checked = state.checked.filter((id) => id !== action.payload);
      } else {
        state.checked = [...state.checked, action.payload];
      }
    },
    selectVideos(state, action: PayloadAction<VideoResponseDeprecated['id'][]>) {
      state.checked = action.payload;
    },
    uncheckAll(state) {
      state.checked = [];
    },
    markVideosAsDeleted(state, action: PayloadAction<VideoResponseDeprecated['id'][]>) {
      state.deletedVideoIds = [...state.deletedVideoIds, ...action.payload];
    }
  },
});

export const videoListActions = videosListSlice.actions;
