import { useState } from 'react'
import { Modal, TextInput, Button } from '@mantine/core'

import styles from './CardMenuDropDownRenameModal.module.scss'

import type { FC } from 'react'

interface CardMenuDropDownRenameModalProps {
  initialValue: string
  isOpen: boolean
  onRename: (name: string) => void
  onClose: () => void
}

export const CardMenuDropDownRenameModal:FC<CardMenuDropDownRenameModalProps> = (props) => {
  const { initialValue, isOpen, onRename, onClose } = props

  const [value, setValue] = useState(initialValue)

  const handleRename = () => {
    onRename(value)
    onClose()
  }

  return (
    <Modal
      id="item-rename"
      title="Rename widget"
      centered
      size="sm"
      opened={isOpen}
      onClose={onClose}
    >
      <TextInput value={value} onChange={(e) => setValue(e.target.value)} />

      <div className={styles.buttons}>
        <Button variant='outline' color='gray' onClick={onClose}>Cancel</Button>
        <Button color="green.1" disabled={!value} onClick={handleRename}>Rename</Button>
      </div>
    </Modal>
  )
}
