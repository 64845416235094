import {MantineTheme} from '@mantine/core';
import type {ButtonStylesParams, ContextStylesParams} from '@mantine/core';

const getVariant = (theme: MantineTheme, variant?: string) => {
  switch (variant) {
    case 'link':
      return {
        root: {
          color: theme.colors.greyLight[2],
          textDecoration: 'underline',
          textDecorationColor: theme.colors.greyLight[2],
        }
      };

  }
}

export const textOverride = {
  Text: {
    styles: (theme: MantineTheme, params: ButtonStylesParams, context: ContextStylesParams) => ({
      ...(getVariant(theme, context.variant) || {}),
    }),
  },
};
