import { useMutation } from "@tanstack/react-query";
import { queryClient, apiClient } from 'src/api/client';
import { QUERY_KEY } from 'src/shared/consts/queryKey';

export const useUpdateOnboardingStep = () =>
  useMutation({
    mutationFn: async (data: {stepName: string, isDone: boolean}) => {
      return apiClient.post('/bff/onboarding/update-step', data)
    },
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: [QUERY_KEY.ONBOARDING] })
    }
  })
