import {useEffect, useState} from "react";
import {refreshToken} from "../../api/updateToken";
import {userActions} from "../../components/User/store";
import Cookies from "js-cookie";
import {useDispatch, useSelector} from "react-redux";
import {selectUser} from "../../components/User/selectors";
import {useGetUser} from "./tanstackQuery/useGetUser";

export const useLogin = () => {
  const {data: user, refetch} = useGetUser()
  useEffect(() => {
    const params = new URL(window.location.href).searchParams;
    const access_tokenParams = params.get('access_token')
    const refresh_tokenParams = params.get('refresh_token')
    if (access_tokenParams) {
      Cookies.set('access_token', access_tokenParams);
    }
    if (refresh_tokenParams) {
      Cookies.set('refresh_token', refresh_tokenParams);
    }
    // const access_token = access_tokenParams || Cookies.get('access_token');
    // const refresh_token = refresh_tokenParams || Cookies.get('refresh_token');

  }, [user])
  return {
    isLoggedIn: !!user?.mantleAccessToken,
    user,
  }
}
