import {useDispatch, useSelector} from 'react-redux';
import { widgetsActions } from 'src/components/Widget/store';
import { WidgetStatus } from 'src/components/Widget/types';
import { useProgress } from './useProgress';
import { checkAndRefetchOnboardingData } from 'src/api/onboarding/checkAndRefetchOnboardingData';
import {useGetWidgetsManger} from "../tanstackQuery/useGetWidgetsManger";
import {usePutWidget} from "../tanstackQuery/usePutWidget";
import {selectActiveWidget} from "../../../components/Widget/selectors";
import {useFeatureRestriction} from "../useFeatureRestriction";

export const usePublishWidget = () => {
  const dispatch = useDispatch();
  const { startProgress, progress, finishProgress } = useProgress();
  const activeWidget = useSelector(selectActiveWidget)

  const publishActiveWidget =  (widgetId: string , widgetStatus: WidgetStatus) => {
    dispatch(widgetsActions.setStatus({
      widgetId,
      newStatus: widgetStatus,
    }));
  }

  const restrictions = useFeatureRestriction()
  const publishingHandler = (widgetId: string, unpublish?: boolean) => {
    if(restrictions.isLoading) {
      return;
    }
    if(widgetId) {
      const changeStatus = ()=>{
        startProgress();
        const widgetStatus = unpublish ? WidgetStatus.draft : WidgetStatus.published;
        const isActiveWidget = widgetId === activeWidget?.id;
        if(isActiveWidget) {
          publishActiveWidget(widgetId, widgetStatus);
        }

        finishProgress();
      }
      if(unpublish) {
        changeStatus();
      } else {
        restrictions.onPublishWidget(changeStatus);
      }
    }
  };

  return {
    publishingHandler,
    progress,
  };
};
