import { FC } from 'react';
import { LayoutKey, LayoutValue } from 'src/components/Widget/store';
import propWrapperStyles from '../PropWrapper.module.scss';
import { ColorInput, NumberInput, Slider, TextInput, Box } from '@mantine/core';
import { SegmentSwitch } from 'src/components/SegmentSwitch/SegmentSwitch';
import styles from './CarouselLayoutSettings.module.scss';
import { WidgetSettings } from 'src/components/Widget/types';

const headingFontWeight = [
  {
    value: 'light',
    label: 'Light',
  },
  {
    value: 'regular',
    label: 'Regular',
  },
  {
    value: 'bold',
    label: 'Bold',
  },
];
const headingAlignment = [
  {
    value: 'left',
    label: 'Left',
  },
  {
    value: 'center',
    label: 'Center',
  },
  {
    value: 'right',
    label: 'Right',
  },
];

interface SubmoduleProps {
  handler: (key: LayoutKey, value: LayoutValue) => void;
  layout: WidgetSettings['layout'];
}
interface BorderSubmoduleProps extends SubmoduleProps {
  showBorderThickness?: boolean;
}
export const BorderSubmodules: FC<BorderSubmoduleProps> = ({
  handler,
  layout,
  showBorderThickness,
}) => {
  return (
    <Box className={propWrapperStyles.submodule__container}>
      <Box className={propWrapperStyles.submodule}>
        <span className={propWrapperStyles.submodule__title}>Color</span>
        <ColorInput
          w={171}
          format="hex"
          placeholder="Pick a color"
          value={layout?.borderColor}
          onChange={(val) => handler('borderColor', val)}
        />
      </Box>
      {showBorderThickness && (
        <Box className={propWrapperStyles.submodule}>
          <span className={propWrapperStyles.submodule__title}>Thickness</span>
          <div className={styles.slider}>
            <Slider
              value={layout?.borderThickness}
              onChange={(val) => handler('borderThickness', val)}
              w={160}
              mr={12}
              max={10}
              min={0}
            />
            <NumberInput
              color="green"
              value={typeof layout?.borderThickness === 'number' ? layout?.borderThickness : 0}
              onChange={(val) => handler('borderThickness', val)}
              hideControls
              styles={() => ({
                input: { width: '42px', height: '26px', minHeight: '26px' },
              })}
              min={0}
              max={10}
              step={1}
              mr={6}
            />
            <div className={styles.slider__units}>px</div>
          </div>
        </Box>
      )}
    </Box>
  );
};

export const HeadingSubmodule: FC<SubmoduleProps> = ({ handler, layout }) => {
  return (
    <Box className={propWrapperStyles.submodule__container}>
      <Box className={propWrapperStyles.submodule}>
        <span className={propWrapperStyles.submodule__title}>Heading text</span>
        <TextInput
          w={261}
          placeholder="Your text"
          value={layout?.headingText}
          onChange={(e) => handler('headingText', e.target.value)}
        />
      </Box>
      <Box className={propWrapperStyles.submodule}>
        <span className={propWrapperStyles.submodule__title}>Font weight</span>
        <SegmentSwitch
          value={layout?.headingFontWeight ?? 'light'}
          data={headingFontWeight}
          onChange={(val) => handler('headingFontWeight', val)}
        />
      </Box>
      <Box className={propWrapperStyles.submodule}>
        <span className={propWrapperStyles.submodule__title}>Text color</span>
        <ColorInput
          w={171}
          format="hex"
          placeholder="Pick a color"
          value={layout?.headingTextColor}
          onChange={(val) => handler('headingTextColor', val)}
        />
      </Box>
      <Box className={propWrapperStyles.submodule}>
        <span className={propWrapperStyles.submodule__title}>Alignment</span>
        <SegmentSwitch
          value={layout?.headingAlignment ?? 'center'}
          data={headingAlignment}
          onChange={(val) => handler('headingAlignment', val)}
        />
      </Box>
    </Box>
  );
};
