import {apiClient} from "../client";
import {QueryVideoFilter} from "./getVideos";
import {AxiosRequestConfig} from "axios";

type Config = AxiosRequestConfig

export type FetchVideosCountForAllFiltersResponse = {
  count: number;
  type: QueryVideoFilter;
}[];

export const getVideosCountForAllFilters = (config?: Config) => {
  return apiClient.get<FetchVideosCountForAllFiltersResponse>(`/bff/video/platform/count-for-all-filters`, config);
};
