import {useMemo} from "react";

const setStartTime = (...urls: URL[]) => {
  urls.forEach(url => {
    url.searchParams.append('time', '0');
  });
}

export const getVideoPreviewFromMuxId = (muxId: string | null, width?: number) => {
  const playbackId =muxId;
  const animateGif = new URL(`${playbackId}/animated.gif`, 'https://image.mux.com')
  const animateWebp = new URL(`${playbackId}/animated.webp`, 'https://image.mux.com')
  const imageJpg = new URL(`${playbackId}/thumbnail.jpg`, 'https://image.mux.com',)
  const imageWebp = new URL(`${playbackId}/thumbnail.webp`, 'https://image.mux.com')
  if (width) {
    animateGif.searchParams.append('width', width.toString());
    animateWebp.searchParams.append('width', width.toString());
  }


  setStartTime(animateGif, animateWebp, imageJpg, imageWebp);


  return {
    animateGif: animateGif.toString(),
    animateWebp: animateWebp.toString(),
    imageJpg: imageJpg.toString(),
    imageWebp: imageWebp.toString(),
  }
}

export const useVideoPreviewFromMuxId = (muxId?: string | null, width?: number) => {

  return useMemo(() => {
    if (muxId) {
      return getVideoPreviewFromMuxId(muxId, width)
    }
    return null
  }, [muxId, width]);

};
