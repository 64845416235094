import { ActionIcon, Box, createStyles, Image, Text } from '@mantine/core';
import { FC } from 'react';
import { ReactComponent as IconDelete } from 'src/shared/icons/delete.svg';

const useStyles = createStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '10px 12px',
    '&:not(:last-child)': {
      borderBottom: `1px solid ${theme.colors.greyDark[1]}`,
    },
  },
  infoWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    width: '20px',
    height: '20px',
    path: {
      stroke: theme.colors.greyLight[2],
    },
  },
}));

interface SelectedPageItemProps {
  image: string | null;
  title: string;
  onDelete: () => void;
}
export const SelectedPageItem: FC<SelectedPageItemProps> = ({ image, title, onDelete }) => {
  const { classes } = useStyles();
  return (
    <Box className={classes.root}>
      <Box className={classes.infoWrapper}>
        <Image src={image} alt="product image" width={32} height={32} mr={16} radius="sm" />
        <Text color="greyLight.2">{title}</Text>
      </Box>
      <ActionIcon onClick={onDelete} variant="transparent">
        <IconDelete className={classes.icon} />
      </ActionIcon>
    </Box>
  );
};
