import React from 'react';
import { useLocalStorage } from 'src/shared/hooks/useLocalStorage';
import { VideoItem } from '../VideoItem/VideoItem';
import { Col, Row } from 'antd';
import styles from './VideosList.module.scss';

export type Video = {
  id: number;
  caption: string;
  media_url: string;
  thumbnail_url: string;
};

export const VideosList = () => {
  const [userVideos] = useLocalStorage<Video[]>('user_videos');

  return (
    <Row className={styles.videosListRow}>
      {userVideos?.map((video) => (
        <Col
          key={video.id}
          className={styles.videoItemContainer}
          span={6}
          xs={24}
          sm={24}
          md={8}
          lg={6}
          xl={6}
        >
          {/* <VideoItem video={video} /> */}
        </Col>
      ))}
    </Row>
  );
};
