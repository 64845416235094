import {Menu} from '@mantine/core';
import {IconDotsVertical, IconToggleLeft, IconToggleRight} from '@tabler/icons-react';
import {FC} from 'react';
import styles from './CardMenuDropdown.module.scss';
import {ReactComponent as IconPencilSquare} from 'src/shared/icons/pencilSquare.svg';
import {ReactComponent as IconDuplicate} from 'src/shared/icons/duplicate.svg';
import {ReactComponent as IconDelete} from 'src/shared/icons/delete.svg';
import {useNavigate} from 'react-router-dom';
import {CardMenuDropDownRenameModal} from './CardMenuDropDownRenameModal/CardMenuDropDownRenameModal';
import {useOpen} from 'src/shared/hooks/useOpen';
import {usePutWidget} from "../../shared/hooks/tanstackQuery/usePutWidget";
import {checkAndRefetchOnboardingData} from "../../api/onboarding/checkAndRefetchOnboardingData";
import {WidgetStatus} from "../Widget/types";
import {useGetWidgetsManger} from "../../shared/hooks/tanstackQuery/useGetWidgetsManger";

export interface CardMenuDropdownProps {
  itemId: string;
  itemTitle?: string;
  showRename?: boolean;
  showInstall?: boolean;  //TODO remove it after refactoring
  showPublish?: boolean;   //TODO remove it after refactoring
  showUnpublish?: boolean; //TODO remove it after refactoring
  onRename: (name: string) => void;
  onDelete: () => void;
  onDuplicate?: () => void;
  onPublish?: () => void;
  onUnpublish?: () => void;
}
//TODO refactor this component to make it not common for Video and Widget
export const CardMenuDropdown: FC<CardMenuDropdownProps> = (props) => {
  const {
    itemId,
    itemTitle,
    showRename,
    showInstall,
    showPublish,
    showUnpublish,
    onRename,
    onDelete,
    onDuplicate,
    onPublish,
    onUnpublish,
  } = props;

  const navigate = useNavigate();

  const renameItemPopupManager = useOpen()



  return (
    <div>
      <div className={styles.menu}>
        <Menu width={163} position="right-start">
          <Menu.Target>
            <IconDotsVertical className={styles.menuIcon} size={16} />
          </Menu.Target>
          <Menu.Dropdown className={styles.dropdown}>
           {showInstall ?
              <Menu.Item
                className={styles.dropdown__item}
                icon={<IconToggleRight size={14} />}
                onClick={() => navigate(`/widgets/edit/${itemId}?defaultTab=installation`)}
              >
                <span>Install</span>
              </Menu.Item>
            : null}

            {showPublish ?
              <Menu.Item
                className={styles.dropdown__item}
                icon={<IconToggleRight size={14} />}
                onClick={onPublish}
              >
                <span>Publish</span>
              </Menu.Item>
              : null}

            {showRename ?
              <Menu.Item
                className={styles.dropdown__item}
                onClick={renameItemPopupManager.open}
                icon={<IconPencilSquare className={styles.dropdown__itemIcon} />}
              >
                <span>Rename</span>
              </Menu.Item>
            : null}

            {onDuplicate ?
              <Menu.Item
                className={styles.dropdown__item}
                onClick={onDuplicate}
                icon={<IconDuplicate className={styles.dropdown__itemIcon} />}
              >
                <span>Duplicate</span>
              </Menu.Item>
            : null}


           {showUnpublish ?
              <Menu.Item
                className={styles.dropdown__item}
                icon={<IconToggleLeft size={14} />}
                onClick={onUnpublish}
              >
                <span>Unpublish</span>
              </Menu.Item>
              : null}

            <Menu.Item
              className={styles.dropdown__item}
              onClick={onDelete}
              icon={<IconDelete className={styles.dropdown__itemIcon} />}
            >
              <span className={styles.deleteBtn__text}>Delete</span>
            </Menu.Item>
      </Menu.Dropdown>
      </Menu>
    </div>

    <CardMenuDropDownRenameModal
        initialValue={itemTitle || ''}
        isOpen={renameItemPopupManager.isOpen}
        onRename={onRename}
        onClose={renameItemPopupManager.close}
      />
    </div>
  );
};
