import { BehaviorAutoplay } from 'src/components/Widget/types';
import { useEffect, useState } from 'react';

export const useVideoPlayerAutoplay = (autoplay?: BehaviorAutoplay, thumbnail?: string | null) => {
  const [poster, setPoster] = useState<string | undefined | null>(null);

  const onMouseEnter = () => {
    if (autoplay === BehaviorAutoplay.hover && thumbnail) {
      setPoster(thumbnail);
    }
  };
  const onMouseLeave = () => {
    if (autoplay === BehaviorAutoplay.hover && thumbnail) {
      setPoster(null);
    }
  };

  useEffect(() => {
    if (autoplay === BehaviorAutoplay.hover && thumbnail) {
      setPoster(null);
      return;
    }
    setPoster(thumbnail);
  }, [autoplay, thumbnail]);
  return {
    poster: poster ?? undefined,
    onMouseEnter,
    onMouseLeave,
  };
};
