import { useState, useMemo, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { widgetsActions } from 'src/components/Widget/store';

import { Widget } from 'src/components/Widget/types';
import { WIDGETS_FILTER_KEY } from 'src/shared/consts';
import {useInfiniteQuery} from "@tanstack/react-query";
import {getWidgets} from "../../api/widget/getWidgets";
import {queryKey} from "../query/keys";
import {useGetWidgets} from "./tanstackQuery/useGetWidgets";
import {useGetWidgetsManger} from "./tanstackQuery/useGetWidgetsManger";

export type Category = 'pageType' | 'widgetType' | 'widgetStatus';
export type WidgetsFilter = {
  [key in Category]: Record<string, boolean>;
};

const filterState = {
  pageType: {
    home: false,
    product: false,
    collection: false,
  },
  widgetType: {
    carousel: false,
    stories: false,
  },
  widgetStatus: {
    published: false,
    draft: false,
  },
};

export const useFilterWidgetsList = () => {
  const location = useLocation();

  const dispatch = useDispatch();
  const {data: allWidgetsPages, isLoading} = useGetWidgets();
  const {editGetWidgets} = useGetWidgetsManger();
  const allWidgets = allWidgetsPages?.pages.flat(2) as  Widget[]  || [];

  const [filter, setFilter] = useState<WidgetsFilter>(() => {
    const params = new URLSearchParams(location.search);
    const filterParams: WidgetsFilter = {
      pageType: {
        home: params.get('home') === 'true',
        product: params.get('product') === 'true',
        collection: params.get('collection') === 'true',
      },
      widgetType: {
        carousel: params.get('carousel') === 'true',
        stories: params.get('stories') === 'true',
      },
      widgetStatus: {
        published: params.get('published') === 'true',
        draft: params.get('draft') === 'true',
        notInstalled: params.get('notInstalled') === 'true',
      },
    };
    return filterParams;
  });

  const updateQueryParams = useCallback((newFilter: WidgetsFilter) => {
    const newParams = new URLSearchParams();
    Object.keys(newFilter).forEach((category) => {
      const categoryFilters = newFilter[category as Category];
      const activeFilters = Object.entries(categoryFilters).filter(([_, value]) => value === true);
      activeFilters.forEach(([key, _]) => {
        newParams.set(key, 'true');
      });
    });
    const search = newParams.toString();
    window.history.replaceState(null, '', `?${search}`);
  }, []);

  const saveFilterToSessionStorage = useCallback((newFilter: WidgetsFilter) => {
    sessionStorage.setItem(WIDGETS_FILTER_KEY, JSON.stringify(newFilter));
  }, []);

  const filterHandler = useCallback(
    (category: Category, key: keyof WidgetsFilter[Category]) => {
      dispatch(widgetsActions.clearSelectedWidgetsList())

      setFilter((prev) => {
        const updatedFilter = {
          ...prev,
          [category]: {
            ...prev[category],
            [key]: !prev[category][key],
          },
        };
        updateQueryParams(updatedFilter);
        saveFilterToSessionStorage(updatedFilter);
        return updatedFilter;
      });
    },
    [updateQueryParams, saveFilterToSessionStorage]
  );

  const resetFilter = useCallback(() => {
    setFilter(filterState);
    updateQueryParams(filterState);
    saveFilterToSessionStorage(filterState);
  }, [updateQueryParams, saveFilterToSessionStorage]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const filterParams: WidgetsFilter = {
      pageType: {
        home: params.get('home') === 'true',
        product: params.get('product') === 'true',
        collection: params.get('collection') === 'true',
      },
      widgetType: {
        carousel: params.get('carousel') === 'true',
        stories: params.get('stories') === 'true',
      },
      widgetStatus: {
        published: params.get('published') === 'true',
        draft: params.get('draft') === 'true',
        notInstalled: params.get('notInstalled') === 'true',
      },
    };
    setFilter(filterParams);
  }, [location.search]);
  const filteredWidgets = useMemo(() => {
    return widgetFilter(allWidgets, filter);
  }, [filter, allWidgets]);


  return {
    filteredWidgets,
    filterHandler,
    filter,
    resetFilter,
    editGetWidgets,
    isLoading,
  };
};

// TODO fix installHook.js:1 Error in widgetFilter TypeError: Cannot read properties of undefined (reading 'general')
//     at useFilterWidgetsList.ts:127:1
function widgetFilter(widgets: Widget[], filter: WidgetsFilter) {
  try {
    return widgets.filter((w) => {
      const {
        page,
        settings: {general: widgetViewType},
        status,
      } = w;
      const {pageFilter, typeFilter, statusFilter} = checkActiveFilters(filter);
      const passPageFilter = pageFilter ? pageFilter[page] : true;
      const passTypeFilter = typeFilter ? typeFilter[widgetViewType?.widgetViewType] : true;
      const passStatusFilter = statusFilter ? statusFilter[status] : true;

      return passPageFilter && passTypeFilter && passStatusFilter;
    });
  } catch (error) {
    console.error('Error in widgetFilter', error);
    return [];
  }
}
function checkActiveFilters(filter: WidgetsFilter) {
  const filtersCategories: { name: string; category: Category }[] = [
    { name: 'pageFilter', category: 'pageType' },
    { name: 'typeFilter', category: 'widgetType' },
    { name: 'statusFilter', category: 'widgetStatus' },
  ];
  let filters: Record<string, Record<string, boolean> | null> = {};
  filtersCategories.forEach(({ category, name }) => {
    const currentFilter = Object.entries(filter[category]).filter(([_, value]) => value === true);
    filters = {
      ...filters,
      [name]: Object.values(currentFilter).length < 1 ? null : Object.fromEntries(currentFilter),
    };
  });
  return filters;
}

type FilterItems = {
  [key in Category]: {
    title: string;
    category: Category;
    key: string;
  }[];
};
export const filterItems: FilterItems = {
  pageType: [
    {
      title: 'Home',
      category: 'pageType',
      key: 'home',
    },
    {
      title: 'Product',
      category: 'pageType',
      key: 'product',
    },
    {
      title: 'Collection',
      category: 'pageType',
      key: 'collection',
    },
  ],
  widgetType: [
    {
      title: 'Carousel',
      category: 'widgetType',
      key: 'carousel',
    },
    {
      title: 'Stories',
      category: 'widgetType',
      key: 'stories',
    },
  ],
  widgetStatus: [
    {
      title: 'Published',
      category: 'widgetStatus',
      key: 'published',
    },
    {
      title: 'Not Published',
      category: 'widgetStatus',
      key: 'draft',
    },
    {
      title: 'Not Installed',
      category: 'widgetStatus',
      key: 'notInstalled',
    },
  ],
};

