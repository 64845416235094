import { AxiosRequestHeaders } from 'axios';
import { CreateAutomatedWidget, CreateManualWidget } from 'src/components/Widget/types';
import { apiClient } from '../client';

type Config = (CreateAutomatedWidget | CreateManualWidget) & { headers?: AxiosRequestHeaders };

type PostWidgetResponse = {
  id: string;
}

export const postWidget = ({ headers, ...data }: Config) => {
  return apiClient.post<PostWidgetResponse>(`/bff/widgets/`, data, { headers });
};
