import { AxiosRequestConfig } from 'axios';
import { VideoResponseDeprecated } from 'src/components/Library/LibraryHeader/types';
import { apiClient } from '../client';

interface Config extends AxiosRequestConfig {
  params: {
    id?: VideoResponseDeprecated['id'];
  };
}
export const deleteVideo = ({ params }: Config) => {
  return apiClient.delete(`/bff/video/${params.id}`);
};
