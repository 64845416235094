import { Box, createStyles } from '@mantine/core';
import MuxPlayer from '@mux/mux-player-react';
import MuxPlayerElement from '@mux/mux-player/.';
import classNames from 'classnames';
import {CSSProperties, FC, useMemo, useRef, useState} from 'react';
import { PlayStopButton, TimeManager } from './components';
import { BehaviorAnimation, BehaviorAutoplay } from 'src/components/Widget/types';
import { useVideoPlayerAutoplay } from 'src/shared/hooks/useVideoPlayerAutoplay';
import {useOptimizedThumbnail} from "../../hooks/useOptimizedThumbnail";

interface Props {
  id: string;
  icon?: string;
  hideTimeline?: boolean;
  hidePlayButton?: boolean;
  playButtonSize?: number;
  animation?: BehaviorAnimation;
  thumbnail?: string | null;
  autoplay?: BehaviorAutoplay;
  partOf?: 'stories' | 'carousel';
  coverVideo?: boolean;
}

const storiesStyles = {
  '--media-object-fit': 'cover',
  ' --media-object-position': 'center',
};

const style = {
  '--controls': 'none',
} as CSSProperties;

const useStyles = createStyles(() => ({
  root: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  muxVideo__cover: {
    '--media-object-fit': 'cover',
  },
  muxVideo: {
    width: '100%',
    height: '100%',
    '&::part(bottom time range)': {
      margin: '0 12px',
      width: 'calc(100% - 24px)',
    },
    '&::part(center play button)': {
      backgroundColor: 'rgba(26, 27, 30, 0.50)',
      width: '100px',
      height: '100px',
    },
  },
  controls: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  playClickableArea: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: -1,
    '& > *': {
      opacity: 0,
      visibility: 'hidden',
      transition: 'visibility 1s, opacity 1s',
    },
    '&:hover > *': {
      opacity: 1,
      visibility: 'visible',
    },
  },
  playButtonVisible: {
    '& > *': {
      opacity: 1,
      visibility: 'visible',
    },
  },
  logo: {
    position: 'absolute',
    left: 10,
    top: 40,
    height: '15px',
    width: 'auto',
  },
}));
export const VideoPlayer: FC<Props> = ({
  id,
  icon,
  hideTimeline,
  playButtonSize,
  animation,
  thumbnail,
  autoplay,
  hidePlayButton,
  partOf,
  coverVideo,
}: Props) => {
  const { classes } = useStyles();
  const video = useRef<MuxPlayerElement | null>(null);
  const [paused, setPaused] = useState(true);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);

  const handleTimelineChange = (value: number) => {
    if (!video.current) return;
    video.current.currentTime = value;
    setCurrentTime(value);
  };

  const onTimeUpdate = () => {
    if (!video.current) return;
    setCurrentTime(video.current.currentTime);
  };
  const onLoadedMetadata = () => {
    if (!video.current) return;
    setDuration(video.current.duration);
  };
  const clickableAreaClasses = classNames(classes.playClickableArea, {
    [classes.playButtonVisible]: paused,
  });
  const optimizedThumbnail = useOptimizedThumbnail(thumbnail);

  const { poster, onMouseEnter, onMouseLeave } = useVideoPlayerAutoplay(autoplay, optimizedThumbnail?.webpUrl || thumbnail);

  const muxVideoClasses = classNames(classes.muxVideo, {
    [classes.muxVideo__cover]: coverVideo,
  });

  return (
    <Box className={classes.root} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <Box className={classes.controls}>
        {icon && <img src={icon} className={classes.logo} alt="brand logo" />}
        {!hideTimeline && (
          <TimeManager
            elapsed={currentTime}
            duration={duration}
            onTimeChange={handleTimelineChange}
          />
        )}
        <Box className={clickableAreaClasses} onClick={() => setPaused(!paused)}>
          {!hidePlayButton && (
            <PlayStopButton
              state={paused ? 'pause' : 'play'}
              onClick={() => setPaused(!paused)}
              size={playButtonSize}
              animation={animation}
            />
          )}
        </Box>
      </Box>
      <MuxPlayer
        ref={video}
        paused={paused}
        playbackId={id}
        playsInline={true}
        style={{
          ...style,
          ...(partOf === 'stories' ? storiesStyles : {}),
        }}
        streamType="on-demand"
        className={muxVideoClasses}
        onEnded={() => setPaused(true)}
        onTimeUpdate={onTimeUpdate}
        onLoadedMetadata={onLoadedMetadata}
        poster={poster}
      />
    </Box>
  );
};
