import { Box, createStyles, SegmentedControlItem } from '@mantine/core';
import { ReactElement } from 'react';
import { SegmentSwitch } from '../SegmentSwitch/SegmentSwitch';

interface TabsProps<T extends SegmentedControlItem> {
  currentTab: string;
  onChange: (val: T['value']) => void;
  data: T[];
  children: ReactElement | ReactElement[];
}

const useStyles = createStyles(() => ({
  container: {
    width: '100%',
    height: '100%',
  },
  controlPanel: {
    display: 'flex',
    marginBottom: '18px',
  },
  contentWrapper: {
    display: 'flex',
    flexGrow: 1,
    height: 'calc(100% - 54px)', // 54px height of tabs control components with margin bottom
    position: 'relative',
  },
  scrollableArea: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    overflow: 'auto',
  },
}));

export const Tabs = <T extends SegmentedControlItem>({
  currentTab,
  onChange,
  data,
  children,
}: TabsProps<T>): ReactElement<TabsProps<T>> => {
  const { classes } = useStyles();
  return (
    <Box className={classes.container}>
      <Box className={classes.controlPanel}>
        <SegmentSwitch onChange={onChange} value={currentTab} data={data} size="md" />
      </Box>
      <Box className={classes.contentWrapper}>
        <Box className={classes.scrollableArea}>{children}</Box>
      </Box>
    </Box>
  );
};
