import {useQuery} from "@tanstack/react-query";
import {queryKey} from "../../query/keys";
import {getUser} from "../../../api/me/getUser";

export const useGetUser = () => {
  return useQuery({
    queryKey: queryKey.user,
    queryFn: ()=> getUser().then(res => res.data),
  })
}
