import {apiClient, queryClient} from './client';
import Cookies from 'js-cookie';
import { PATH } from '../shared/consts';
import {queryKey} from "../shared/query/keys";

interface Data {
  accessToken: string;
  refreshToken: string;
}

export const refreshToken = () =>
  apiClient
    .post<Data>('/bff/auth/refresh-token', {
      refreshToken: Cookies.get('refresh_token'),
    })
    .then(({ data }) => {
      Cookies.set('access_token', data.accessToken);
      Cookies.set('refresh_token', data.refreshToken);
      return data;
    });
