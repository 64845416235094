import {FetchVideosResponse, getVideos, QueryVideoFilter} from "../../api/video/getVideos";
import {useEffect, useMemo} from "react";
import {useInfiniteQuery, useQuery} from "@tanstack/react-query";
import {queryKey} from "../query/keys";
import {MainVideoFilter, SourceFilter} from "../../components/Library/LibraryLayout/LibraryLayout";
import {queryClient} from "../../api/client";
import {getVideo} from "../../api/video/getVideo";

export const useVideos = ({
                            videoQueryFilter
                          }: {
  videoQueryFilter: QueryVideoFilter
}) => {


  const updateVideoStatus = async (videoId: string) => {
    const updatedVideo = await getVideo({
      videoId
    }).then((res) => res.data);

    queryClient.setQueryData<{ pages: FetchVideosResponse[] }>(queryKey.videos({filter: videoQueryFilter}), (oldData) => {
      if (!oldData) return oldData;
      const updatedPages = oldData.pages.map((page) => {
        const updatedPage = page.map(video => {
          if (updatedVideo.id === video.id && updatedVideo.status !== 'Loading') {
            return {
              ...video,
              ...updatedVideo,
            }
          }
          return video
        })
        return updatedPage
      });
      return {
        ...oldData,
        pages: updatedPages
      }
    });
  };

  const initialPageParam: {
    skip: number,
    take: number,
    filter?: QueryVideoFilter
  } = {
    skip: 0,
    take: 25,
    filter: videoQueryFilter,
  }
  const infiniteQuery = useInfiniteQuery({
    queryKey: queryKey.videos({
      filter: videoQueryFilter,
    }),
    queryFn: async ({pageParam}) => {
      return getVideos({
        params: {
          take: pageParam?.take,
          skip: pageParam?.skip,
          filter: pageParam?.filter
        }
      }).then((res) => res.data)
    },
    initialPageParam,
    getNextPageParam: (lastPage, allPages) => {
      if (lastPage?.length === 0) {
        return undefined
      }
      return {
        skip: allPages.flat(2).length,
        take: 25,
        filter: videoQueryFilter,
      }
    }
  })


  useEffect(() => {
    const interval = setInterval(() => {
      const pages = infiniteQuery.data?.pages || [];
      const loadingVideos = pages.flat().filter((video: FetchVideosResponse[0]) => video.status === 'Loading');

      loadingVideos.forEach((video) => {
        updateVideoStatus(video.id);
      });
    }, 3000);

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [infiniteQuery.data, queryClient]);

  return infiniteQuery
}
