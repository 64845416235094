import {useGetWidgets} from "./tanstackQuery/useGetWidgets";
import {useInfiniteQuery, useQuery} from "@tanstack/react-query";
import {queryKey} from "../query/keys";
import {getWidgets} from "../../api/widget/getWidgets";

export const useGetPublishedWidgetsCount = () => {
  return useQuery({
    queryKey: queryKey.publishedWidgetsCount,
    queryFn: async () => {
      return getWidgets().then((res) => res.data);
    },
    select: (data) => {
      return data.filter((widget) => widget.status === 'published')?.length;
    }
  })

}
