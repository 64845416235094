import { FC, ReactNode, useState } from 'react';
import { Layout } from 'antd';
import styles from './Layout.module.scss';
import { Link } from 'react-router-dom';
import logo from '../../shared/images/logoWhite.svg';
import logoClose from '../../shared/images/logoClose.svg';
import { Websites } from '../Websites';
import cn from 'classnames';
import { ChevronLeft, ChevronRight } from 'tabler-icons-react';
import { Box } from '@mantine/core';

interface LayoutProps {
  children: ReactNode;
}

const { Sider } = Layout;
export const BaseLayout: FC<LayoutProps> = ({ children }) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);

  return (
    <>
      <Layout className={styles.layout}>
        <Box sx={{ height: '100vh', display: 'flex', width: '100%' }}>
          <Sider
            theme="dark"
            collapsible
            width={300}
            trigger={null}
            collapsed={isCollapsed}
            collapsedWidth={60}
            style={{ backgroundColor: '#1a1b1e', transition: 'width 0.1s linear' }}
          >
            <div className={isCollapsed ? styles.sidebar__close : styles.sidebar}>
              <div className={isCollapsed ? styles.logoHeader__close : styles.logoHeader}>
                <Link className={styles.logoWrapper} to="/">
                  <img
                    className={cn(isCollapsed ? styles.close : styles.logo)}
                    src={logo}
                    alt="VideoPoint"
                  />
                  <img
                    className={cn(isCollapsed ? '' : styles.close)}
                    src={logoClose}
                    alt="VideoPoint"
                  />
                </Link>
              </div>
              <div className={styles.content}>
                <div className={isCollapsed ? styles.contentInner__close : styles.contentInner}>
                  {/*todo move too other component */}
                  <Websites collapse={isCollapsed} />
                </div>
                <div className={isCollapsed ? styles.collapsed : styles.noCollapsed}>
                  <span className={styles.collapseBtn} onClick={() => setIsCollapsed(!isCollapsed)}>
                    {isCollapsed ? (
                      <ChevronRight color="#A6A7AB" strokeWidth={1} />
                    ) : (
                      <ChevronLeft color="#A6A7AB" strokeWidth={1} />
                    )}
                  </span>
                </div>
              </div>
            </div>
          </Sider>
          <Layout>
            <div className={styles.content}>{children}</div>
          </Layout>
        </Box>
      </Layout>
    </>
  );
};
