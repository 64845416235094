import { Box, createStyles } from '@mantine/core';
import { Link } from 'react-router-dom';
import { GradientButton } from 'src/components/GradientButton/GradientButtom';
// import { LibrarySearch } from 'src/components/Search/Search';
import { useSelector } from 'react-redux';
import { selectSelectedWidgets } from '../selectors';
import { SelectedWidgetsManager } from './SelectedWidgetsManager';

import type { FC } from 'react';
import { Widget } from '../types';

export const useStyles = createStyles((theme) => ({
  container: {
    height: '60px',
    padding: '12px 16px',
    borderBottom: `1px solid ${theme.colors.greyDark['4']}`,
    backgroundColor: theme.colors.black['0'],
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
}));

interface WidgetsHeaderProps {
  filteredWidgets: Widget[]
}

export const WidgetsHeader:FC<WidgetsHeaderProps> = (props) => {
  const { classes } = useStyles();

  const selectedWidgets = useSelector(selectSelectedWidgets);
  if (selectedWidgets.length > 0) {
    return <SelectedWidgetsManager {...props} />;
  }

  return (
    <Box className={classes.container}>
      {/* <LibrarySearch /> */}
      <Link to={'/widgets/new'}>
        <GradientButton>Create Widget</GradientButton>
      </Link>
    </Box>
  );
};
