import { useInterval } from '@mantine/hooks';
import { useState } from 'react';

export const useProgress = () => {
  const [progress, setProgress] = useState(0);

  const interval = useInterval(
    () =>
      setProgress((current) => {
        if (current < 100) {
          return current + 1;
        }
        interval.stop();
        return 0;
      }),
    10
  );
  const startProgress = () => {
    if (interval.active) return;
    interval.start();
  };
  const finishProgress = () => {
    interval.stop();
    setProgress(0);
  };

  return {
    progress,
    startProgress,
    finishProgress,
    setProgress,
  };
};
