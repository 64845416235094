import cn from 'classnames'
import { useNavigate } from 'react-router-dom'

import { Checkbox } from '@mantine/core'

import styles from './OnboardingStepperList.module.scss'

import { FC } from 'react'
import { OnboardingStep } from '../onboarding-stepper.interfaces'

interface OnboardingStepperListProps {
    className?: string
    steps: OnboardingStep[]
}

export const OnboardingStepperList: FC<OnboardingStepperListProps> = (props) => {
  const { className, steps } = props

  const navigate = useNavigate()

  const handleClick = (step: OnboardingStep) => {
    const { isDone, target } = step
    if (!isDone) {
      navigate(target)
    }
  }

  return (
    <div className={className}>
        <div className={styles.inner}>
            {steps.map((step) => (
                <div className={styles.step} key={step.id}>
                    <div className={cn(styles.stepInner, step.isDone && styles.stepInner__passed)}>
                        <span onClick={() => handleClick(step)}>{step.label}</span>

                        <Checkbox checked={step.isDone} readOnly={true} radius="xl" color="green.1" />
                    </div>
                </div>
            ))}
        </div>
    </div>
  )
}
