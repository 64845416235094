import {useQuery} from "@tanstack/react-query";
import {getMaintenanceStatus} from "../../../api/maintenance/getMaintenanceStatus";
import {queryKey} from "../../query/keys";

export const useGetMaintenanceStatus = () => {
  return useQuery({
    queryKey: queryKey.maintenanceStatus,
    queryFn: getMaintenanceStatus,
  })
}
