import {useMemo, useState, useEffect, useCallback} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectActiveWidget } from 'src/components/Widget/selectors';
import { widgetsActions } from 'src/components/Widget/store';
import { usePagesData } from 'src/shared/hooks/usePagesData';
import { useDisclosure } from '@mantine/hooks';

const compareArraysByValues = (arr1: string[], arr2: string[]) => {
  let isSame = true;
  if (arr1.length !== arr2.length) return false;
  const arrayToCompareWith = Object.fromEntries(arr2.map((el) => [el, true]));
  arr1.forEach((item) => {
    if (!arrayToCompareWith[item]) {
      isSame = false;
      return;
    }
  });
  return isSame;
};

export const useTargetingSelect = () => {
  const dispatch = useDispatch();
  const { selectData } = usePagesData();
  const activeWidget = useSelector(selectActiveWidget);

  const defaultSelectValue = useMemo(() => activeWidget?.pages ?? [], [activeWidget]);
  const [selectValue, setSelectValue] = useState<string[]>(defaultSelectValue);
  const [search, setSearch] = useState<string | undefined>();
  const [dropdownState, dropdownHandler] = useDisclosure(false);
  // check if value of select was changed
  const isArraysSame = useMemo(
    () => compareArraysByValues(defaultSelectValue, selectValue),
    [selectValue, defaultSelectValue]
  );

  const searchHandler = (val?: string) => {
    setSearch(val);
  };
  const onChange = useCallback((value: string) => {
    setSelectValue((current) => {
      if (current.includes(value)) {
        return current.filter((item) => item !== value);
      }
      return [...current, value];
    })
  }, []);
  const onCancel = () => {
    setSelectValue(defaultSelectValue);
    dropdownHandler.close();
  };

  const onConfirm = () => {
    if (!activeWidget || isArraysSame) return;
    dispatch(widgetsActions.setPages(selectValue));
    dropdownHandler.close();

  };

  const renderData = useMemo(() => {
    if (!search) return selectData;
    return selectData.filter((item) => item.label.toLowerCase().includes(search.toLowerCase()));
  }, [search, selectData]);

  useEffect(() => {
    setSelectValue(defaultSelectValue);
  }, [defaultSelectValue]);

  useEffect(() => {
    const closeDropdown = (e: MouseEvent) => {
      const el = e.target as Element | null;
      if (!el?.closest('[data-select="true"]')) {
        dropdownHandler.close();
      }
    };
    window.addEventListener('click', closeDropdown);
    return () => {
      window.removeEventListener('click', closeDropdown);
    };
  }, []);
  return {
    onChange,
    onCancel,
    onConfirm,
    disableConfirmButton: isArraysSame,
    selectData: renderData,
    selectValue,
    dropdownHandler,
    dropdownState,
    searchHandler,
    search,
  };
};
