import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import 'roboto-fontface/css/roboto/roboto-fontface.css';
import '@fontsource/open-sans';
import App from './app/App';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
