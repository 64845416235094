import { Box, Flex, Skeleton } from '@mantine/core';
import { FC } from 'react';
import { LayoutSize } from 'src/components/Widget/types';
import { getCarouselConfiguration } from '../../config/helperFunctions';
import { CarouselSkeletonFooterProps } from '../../config/types';

const skeletonConfig = {
  onlyDots: {
    numOfComponentsAtBothSides: 4,
    generalSkeletonHeight: 3,
    centeredSkeletonHeight: 21,
  },
  onlyHeading: {
    numOfComponentsAtBothSides: 3,
    generalSkeletonHeight: 3,
    centeredSkeletonHeight: 15,
  },
  none: {
    numOfComponentsAtBothSides: 4,
    generalSkeletonHeight: 6,
    centeredSkeletonHeight: 32,
  },
  both: {
    numOfComponentsAtBothSides: 0,
    generalSkeletonHeight: 0,
    centeredSkeletonHeight: 0,
  },
};

export const CarouselSkeletonFooter: FC<CarouselSkeletonFooterProps> = ({
  size,
  showDots,
  showHeading,
}) => {
  const configuration = getCarouselConfiguration(showDots, showHeading);
  const { numOfComponentsAtBothSides, generalSkeletonHeight, centeredSkeletonHeight } =
    skeletonConfig[configuration];
  return (
    <Box sx={{left: 40, right: 40, bottom: 4 }}>
      {configuration !== 'both' && (
        <Flex>
          <Box w={'30%'}>
            {Array.from(Array(numOfComponentsAtBothSides).keys()).map((item) => (
              <Skeleton
                height={60}
                width={'98%'}
                radius="sm"
                mb={3}
                key={item}
              />
            ))}
          </Box>
          <Box w={'50%'} mx={4}>
            <Skeleton height={60 * 4} width="100%" radius="sm" />
          </Box>
          <Box w={'20%'}>
            {Array.from(Array(numOfComponentsAtBothSides).keys()).map((item) => (
              <Skeleton
                height={60}
                width={'98%'}
                radius="sm"
                mb={3}
                key={item}
              />
            ))}
          </Box>
        </Flex>
      )}
      <AdditionalSection size={size} showDots={showDots} showHeading={showHeading} />
    </Box>
  );
};

const additionalSectionConfig = {
  sm: {
    widgetSize: LayoutSize.sm,
    firstBlockHeight: 40,
  },
  md: {
    widgetSize: LayoutSize.md,
    firstBlockHeight: 20,
  },
  lg: {
    widgetSize: LayoutSize.lg,
    firstBlockHeight: 1,
  },
};
interface AdditionalSectionProps {
  size: LayoutSize;
  showDots: boolean;
  showHeading: boolean;
}
const AdditionalSection = ({ size, showDots, showHeading }: AdditionalSectionProps) => {
  const { widgetSize, firstBlockHeight } = additionalSectionConfig[size];
  const showFirstBlock = widgetSize !== LayoutSize.lg;
  const showSecondBlock =
    widgetSize !== LayoutSize.lg ||
    (widgetSize === LayoutSize.lg && !showHeading) ||
    (widgetSize === LayoutSize.lg && !showDots);
  return (
    <>
      {showFirstBlock && (
        <Flex gap={6} mb={4}>
          <Skeleton height={firstBlockHeight} width={'80%'} radius="sm" />
          <Skeleton height={firstBlockHeight} width={'28%'} radius="sm" />
        </Flex>
      )}
      {showSecondBlock && (
        <Flex gap={4}>
          <Skeleton height={85} width={'33%'} radius="sm" />
          <Skeleton height={85} width={'33%'} radius="sm" />
          <Skeleton height={85} width={'33%'} radius="sm" />
        </Flex>
      )}
    </>
  );
};
