import { Button } from '@mantine/core';

import styles from './CounterAndSelection.module.scss'

import { FC } from 'react'

interface CounterAndSelectionProps {
    allLength: number
    selectedLength: number
    onSelectAll: () => void
    onClearAll: () => void
}

export const CounterAndSelection: FC<CounterAndSelectionProps> = (props) => {
  const {
    allLength,
    selectedLength,
    onSelectAll,
    onClearAll,
  } = props

  const isAllSelected = selectedLength === allLength

  const handleSelectOrClear = () => {
    if (isAllSelected) {
        onClearAll()
    } else {
        onSelectAll()
    }
  }

  return (
    <div className={styles.wrapper}>
        <span className={styles.amountText}>{selectedLength} selected</span>

        <Button variant="default" onClick={handleSelectOrClear}>
          {`${isAllSelected ? 'Clear' : 'Select'} All`}
        </Button>
    </div>
  )
}
