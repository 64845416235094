import { ActionIcon, Box, createStyles, TextInput } from '@mantine/core';
import { IconCheck, IconX } from '@tabler/icons-react';
import classNames from 'classnames';
import { FC, ChangeEvent } from 'react';

const useStyles = createStyles((theme) => ({
  container: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    width: '533px',
    height: '60px',
  },
  textfield: {
    position: 'absolute',
    top: '10px',
    left: 0,
    width: '445px',
    '& .mantine-TextInput-input': {
      border: 'none',
      fontSize: '20px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '24.8px',
      color: theme.colors.whitePrimary,
    },
    '& .mantine-TextInput-error': {
      color: theme.colors.red[0],
      backgroundColor: theme.colors.greyDark[5],
      padding: '4px',
      borderRadius: '4px',
    },
  },
  confirmButton: {
    position: 'absolute',
    top: '10px',
    right: 0,
    backgroundColor: 'rgba(23, 232, 133, 0.20)',
    '&:hover': {
      backgroundColor: 'rgba(23, 232, 133, 0.40)',
    },
    '&[disabled]': {
      pointerEvents: 'none',
    },
  },
  confirmButton__icon: {
    stroke: theme.colors.green[0],
  },
  cancelButton: {
    position: 'absolute',
    top: '10px',
    right: '42px',
    backgroundColor: theme.colors.greyDark[5],
    '&:hover': {
      backgroundColor: theme.colors.greyDark[4],
    },
  },
  cancelButton__icon: {
    width: '20px',
    height: '20px',
  },
  disabledIcon: {
    stroke: theme.colors.greyDark[0],
  },
  rightSection: {
    display: 'flex',
    alignItems: 'center',
    width: '68px',
    height: '36px',
    color: theme.colors.greyDark[0],
    fontSize: '12px',
  },
  rightSection__error: {
    color: theme.colors.red[0],
  },
  nameLength: {
    marginTop: 2,
    verticalAlign: 'center',
  },
}));

interface RenameTextfieldProps {
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onCancel: () => void;
  onConfirm: () => void;
  disableConfirmButton?: boolean;
  error?: string | null;
}
export const RenameTextfield: FC<RenameTextfieldProps> = ({
  value,
  onChange,
  onCancel,
  onConfirm,
  disableConfirmButton,
  error,
}) => {
  const { classes } = useStyles();
  const confirmIconStyles = classNames(classes.confirmButton__icon, {
    [classes.disabledIcon]: Boolean(error),
  });
  const rightSectionClasses = classNames(classes.rightSection, {
    [classes.rightSection__error]: value.length < 1 || value.length > 256,
  });

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!disableConfirmButton) {
      onConfirm();
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box className={classes.container}>
        <TextInput
          value={value}
          onChange={onChange}
          className={classes.textfield}
          classNames={{
            rightSection: rightSectionClasses,
          }}
          error={error}
          rightSection={<div className={classes.nameLength}>{value.length}/256</div>}
        />
        <ActionIcon onClick={onCancel} variant="filled" size={34} className={classes.cancelButton}>
          <IconX className={classes.cancelButton__icon} />
        </ActionIcon>
        <ActionIcon
          onClick={onConfirm}
          variant="filled"
          size={34}
          className={classes.confirmButton}
          disabled={disableConfirmButton}
        >
          <IconCheck className={confirmIconStyles} />
        </ActionIcon>
      </Box>
    </form>
  );
};
