import {useQuery} from "@tanstack/react-query";
import {queryKey} from "../../query/keys";
import {getWidgetVideos} from "../../../api/widget/getWidgetVideos";

export const useGetWidgetVideos = (filter:  {
  collectionId?: string;
  productId?: string;
  widgetId?: string;
}) => {
  return useQuery({
    queryKey: queryKey.widgetVideos(filter),
    queryFn: async () => {
      if (filter.widgetId && (!!filter.collectionId || !!filter.productId)) {
        return getWidgetVideos({
          widgetId: filter.widgetId,
          params: {
            collectionId: filter.collectionId,
            productId: filter.productId
          }
        }).then((res) => res.data)
      }
      return []
    },
  })
}
