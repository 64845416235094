import { useDispatch, useSelector } from 'react-redux';
import {useEffect, useRef, useState} from 'react';
import { userActions } from 'src/components/User/store';
import { selectUser } from 'src/components/User/selectors';
import { useNavigate } from 'react-router-dom';
import {useGetUser} from "./tanstackQuery/useGetUser";

export const useCheckInstagramConnection = (params?: {
  isNavigateToInbox?: boolean;
}) => {
  const { data: user, isLoading } = useGetUser();
  return { instagram_token: user?.instagram_token, loading: isLoading };
};
