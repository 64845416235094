import {useQuery} from "@tanstack/react-query";
import {getAnalytics} from "../../../api/analytics/getAnalytics";
import {queryKey} from "../../query/keys";

export const useGetAnalytics = () => {
  return useQuery({
    queryKey: queryKey.analytics,
    queryFn: () => getAnalytics().then((res) => res.data),
  })
}
