import {MouseEvent, MouseEventHandler, useRef, useState} from "react";
import {useDispatch} from "react-redux";
import {videoListActions} from "../../components/Library/store";
import {useVideoFilesUploader} from "../useVideoFilesUploader";

  export const useUploadVideoFilesDropZone = () => {
  const openRef = useRef<() => void>(null);
  const { videos, setVideos, upload} = useVideoFilesUploader()

  const dispatch = useDispatch();

  const actionHandler = async (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (videos.length > 0) {
      upload()
    } else {
      openRef.current?.();
    }
  }
  const isSelected = videos.length > 0;
  const onDrop = (acceptedFiles: File[]) => {
    setVideos(acceptedFiles);
  }
  return {
    openRef,
    actionHandler,
    isSelected,
    onDrop,
    videos
  }
}
