import * as amplitude from '@amplitude/analytics-browser';
import {getEnv} from "../lib/getEnv";
const amplitudeApiKey = getEnv('REACT_APP_AMPLITUDE_API_KEY')
export const initEventLogger = () =>{
  if(amplitudeApiKey) {
    amplitude.init(amplitudeApiKey);
  }
}

export const setUserIdForEvents = ({userId}:{userId: string}) =>{
  amplitude.setUserId(userId)
}

export const sendPageViewedEvent = (data:{pageName: string}) => {
  amplitude.track('Page viewed', data)
}


export const sendTargetPageTypeSelectedEvent = (data:{pageType: string}) => {
  amplitude.track('Target page type selected', data)
}

// Way to add video selected
export const sendWayToAddVideoSelectedEvent = (data : {
  wayToAdd: string
}) => {
  amplitude.track('Way to add video selected', data)
}

export const sendEventWidgetTargetingSelectedEvent = (data: {
  targetingType: string
}) => {
  amplitude.track('Widget targeting selected', data)
}

export const sendLivePreviewUsed = () => {
  amplitude.track('Live preview used')
}
