import { useState, useEffect, useRef } from 'react';
import { Switch } from '@mantine/core';

import { PropWrapper } from "../PropWrapper";

import { PropWrapperProps } from '../PropWrapper';
import { FC } from 'react';


interface PropWithSwitchAutoScrollProps extends Omit<PropWrapperProps, 'children'> {
  checked?: boolean
  onChange: () => void
  strictScrollingDown?: boolean
}

export const PropWithSwitchAutoScroll:FC<PropWithSwitchAutoScrollProps> = (props) => {
  const { checked, onChange, subModule, strictScrollingDown, ...wrapperProps } = props;

  const [shouldScroll, setShouldScroll] = useState(false)

  const submoduleWrapperRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (shouldScroll && checked && submoduleWrapperRef.current){
      submoduleWrapperRef.current?.scrollIntoView({block: "nearest", inline: "nearest"})

      if (strictScrollingDown){
        document.getElementById('settingsAccordionWrapper')?.scrollIntoView({ block: "end" })
      }

      setShouldScroll(false)
    }
  }, [shouldScroll, checked])

  const handleChange = () => {
    if (!checked){
      setShouldScroll(true)
    }
    onChange()
  }

  return (
    <PropWrapper
      {...wrapperProps}
      subModule={(
        <div ref={submoduleWrapperRef}>{subModule}</div>
      )}
    >
        <Switch
          checked={checked}
          onChange={handleChange}
        />
    </PropWrapper>
  )
}
