import React, {ReactElement, useCallback, useEffect, useState} from "react";
import {FeatureRestrictionsContext, FeatureRestrictionsContextType} from "./featureRestrictionsContext";
import {useMantle} from "@heymantle/react";
import {useGetPublishedWidgetsCount} from "../hooks/useGetPublishedWidgetsCount";
import {notifications} from "@mantine/notifications";
import {Text} from "@mantine/core";
import {Box} from "@mantine/core";
import {Link, useRoutes} from "react-router-dom";
import {GradientButton} from "../../components/GradientButton/GradientButtom";

type FeatureRestrictionProviderProps = {
  children: ReactElement | ReactElement[];

}



const NotificationBodyCanPublishUpToNWidgets = ({publishedWidgetsCount, planName, onLinkClick} : {
  publishedWidgetsCount: number,
  planName: string
  onLinkClick?: () => void
}) => {
  const handleLinkClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if(!onLinkClick){
      return
    }
    if (e.button === 1) {
      setTimeout(()=>{
        onLinkClick()
      }, 2000)
    } else  {
      onLinkClick();
    }
  };

  return (
    <Box>
      <Text>You can publish up to {publishedWidgetsCount} widgets on {planName}.</Text>
      <Text sx={{
        marginBottom: '5px'
      }}>Upgrade your plan now to proceed:</Text>
      <Link onClick={onLinkClick}  onMouseUp={handleLinkClick} to="/pricing-plans">
        <GradientButton>View pricing plans</GradientButton>
      </Link>
    </Box>
  )
}

export const FeatureRestrictionProvider: React.FC<FeatureRestrictionProviderProps> = ({ children }) => {
  const {limitForFeature, customer} = useMantle()
  const {data: currentPublishedWidgets} = useGetPublishedWidgetsCount()
  const [context, setContext] = useState<FeatureRestrictionsContextType>({
    isLoading: true,
  });
  const closeCantPublishMoreWidgetsNotification = useCallback(() => {
    notifications.hide('cant-publish-more-widgets')
  }, [])
  const onCantPublishMoreWidgets = useCallback(() => {
    notifications.show({
      id: 'cant-publish-more-widgets',
      autoClose: false,
      withCloseButton: false,
      message: <NotificationBodyCanPublishUpToNWidgets
        onLinkClick={closeCantPublishMoreWidgetsNotification}
        publishedWidgetsCount={currentPublishedWidgets || 0}
        planName={customer.subscription?.plan.name || ''}
      />,
    })
  }, [currentPublishedWidgets, customer.subscription?.plan.name])

  useEffect(() => {
    try {
      const maxWidgetCount = limitForFeature({
        featureKey: 'published_widgets'
      })
      const currentWidgetCount = currentPublishedWidgets

      if(currentWidgetCount === undefined){
        return
      }
      const isPossibleToPublishWidgets = currentWidgetCount < maxWidgetCount
      const onPublishWidget = async (publishFunction: ()=> void | Promise<void>, newWidgetCount = 1) => {
        if(maxWidgetCount < 0 || maxWidgetCount === undefined){
          publishFunction()
          return;
        }
        const isOverLimit = newWidgetCount + currentWidgetCount > maxWidgetCount
        if(!isPossibleToPublishWidgets || isOverLimit){
          onCantPublishMoreWidgets()
          return
        }
        await publishFunction()
      }

      setContext({
        maxPublishedWidgets: maxWidgetCount,
        isPossibleToPublishWidgets: isPossibleToPublishWidgets,
        isLoading: false,
        onPublishWidget,
        currentPublishedWidgetsCount: currentWidgetCount
      })
    } catch (e) {
      console.log('Warning: ', e)
    }
  }, [limitForFeature, currentPublishedWidgets, onCantPublishMoreWidgets]);



  return (
    <FeatureRestrictionsContext.Provider value={context}>
      {children}
    </FeatureRestrictionsContext.Provider>
  );
}
