import React, {useCallback, useEffect, useState} from 'react';
import {Box, Skeleton} from "@mantine/core";
import styles from './CardPreview.module.scss'
import classNames from "classnames";
import {useDisclosure} from "@mantine/hooks";
export enum BehaviorAutoplay {
  off = 'off',
  enable = 'enable',
  hover = 'hover',
}

export interface CardPreviewProps{
  showContent?: boolean
  previewUrl?: string
  previewUrlWebp?: string
  animationUrl?: string
  animationUrlWebp?: string
  hidePlayButton?: boolean
  playButtonSize?: number
  isImageCanBeLoaded?: boolean
  isAnimatedCanBeLoaded?: boolean
  onImageLoaded?: () => void
  onAnimatedLoaded?: () => void
  autoplay?: BehaviorAutoplay;
}
export const CardPreview: React.FC<CardPreviewProps> = ({
                                                          showContent,
                                                          previewUrl,
                                                          previewUrlWebp,
                                                          animationUrl,
                                                          animationUrlWebp,
                                                          hidePlayButton,
                                                          playButtonSize,
                                                          isAnimatedCanBeLoaded,
                                                          isImageCanBeLoaded,
                                                          onImageLoaded: externalOnImageLoaded,
                                                          onAnimatedLoaded: externalOnAnimatedLoaded,
  autoplay
                                                        }) => {

  const [isHovered, setIsHovered] = useState(false)
  const [isImageLoaded, setIsImageLoaded] = useState(false)
  const [isImageLoading, setIsImageLoading] = useDisclosure()
  const [isAnimatedLoaded, setIsAnimatedLoaded] = useState(false)

  const onImageLoaded = useCallback(() => {
    setIsImageLoaded(true)
    setIsImageLoading.close()
    externalOnImageLoaded?.()
  }, [externalOnImageLoaded])



  const onAnimatedLoaded = useCallback(() => {
    setIsAnimatedLoaded(true)
    externalOnAnimatedLoaded?.()
  }, [externalOnAnimatedLoaded])


  const onMouseEnter = () => {
    setIsHovered(true)
  }
  const onMouseLeave = () => {
    setIsHovered(false)
  }

  const isNeedToShowAnimated = autoplay === BehaviorAutoplay.enable || autoplay === BehaviorAutoplay.hover && isHovered
  const isShowAnimated = isNeedToShowAnimated && isAnimatedLoaded

  return (
    <Box id={`vp-root-${previewUrl}-${isImageCanBeLoaded}-${isImageLoaded}`} className={styles.root}  onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      {(isImageLoading || !showContent) && <Skeleton
        id="Skeleton-id"
        className={classNames(
          styles.preview,
          isImageLoading && styles.preview_skeleton
        )}
      />}

        <>
          <Box
            id={`vp-image-wrapper-${previewUrl}`}
            className={classNames(
              styles.preview,
            )}
            style={{
              display: !isShowAnimated ? 'block' : 'none',
            }}>
            <picture  id={`vp-picture-${previewUrl}`} onLoad={onImageLoaded} onLoadCapture={setIsImageLoading.open}>
              <source
                srcSet={previewUrlWebp}
                className={classNames(
                  styles.preview,
                )}
                type="image/webp"
              />

              <img
                src={previewUrl}
                className={classNames(
                  styles.preview,
                )}
                alt={previewUrl}
                fetchPriority="high"
              />
            </picture>
          </Box>
          {(isAnimatedCanBeLoaded || isAnimatedLoaded) && <Box
            className={classNames(
              styles.preview,
              !isAnimatedLoaded && styles.hidden
            )}
            style={{
              display: isShowAnimated ? 'block' : 'none',
            }}>
            <picture onLoad={onAnimatedLoaded}>
              <source
                srcSet={animationUrlWebp}
                className={classNames(
                  styles.preview,
                  !isAnimatedLoaded && styles.hidden
                )}
                type="image/webp"
              />

              <img
                src={animationUrl}
                className={classNames(
                  styles.preview,
                  !isAnimatedLoaded && styles.hidden
                )}
                alt={animationUrl}
              />
            </picture>
          </Box>}
        </>
    </Box>
  );
};

