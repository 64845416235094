import { Group, Box, ThemeIcon, UnstyledButton, createStyles, Tooltip } from '@mantine/core';
import classNames from 'classnames';
import { FC } from 'react';
import { Link, To, useLocation } from 'react-router-dom';

const useStyles = createStyles((theme) => ({
  control: {
    fontWeight: 500,
    display: 'block',
    width: '100%',
    whiteSpace: 'normal',
    overflow: 'hidden',
    padding: `${theme.spacing.xs} ${theme.spacing.md}`,
    color: theme.colors.greyLight[3],
    fontSize: theme.fontSizes.sm,
    borderRadius: '4px',
    '&:hover, &:focus': {
      backgroundColor: theme.colors.greyDark[1],
    },
  },
  activeLink: {
    backgroundColor: 'rgba(23, 232, 133, 0.20)',
    color: theme.colors.green[0],
    '&:hover, &:focus': {
      backgroundColor: 'rgba(23, 232, 133, 0.20)',
      color: theme.colors.green[0],
    },
  },
}));

interface LinksGroupProps {
  // todo remove any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icon: React.FC<any>;
  label: string;
  isCollapsed: boolean;
  to?: To;
  baseRoute?: string;
  openInNewTab?: boolean;
  onClick?: () => void;
}

export const LinksGroup: FC<LinksGroupProps> = ({
  icon: Icon,
  label,
  isCollapsed,
  to = '',
  baseRoute,
  openInNewTab,
  onClick
}) => {
  const { classes } = useStyles();
  const { pathname } = useLocation();
  const linkStyles = classNames(classes.control, {
    [classes.activeLink]: baseRoute && pathname.startsWith(baseRoute),
  });

  const handleLinkClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    if (onClick){
      event.preventDefault();
      onClick();
    }
  }

  return (
    <Tooltip
      label={label}
      withArrow
      position="right"
      arrowPosition="center"
      disabled={!isCollapsed}
    >
      <Link to={to} target={openInNewTab ? '_blank' : undefined} onClick={handleLinkClick}>
        <UnstyledButton className={linkStyles}>
          <Group position="apart" spacing={0}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <ThemeIcon variant="dark" size={30}>
                <Icon size="1.2rem" />
              </ThemeIcon>
              <Box ml="md">{label}</Box>
            </Box>
          </Group>
        </UnstyledButton>
      </Link>
    </Tooltip>
  );
};
