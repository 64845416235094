import { Box, createStyles, Slider } from '@mantine/core';
import { FC, useMemo } from 'react';
import cn from 'classnames';

import { formatVideoTime } from 'src/shared/lib/formatVideoTime';

import styles from './TimeManager.module.scss'

const useStyles = createStyles((theme) => ({
  root: {
    position: 'absolute',
    bottom: 16,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '0 12px',
    span: {
      color: '#FFF',
      textAlign: 'center',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '150%',
    },
  },
  displayTime: {
    display: 'flex',
    justifyContent: 'space-between',
    color: theme.colors.whitePrimary,
  },
  timeline: {
    width: '100%',
    '&:hover': {
      '.mantine-Slider-thumb': {
        display: 'block',
      },
    },
  },
  timelineThumb: {
    width: '18px',
    height: '18px',
    display: 'none',
  },
  timelineFilled: {
    backgroundColor: theme.colors.whitePrimary,
  },
  timelineLabel: {
    backgroundColor: 'transparent',
    color: '#FFF',
    textAlign: 'center',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '150%',
  },
}));

interface TimeManagerProps {
  elapsed: number;
  duration: number;
  onTimeChange: (value: number) => void;
}
export const TimeManager: FC<TimeManagerProps> = ({ elapsed, duration, onTimeChange }) => {
  const { classes } = useStyles();
  const elapsedTime = useMemo(() => formatVideoTime(elapsed), [elapsed]);
  const durationtime = useMemo(() => formatVideoTime(duration), [duration]);
  return (
    <Box className={cn(classes.root, styles.wrapper)}>
      <Slider
        min={0}
        max={duration}
        value={elapsed}
        label={(value) => formatVideoTime(value)}
        onChange={onTimeChange}
        step={0.000001}
        className={classes.timeline}
        classNames={{
          bar: classes.timelineFilled,
          thumb: classes.timelineThumb,
          label: classes.timelineLabel,
        }}
      />

      <Box className={classes.displayTime}>
        <Box component="span">{elapsedTime}</Box>
        <Box component="span">{durationtime}</Box>
      </Box>
    </Box>
  );
};
