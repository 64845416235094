import { AxiosRequestConfig } from 'axios';
import { apiClient } from '../../client';

interface Config extends AxiosRequestConfig {
  id: string;
  toAdd: string[];
  toDelete: string[];
}

export const patchProductsToVideo = ({ id, toDelete, toAdd }: Config) =>
  apiClient.put(`/bff/video/${id}/products`, { toDelete, toAdd });
