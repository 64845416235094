import { Portal, Button } from '@mantine/core';
import styles from './SelectedVideosListManager.module.scss';
import { ReactComponent as IconDelete } from 'src/shared/icons/delete.svg';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { useDisclosure } from '@mantine/hooks';
import { useMemo, FC } from 'react';
import { ConfirmDeletionModal } from 'src/components/Library/ConfirmDeletionModal';
import { widgetsActions } from 'src/components/Widget/store';
import { getPluralWord } from 'src/shared/lib/getPluralWord';

interface SelectedVideosListManagerProps {
  selectedVideos: string[];
  onCancel: () => void;
}

export const SelectedVideosListManager:FC<SelectedVideosListManagerProps> = (props) => {
  const { selectedVideos, onCancel } = props;

  const dispatch = useDispatch();
  const [confirmationModalState, setConfirmationModalState] = useDisclosure(false);

  const deleteHandler = () => {
    dispatch(widgetsActions.deleteVideosFromWidget([...selectedVideos]));
    onCancel();
  };

  const cancelBtnClasses = classNames(styles.btn, styles.btn__cancel);
  const deleteBtnClasses = classNames(styles.btn, styles.btn__delete);
  const deleteIconClasses = classNames(styles.icon, styles.icon__delete);
  const modalBodyText = useMemo(
    () =>
      `Are you sure you want to delete ${selectedVideos.length} ${getPluralWord(
        'video',
        selectedVideos.length
      )}?`,
    [selectedVideos.length]
  );
  return (
    <Portal target="#single-widget-header">
      <div className={styles.container}>
        <div className={styles.inner}>
          <span className={styles.amountText}>{selectedVideos.length} selected</span>
          <div className={styles.btn__container}>
            <Button className={deleteBtnClasses} onClick={setConfirmationModalState.open}>
              <IconDelete className={deleteIconClasses} />
            </Button>
            <Button className={cancelBtnClasses} onClick={onCancel}>
              Cancel
            </Button>
          </div>
        </div>
      </div>
      <ConfirmDeletionModal
        open={confirmationModalState}
        onClose={setConfirmationModalState.close}
        onConfirm={deleteHandler}
        onCancel={setConfirmationModalState.close}
        bodyText={modalBodyText}
        title="Remove videos"
      />
    </Portal>
  );
};
