import { Box, Flex, Skeleton } from '@mantine/core';
import { FC } from 'react';
import { getStoriesConfiguration } from '../../config/helperFunctions';
import { StoriesSkeletonFooterProps } from '../../config/types';

const skeletonConfig = {
  default: {
    sm: 68,
    md: 62,
    lg: 42,
  },
  headingShown: {
    sm: 32,
    md: 26,
    lg: 8,
  },
};

export const StoriesSkeletonFooter: FC<StoriesSkeletonFooterProps> = ({ size, showHeading }) => {
  const configuration = getStoriesConfiguration(showHeading);
  const blockHeight = skeletonConfig[configuration][size];
  return (
    <Box sx={{ left: 40, right: 40, bottom: 4 }}>
      <Flex mb={3} gap={6}>
        <Skeleton height={80} width={'80%'} radius="md" />
        <Box w={'20%'}>
          <Skeleton height={30} width={'100%'} radius="md" mb={3} />
          <Skeleton height={30} width={'100%'} radius="md" mb={3} />
          <Skeleton height={30} width={'100%'} radius="md" />
        </Box>
      </Flex>
      <Flex gap={6} mb={6}>
        <Skeleton height={129} width={'60%'} radius="md" />
        <Skeleton height={129} width={'20%'} radius="md" />
        <Skeleton height={129} width={'20%'} radius="md" />
      </Flex>
      <Flex gap={4} mb={6}>
        <Box w="50%">
          <Skeleton width="100%" height={32} mb={4} />
          <Skeleton width="100%" height={32} mb={4} />
          <Skeleton width="100%" height={32} mb={4} />
          <Skeleton width="100%" height={32} mb={4} />
        </Box>
        <Box w="50%">
          <Skeleton width="100%" height={32} mb={4} />
          <Skeleton width="100%" height={32} mb={4} />
          <Skeleton width="100%" height={32} mb={4} />
          <Skeleton width="100%" height={32} mb={4} />
        </Box>
      </Flex>
    </Box>
  );
};
