import {
  Box,
  Button,
  CopyButton,
  createStyles,
  Menu,
  Text,
  Progress,
  Group,
  ActionIcon,
  List,
} from '@mantine/core';
import classNames from 'classnames';
import { FC, useEffect } from 'react';
import { checkInstallation } from 'src/api/widget/checkInstallation';
import { GradientButton } from 'src/components/GradientButton/GradientButtom';
import { ExternalLink, MessageChatbot } from 'tabler-icons-react';
import { checkAppBlock } from 'src/api/widget/checkAppBlock';
import { useSelector } from 'react-redux';
import { selectActiveWidget } from '../../../selectors';
import { usePublishWidget } from 'src/shared/hooks/widgetInstallationTab/usePublishWidget';
import { IconChevronDown } from '@tabler/icons-react';
import { useActiveWidget } from 'src/shared/hooks/widgetInstallationTab/useActivateWidget';
import { WidgetStatus } from 'src/components/Widget/types';
import { checkAndRefetchOnboardingData } from 'src/api/onboarding/checkAndRefetchOnboardingData';
import { sendLivePreviewUsed } from "../../../../../shared/eventLogger/eventLogger";

const useStyles = createStyles((theme) => ({
  descriptionText: {
    color: theme.colors.greyLight[5],
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineheight: '14px',
    marginBottom: '12px',
  },
  textTitle: {
    color: theme.colors.greyLight[2],
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '16px',
  },
  textSuccess: {
    color: theme.colors.green[1],
  },
  textError: {
    color: theme.colors.red[0],
  },
  textInfo: {
    color: theme.colors.whitePrimary,
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '14px',
  },
  nextStepButton: {
    borderColor: theme.colors.green[0],
    color: theme.colors.green[0],
  },
  widgetIdWrapper: {
    display: 'flex',
    padding: '10px 0px 10px 10px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '17.78px',
    borderRadius: '4px',
    background: theme.colors.greyDark[4],
    userSelect: 'text',
    '&::selection': {
      backgroundColor: theme.colors.green[0],
    },
  },
  pagesMenu__item: {
    display: 'flex',
    alignItems: 'center',
  },
  publishBUtton__progress: {
    position: 'absolute',
    top: 'rem(-1px)',
    left: 'rem(-1px)',
    right: 'rem(-1px)',
    bottom: 'rem(-1px)',
    height: 'auto',
    backgroundColor: 'transparent',
    zIndex: 0,
  },
  listItem: {
    '& .mantine-List-itemWrapper': {
      width: 'calc(100% - 18px)',
      paddingRight: '4px',
      fontSize: '14px',
      color: theme.colors.greyLight[5],
    },
  },
}));
const useStepOneStyles = createStyles(() => ({
  buttonsContainer: {
    display: 'flex',
    alignItems: 'center',
  },
}));
interface StepOneProps {
  nextStep: () => void;
}
export type CheckInstallationResponse = {
  status: 'OK' | 'BlockUninstalled' | 'BlockDisabled';
  activation_url: string;
};
export const StepOne: FC<StepOneProps> = ({ nextStep }) => {
  const { classes } = useStyles();
  const { classes: stepOneClasses } = useStepOneStyles();
  const { error, activateWidget, nextStepHandler } = useActiveWidget(checkInstallation, nextStep);
  return (
    <Box pt={6}>
      <Text className={classes.textTitle} mb={15}>
        Activate VideoPoint
      </Text>
      <List mb={12}>
        <List.Item className={classes.listItem}>
          <Text component="span" display="block" mb={4}>
            Click the{' '}
            <Text component="span" fw={900}>
              Activate VideoPoint
            </Text>{' '}
            button, it will open a new tab
          </Text>
          <Button color="green.1" mr={12} onClick={activateWidget}>
            Activate VideoPoint
          </Button>
        </List.Item>
        <List.Item className={classes.listItem}>
          <Text component="span" display="block" mb={4}>
            Click the{' '}
            <Text component="span" fw={900}>
              Save
            </Text>{' '}
            button in the newly opened tab
          </Text>
          <Button variant="default" className={classes.nextStepButton} onClick={nextStepHandler}>
            Next step
          </Button>
          {error && <Text color="red.0">Please activate VideoPoint using the button above.</Text>}
        </List.Item>
      </List>
    </Box>
  );
};

interface StepTwoProps {
  nextStep: () => void;
  widgetId: string;
}
export const StepTwo: FC<StepTwoProps> = ({ nextStep, widgetId }) => {
  const { classes } = useStyles();
  const { classes: stepOneClasses } = useStepOneStyles();
  const { error, activateWidget, nextStepHandler } = useActiveWidget(
    () => checkAppBlock(widgetId),
    nextStep
  );

  return (
    <Box pt={6}>
      <Text className={classes.textTitle} mb={15}>
        Add widget block
      </Text>
      <Text color="greyLight.5" mb={12}>
        Copy the widget ID:
      </Text>
      <Box className={classes.widgetIdWrapper} mb={12}>
        {widgetId}
      </Box>
      <CopyButton value={widgetId}>
        {({ copied, copy }) => (
          <Button onClick={copy} color="green.1" mb={12}>
            {copied ? 'Widget ID copied' : 'Copy widget ID'}
          </Button>
        )}
      </CopyButton>

      <List color="greyLight.5" mb={12}>
        <List.Item className={classes.listItem}>
          <Text component="span" display="block" mb={4}>
            Click{' '}
            <Text component="span" fw={900}>
              Add widget block
            </Text>{' '}
            button, it will open a new tab and add the Apps section with the widget block to the
            target page template
          </Text>
          <Button
            color="green.1"
            mr={12}
            mb={4}
            leftIcon={<ExternalLink size={15} />}
            onClick={activateWidget}
          >
            Add widget block
          </Button>
        </List.Item>
        <List.Item className={classes.listItem}>
          Unfold the Apps section, click the Widget block, and paste the copied ID into the ID field
          of the block
        </List.Item>
        <List.Item className={classes.listItem}>
          Position the block where you want the widget to appear
        </List.Item>
        <List.Item className={classes.listItem}>
          Click{' '}
          <Text component="span" fw={900}>
            Save
          </Text>
        </List.Item>
      </List>
      <Button variant="default" className={classes.nextStepButton} onClick={nextStepHandler}>
        Next step
      </Button>
      {error && (
        <Text color="red.0" mb={12}>
          Please copy the widget ID and add the VideoPoint block using the button above.
        </Text>
      )}
    </Box>
  );
};

interface StepThreeProps {
  checkInstallation: () => void;
  checked?: boolean;
}
export const StepThree: FC<StepThreeProps> = ({ checkInstallation, checked }) => {
  const { classes } = useStyles();
  const { classes: stepOneClasses } = useStepOneStyles();

  useEffect(() => {
    if (checked) {
      checkInstallation();

      //ONBOARDING checking 2 installed widgets
      checkAndRefetchOnboardingData()
    }
  }, []);

  return (
    <Box pt={6}>
      <Text className={classes.textTitle} mb={15}>
        Check widget installation
      </Text>
      <Text className={classes.descriptionText} mb={12}>
        Click the button below to check if the installation was successful
      </Text>
      <Box className={stepOneClasses.buttonsContainer}>
        <Button
          color="green.1"
          mr={12}
          onClick={checkInstallation}
        >
          Check widget installation
        </Button>
      </Box>
    </Box>
  );
};
interface StepThreeErrorProps {
  prevStep: () => void;
  contact: () => void;
}
export const StepThreeError: FC<StepThreeErrorProps> = ({ prevStep, contact }) => {
  const { classes } = useStyles();
  const errorTextClasses = classNames(classes.descriptionText, { [classes.textError]: true });
  return (
    <Box>
      <Text className={errorTextClasses} mb={0}>
        The widget was not installed
      </Text>
      <Text className={classes.descriptionText} mb={12}>
        Please check if you have saved the edits made in the previous steps.
      </Text>
      <Button color="green.1" onClick={prevStep} mb={14}>
        Previous step
      </Button>
      <Text className={classes.textInfo} mb={12}>
        Let us figure this out for you
      </Text>
      <GradientButton onClick={contact}>
        <MessageChatbot />
        <Text component="span">Contact support</Text>
      </GradientButton>
    </Box>
  );
};

interface StepThreeSuccessProps {
  preview_url: string;
  themeName: string;
  template: string;
  widgetId: string;
}
export const StepThreeSuccess: FC<StepThreeSuccessProps> = ({
  preview_url,
  themeName,
  template,
}) => {
  const { classes } = useStyles();
  const activeWidget = useSelector(selectActiveWidget);
  const successTextClasses = classNames(classes.descriptionText, { [classes.textSuccess]: true });
  const { publishingHandler, progress } = usePublishWidget();

  const previewHandler = () => {
    sendLivePreviewUsed()
    window.open(preview_url);
  };


  return (
    <Box>
      <Text className={successTextClasses}>
        The widget has been successfully installed into "{themeName}" theme, "{template}" template.
      </Text>
      <Text className={classes.descriptionText} mb={12}>
        Preview the widget on-site before publishing.
      </Text>
      <Button
        variant="default"
        className={classes.nextStepButton}
        leftIcon={<ExternalLink size={15} />}
        mb={12}
        onClick={previewHandler}
      >
        Live Preview
      </Button>
      <Text className={classes.descriptionText}>
        Publish the widget to make it visible to your site visitors.
      </Text>
      {activeWidget && (
      <PublishButton handler={(unpublish) => publishingHandler(activeWidget?.id, unpublish)} progress={progress} />
      )}
    </Box>
  );
};

interface PublishButtonProps {
  handler: (unpublish?: boolean) => void;
  progress: number;
}
export const PublishButton: FC<PublishButtonProps> = ({ progress, handler }) => {
  const { classes } = useStyles();
  const activeWidget = useSelector(selectActiveWidget);
  return (
    <>
      {activeWidget?.status !== WidgetStatus.published && (
        <GradientButton onClick={() => handler()}>
          <span>{progress !== 0 ? 'Publishing' : 'Publish'}</span>
          {progress !== 0 && (
            <Progress
              value={progress}
              className={classes.publishBUtton__progress}
              color="#00ad6035"
              radius="sm"
            />
          )}
        </GradientButton>
      )}
      {activeWidget?.status === WidgetStatus.published && (
        <Group spacing={1}>
          <Button
            variant="light"
            sx={{
              borderRadius: '4px 0px 0px 4px',
              pointerEvents: 'none',
              backgroundColor: 'rgba(52, 58, 64, 0.2)',
            }}
            color="greyLight.1"
          >
            Published
          </Button>
          <Menu transitionProps={{ transition: 'pop' }} position="bottom-end" withinPortal>
            <Menu.Target>
              <ActionIcon size={36} variant="light" sx={{ borderRadius: '0px 4px 4px 0px' }}>
                <IconChevronDown stroke={1.5} />
              </ActionIcon>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Item onClick={() => handler(true)}>Unpublish</Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Group>
      )}
    </>
  );
};
