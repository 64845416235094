import { useDispatch, useSelector } from 'react-redux';
import { SegmentSwitch } from 'src/components/SegmentSwitch/SegmentSwitch';
import { selectWidgetSettings } from 'src/components/Widget/selectors';
import { BehaviorKey, BehaviorValue, widgetsActions } from 'src/components/Widget/store';
import { BehaviorAnimation, BehaviorAutoplay, WidgetViewType } from 'src/components/Widget/types';
import { PropWrapper } from '../PropWrapper';

const autoplayData = [
  {
    value: BehaviorAutoplay.off,
    label: 'Off',
  },
  {
    value: BehaviorAutoplay.enable,
    label: 'Enable',
  },
  {
    value: BehaviorAutoplay.hover,
    label: 'On hover',
  },
];

const animationData = [
  {
    value: BehaviorAnimation.off,
    label: 'Off',
  },
  {
    value: BehaviorAnimation.pulse,
    label: 'Pulse',
  },
  {
    value: BehaviorAnimation.shine,
    label: 'Shine',
  },
];

export const BehaviorSettings = () => {
  const dispatch = useDispatch();
  const { behavior, general } = useSelector(selectWidgetSettings) ?? {};

  const handler = (key: BehaviorKey, value: BehaviorValue) => {
    dispatch(widgetsActions.setBehavior({ key, value }));
  };
  return (
    <>
      {general?.widgetViewType === WidgetViewType.carousel && (
        <PropWrapper propName="Play button animation" alignContentLeft>
          <SegmentSwitch
            value={behavior?.animation ?? BehaviorAnimation.off}
            onChange={(val) => handler('animation', val)}
            data={animationData}
          />
        </PropWrapper>
      )}
      <PropWrapper propName="Video autoplay" alignContentLeft>
        <SegmentSwitch
          value={behavior?.autoplay ?? BehaviorAutoplay.off}
          onChange={(val) => handler('autoplay', val)}
          data={autoplayData}
        />
      </PropWrapper>
    </>
  );
};
