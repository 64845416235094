import {createContext} from "react";

export type FeatureRestrictionsContextType = {
  isLoading: true;
} | {
  isLoading: false;
  maxPublishedWidgets: number;
  currentPublishedWidgetsCount: number;
  isPossibleToPublishWidgets: boolean;
  onPublishWidget: (fun: () => void | Promise<void>, count?: number) => void
}

export const FeatureRestrictionsContext = createContext<FeatureRestrictionsContextType>({
  isLoading: true,
});
