import {QueryVideoFilter} from "../../api/video/getVideos";
import {queryClient} from "../../api/client";
import {queryKey} from "./keys";

export const invalidateQueryVideos = ({ filters }: {
  filters: QueryVideoFilter[];
}) => {
 const queryKeys = filters.map(filter=> queryKey.videos({filter}))
  queryKeys.push(queryKey.videosCountPerFilter)
  for (const formattedKey of queryKeys) {
    queryClient.invalidateQueries({
      queryKey: formattedKey
    })
  }
}
