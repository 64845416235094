import { Box, createStyles } from '@mantine/core';
import { FC, ReactNode } from 'react';

interface WidgetsListProps {
  children: ReactNode;
}

export const useStyles = createStyles(() => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '46px 52px',
  },
}));

export const WidgetList: FC<WidgetsListProps> = ({ children }) => {
  const { classes } = useStyles();
  return <Box className={classes.container}>{children}</Box>;
};
