import {useMutation} from "@tanstack/react-query";
import {postWidget} from "../../../api/widget/postWidget";
import {CreateAutomatedWidget, CreateManualWidget} from "../../../components/Widget/types";
import {useGetWidgetsManger} from "./useGetWidgetsManger";

interface UsePostWidgetProps {
  onSuccess?: (data: {
    id: string;
  }) => void;
}

export const usePostWidget = (props: UsePostWidgetProps) => {
  const {refetch} = useGetWidgetsManger()
  return useMutation({
    mutationFn: async (data: CreateAutomatedWidget | CreateManualWidget) => {
      return postWidget(data).then((res) => res.data);
    },
    onSuccess: (data,) => {
      refetch();
      props?.onSuccess?.(data);
    }
  })
}
