import {useMutation} from "@tanstack/react-query";
import {deleteWidget} from "../../../api/widget/deleteWidget";
import {useGetWidgetsManger} from "./useGetWidgetsManger";

interface UseDeleteWidgetProps {
  onSuccess?: () => void;
}

export const useDeleteWidget = (props?: UseDeleteWidgetProps) => {
  const {refetch} = useGetWidgetsManger();
  const onSuccess = () => {
    refetch();
    props?.onSuccess?.();
  }

  return useMutation<void, unknown, { id: string | string[] }>({
    mutationFn: async (data) => {
      if (Array.isArray(data.id)) {
        await Promise.all(data.id.map((id) => deleteWidget({id})));
      } else {
        await deleteWidget({id: data.id});
      }
    },
    onSuccess: onSuccess,
  })
}
