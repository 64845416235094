import {useDeleteVideos} from "./useDeleteVideos";
import {useCallback} from "react";
import {queryClient} from "../../api/client";
import {queryKey} from "../query/keys";
import {videoFilterToVideoQueryFilter} from "../helpers/videoFilterToVideoQueryFilter";
import {VideoFilter} from "src/components/Library/LibraryLayout/LibraryLayout";
import {useDispatch} from "react-redux";
import {videoListActions} from "../../components/Library/store";

export const useLibraryDeleteVideos = (props: {
  currentFilter: VideoFilter;
  onSuccess?: () => void;
}) => {

  const dispatch = useDispatch()

  const onDelete = ({ids}:{ids: string[]})=>{
    dispatch(videoListActions.markVideosAsDeleted(ids))
  }

  const onSuccessVideoDeletion = useCallback(() => {
    const timer = setTimeout(() => {
      queryClient.invalidateQueries({
        queryKey: queryKey.videosCountPerFilter,
      })
      clearTimeout(timer)
    },100)
    const queryFilter = videoFilterToVideoQueryFilter(props.currentFilter)
    queryClient.invalidateQueries({
      queryKey: queryKey.videos({filter: queryFilter}),
    })

    props.onSuccess?.()

  }, [props])

  return useDeleteVideos({
    onSuccess: onSuccessVideoDeletion,
    onMutate: onDelete,
  })
}
