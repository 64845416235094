import React from 'react';
import classes from './VideoFileUploader.module.scss';
import {Dropzone, MIME_TYPES} from "@mantine/dropzone";
import {Box, Button, Text} from "@mantine/core";
import {useUploadVideoFilesDropZone} from "../../../shared/hooks/useUploadVideoFilesDropZone";

export const VideoFileUploader = () => {
  const {openRef, onDrop, actionHandler, isSelected, videos} = useUploadVideoFilesDropZone();

  return (
    <Dropzone
      openRef={openRef}
      onDrop={onDrop}
      className={classes.dropzone}
      radius="md"
      accept={[MIME_TYPES.mp4, 'video/avi','video/quicktime']}
      maxSize={300 * 1024 ** 2}
    >
      <div style={{pointerEvents: 'none'}}>
        {videos.length > 0 && <Text className={classes.uploadVideos}>
          <Dropzone.Idle>videos</Dropzone.Idle>
        </Text>}
        {isSelected ?
          videos.map((file, index) => {
            const number = index + 1;
            return (
              <Text key={file.name}>
                {number}: {file.name}
              </Text>
            )
          })
          : (
            <Box className={classes.noVideoContainer}>
              <Text
                className={classes.noVideoText}
              >
                No videos in the Upload
              </Text>
              <Text
                className={classes.noVideoText}
              >
                Drag&apos;n&apos;drop files into the area bellow to upload your videos
              </Text>
            </Box>
          )
        }
        {videos.length > 0 && (<Box className={classes.uploadButtonContainer}>
          <Button className={classes.uploadButton} size="md" radius="xl" onClick={actionHandler}>
            upload
          </Button>
        </Box>)}
      </div>
    </Dropzone>
  );
};

