import { Title, Text, Button, Container, Group } from '@mantine/core';

import styles from './ErrorNotFound.module.scss';

import { FC } from 'react';

export const ErrorNotFound:FC = () => {
  return (
    <Container className={styles.root}>
      <div className={styles.label}>404</div>
      <Title className={styles.title}>You have found a secret place.</Title>
      <Text c="dimmed" size="lg" ta="center" className={styles.description}>
        Unfortunately, this is only a 404 page. You may have mistyped the address, or the page has
        been moved to another URL.
      </Text>
      <Group className={styles.bottomGroup}>
        <Button variant="subtle" size="md" onClick={() => document.location.href = '/'}>
          Take me back to home page
        </Button>
      </Group>
    </Container>
  );
}
