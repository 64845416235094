import { useSelector } from 'react-redux';
import { DefaultHeader } from './DefaultHeader';
import { SelectedVideosManager } from './SelectedVideosManager';
import { selectCheckedVideos } from '../selectors';

import type { FC } from 'react';
import { VideoResponseDeprecated } from './types';
import {VideoFilter} from "../LibraryLayout/LibraryLayout";
import {FetchVideosResponse} from "../../../api/video/getVideos";

interface LibraryHeaderProps {
  videosByFilter: FetchVideosResponse;
  currentFilter: VideoFilter;
}

export const LibraryHeader:FC<LibraryHeaderProps> = (props) => {
  const checkedVideos = useSelector(selectCheckedVideos);
  return checkedVideos.length ? <SelectedVideosManager {...props} /> : <DefaultHeader />;
};
