import { Button } from '@mantine/core';
import styles from './SelectedVideosManager.module.scss';
// import { ReactComponent as IconDuplicate } from '../../../shared/icons/duplicate.svg';
import { ReactComponent as IconDelete } from '../../../shared/icons/delete.svg';
import cn from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { selectCheckedVideos } from '../selectors';
import { videoListActions } from '../store';
import { ConfirmDeletionModal } from '../ConfirmDeletionModal';
import { useDisclosure } from '@mantine/hooks';
import { useMemo } from 'react';
import { CounterAndSelection } from 'src/shared/ui/CounterAndSelection/CounterAndSelection';

import type { FC } from 'react';
import { VideoResponseDeprecated } from './types';
import {useDeleteVideos} from "../../../shared/hooks/useDeleteVideos";
import {queryClient} from "../../../api/client";
import {queryKey} from "../../../shared/query/keys";
import {videoFilterToVideoQueryFilter} from "../../../shared/helpers/videoFilterToVideoQueryFilter";
import {VideoFilter} from "../LibraryLayout/LibraryLayout";
import {useLibraryDeleteVideos} from "../../../shared/hooks/useLibraryDeleteVideos";
import {FetchVideosResponse} from "../../../api/video/getVideos";

interface SelectedVideosManagerProps {
  videosByFilter: FetchVideosResponse;
  currentFilter: VideoFilter;
}

export const SelectedVideosManager: FC<SelectedVideosManagerProps> = (props) => {
  const { videosByFilter, currentFilter } = props;

  const dispatch = useDispatch();
  const selectedVideos = useSelector(selectCheckedVideos);
  const [confirmationModalState, setConfirmationModalState] = useDisclosure(false);

  const onSuccessVideoDelete = () => {
    setConfirmationModalState.close();
  }

  const {mutate: deleteVideos} = useLibraryDeleteVideos({
    onSuccess: onSuccessVideoDelete,
    currentFilter
  })
  const deleteHandler = () => {
    deleteVideos({
      ids: selectedVideos,
    });
    dispatch(videoListActions.uncheckAll());
  };
  const cancelHandler = () => {
    dispatch(videoListActions.uncheckAll());
  };

  const cancelBtnClasses = cn(styles.btn, styles.btn__cancel);
  const deleteBtnClasses = cn(styles.btn, styles.btn__delete);
  const deleteIconClasses = cn(styles.icon, styles.icon__delete);
  const modalBodyText = useMemo(
    () =>
      `Are you sure you want to delete ${selectedVideos.length} ${
        selectedVideos.length > 1 ? 'videos' : 'video'
      } from the Library?`,
    [selectedVideos.length]
  );

  const handleSelectAll = () => {
    const videosIds = videosByFilter.map((video) => video.id);
    dispatch(videoListActions.selectVideos(videosIds));
  }

  return (
    <>
      <div className={styles.container}>
        <CounterAndSelection
          allLength={videosByFilter.length}
          selectedLength={selectedVideos.length}
          onSelectAll={handleSelectAll}
          onClearAll={() => dispatch(videoListActions.uncheckAll())}
        />

        <div className={styles.btn__container}>
          {/* <Button className={styles.btn}>
            <IconDuplicate className={styles.icon} />
          </Button> */}
          <Button className={deleteBtnClasses} onClick={setConfirmationModalState.open}>
            <IconDelete className={deleteIconClasses} />
          </Button>
          <Button className={cancelBtnClasses} onClick={cancelHandler}>
            Cancel
          </Button>
        </div>
      </div>
      <ConfirmDeletionModal
        open={confirmationModalState}
        onClose={setConfirmationModalState.close}
        onConfirm={deleteHandler}
        onCancel={setConfirmationModalState.close}
        bodyText={modalBodyText}
        title="Delete video"
      />
    </>
  );
};
