import { useState} from 'react'

import { Box, createStyles, Flex } from '@mantine/core';
import { ViewManager } from './Layout.components';
import { useSelector } from 'react-redux';
import { selectActiveWidget } from 'src/components/Widget/selectors';
import { MobileView } from './MobileView';
import { DesktopView } from './DesktopView';
import { Widget } from '@chatpoint/widget-lib'
import { ShopifyProduct } from '@chatpoint/widget-lib/dist/types';

//TODO-MIGRATION: Widget type from lib should be used on the platform side as well

import { Widget as WidgetTypeFromLib } from '@chatpoint/widget-lib/dist/types';

import '@chatpoint/widget-lib/dist/index.css';
import {useWidgetPreviewDataRetrieval} from "../../../../../../../shared/hooks/useWidgetPreviewDataRetrieval";

const useStyles = createStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    height: 'max-content',
    width: '100%',
  },
}));

export const LayoutPreview = () => {
  const { classes } = useStyles();
  const widget = useSelector(selectActiveWidget);


  const actions = { //TODO mb implement normal actions mock
    addDirectToCart: () => {},
    addDirectToCheckout: () => {},
    addToCart: () => {},
    fetchProduct: () => {
      return {} as ShopifyProduct
    },
  }

  const [viewport, setViewport] = useState<HTMLDivElement | null>(null)



  const {dataRetrieval, isPreparing: isPreparingDataRetrieval} = useWidgetPreviewDataRetrieval()

  const consumerWidget: WidgetTypeFromLib | null = widget ? {
    ...widget,
    videoCount: dataRetrieval.videoCount,
  } : null


  return (
    <Box className={classes.root}>
      <ViewManager
        mobile={
          <Flex sx={{ flexGrow: 1 }} justify="center">
            <MobileView getViewport={setViewport} >
              {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
              {/*   @ts-ignore */}
              {viewport && !isPreparingDataRetrieval && <Widget
                widgetId={widget?.id ?? 'pseudoId'}
                widget={consumerWidget}
                cart={undefined}
                actions={actions}
                platformMode="mobile"
                viewPort={viewport}
                dataRetrieval={dataRetrieval}
              />}
            </MobileView>
          </Flex>
        }
        desktop={
          <DesktopView getViewport={setViewport} >
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/*   @ts-ignore */}
            {viewport && !isPreparingDataRetrieval &&  <Widget
              widgetId={widget?.id ?? 'pseudoId'}
              widget={consumerWidget}
              cart={undefined}
              actions={actions}
              platformMode="desktop"
              viewPort={viewport}
              dataRetrieval={dataRetrieval}
            />}
          </DesktopView>
        }
      />
    </Box>
  );
};
