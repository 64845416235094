import { Button } from '@mantine/core';
import styles from './SelectedWidgetsManager.module.scss';
import { ReactComponent as IconDelete } from '../../../shared/icons/delete.svg';
import cn from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { useDisclosure } from '@mantine/hooks';
import { useMemo } from 'react';
import { selectSelectedWidgets } from '../selectors';
import { ConfirmDeletionModal } from 'src/components/Library/ConfirmDeletionModal';
import { widgetsActions } from 'src/components/Widget/store';
import { getPluralWord } from 'src/shared/lib/getPluralWord';
import { CounterAndSelection } from 'src/shared/ui/CounterAndSelection/CounterAndSelection';
import { BulkActions } from './BulkActions/BulkActions';

import type { FC } from 'react';
import { Widget } from '../types';
import {useDeleteWidget} from "../../../shared/hooks/tanstackQuery/useDeleteWidget";

interface SelectedWidgetsManagerProps {
  filteredWidgets: Widget[]
}

export const SelectedWidgetsManager:FC<SelectedWidgetsManagerProps> = (props) => {
  const { filteredWidgets } = props;

  const dispatch = useDispatch();
  const selectedWidgets = useSelector(selectSelectedWidgets);
  const [confirmationModalState, setConfirmationModalState] = useDisclosure(false);
  const {mutate: deleteWidgets} = useDeleteWidget({
    onSuccess: () => {
      setConfirmationModalState.close();
      dispatch(widgetsActions.clearSelectedWidgetsList());
    }
  })
  const deleteHandler = () => {
    deleteWidgets({id: selectedWidgets})
  };

  const cancelHandler = () => {
    dispatch(widgetsActions.clearSelectedWidgetsList());
  };

  const cancelBtnClasses = cn(styles.btn, styles.btn__cancel);
  const deleteBtnClasses = cn(styles.btn, styles.btn__delete);
  const deleteIconClasses = cn(styles.icon, styles.icon__delete);

  const modalBodyText = useMemo(
    () =>
      `Are you sure you want to delete ${selectedWidgets.length} ${getPluralWord(
        'widget',
        selectedWidgets.length
      )}?`,
    [selectedWidgets.length]
  );

  const handleSelectAll = () => {
    const widgetsIds = filteredWidgets.map((widget) => widget.id);
    dispatch(widgetsActions.selectWidgets(widgetsIds));
  }

  return (
    <>
      <div className={styles.container}>
        <CounterAndSelection
          allLength={filteredWidgets.length}
          selectedLength={selectedWidgets.length}
          onSelectAll={handleSelectAll}
          onClearAll={() => dispatch(widgetsActions.clearSelectedWidgetsList())}
        />

        <div className={styles.btn__container}>
          {/* <Button className={styles.btn}>
            <IconDuplicate className={styles.icon} />
          </Button> */}
          <Button className={deleteBtnClasses} onClick={setConfirmationModalState.open}>
            <IconDelete className={deleteIconClasses} />
          </Button>
          <Button className={cn(styles.btn, styles.btn__cancel)} onClick={cancelHandler}>
            Cancel
          </Button>

          <BulkActions />
        </div>
      </div>
      <ConfirmDeletionModal
        open={confirmationModalState}
        onClose={setConfirmationModalState.close}
        onConfirm={deleteHandler}
        onCancel={setConfirmationModalState.close}
        bodyText={modalBodyText}
        title="Remove widgets"
      />
    </>
  );
};
