import { useContext, useEffect, useState } from 'react';
import {useNavigate, useNavigation, useSearchParams} from 'react-router-dom';
import styles from './NewWidget.module.scss';
import { WidgetCreationHeader } from 'src/components/Widget/WidgetCreationHeader/WidgetCreationHeader';
import { WidgetTypeStep } from 'src/components/Widget/Steps/WidgetType/WidgetTypeStep';
import { PageTypeStep } from 'src/components/Widget/Steps/PageType/PageTypeStep';
import { SetupStep } from 'src/components/Widget/Steps/Setup/SetupStep';
import { StepsContext } from './StepsContext';
import { Box } from '@mantine/core';
import { useDispatch } from 'react-redux';
import { widgetsActions } from 'src/components/Widget/store';
import { SetupTabs } from 'src/components/Widget/types';
import { videoListActions } from 'src/components/Library/store';
import { WidgetViewType } from 'src/components/Widget/types';
import { isEnum } from 'src/shared/helpers/typescript';
import {useGetWidgets} from "../../../shared/hooks/tanstackQuery/useGetWidgets";

export const NewWidget = () => {
  const {data: widgetsPages} = useGetWidgets()
  const widgets = widgetsPages?.pages.flat(2)
  const [searchParams] = useSearchParams();

  const dispatch = useDispatch();

  const { currentStep, changeStep } = useContext(StepsContext);
  const [currentTab, setCurrentTab] = useState<SetupTabs>(SetupTabs.videos);

  const changeTab = (tab: SetupTabs) => setCurrentTab(tab);

  useEffect(() => {
    return () => {
      dispatch(widgetsActions.setActiveWidget(null));
    };
  }, [dispatch]);

  useEffect(() => {

    const defaultType = searchParams.get('defaultType');
    if (defaultType && isEnum(defaultType, WidgetViewType)) {
      setWidget(defaultType);
    }
  }, []);

  const setWidget = (val: WidgetViewType) => {
    dispatch(widgetsActions.setNewWidget({ key: 'viewType', value: val }));
    dispatch(widgetsActions.setNewWidget({ key: 'name', value: `New Widget ${(widgets?.length || 0) + 1}`,}));
    changeStep(2);
  };

  const navigation = useNavigate()

  const onWidgetCreated = (props: {id: string}) => {
    navigation(`/widgets/edit/${props.id}?defaultTab=${SetupTabs.videos}`)
  }

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return <WidgetTypeStep onSelect={setWidget} />;
      case 2:
        return <PageTypeStep onCreated={onWidgetCreated} />;
      case 3:
        return <SetupStep currentTab={currentTab} changeTab={changeTab} />;
      default:
        return null;
    }
  };
  return (
    <div className={styles.container}>
      {currentStep !== 1 && (
        <WidgetCreationHeader openInstallTab={() => setCurrentTab(SetupTabs.installation)} />
      )}
      <div className={styles.pageContent}>
        <Box
          sx={() => ({
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            overflow: 'hidden',
            boxSizing: 'border-box',
          })}
        >
          {renderStep()}
        </Box>
      </div>
    </div>
  );
};
