import { Checkbox } from '@mantine/core';

import { CardMenuDropdown } from 'src/components/CardMenuDropdown/CardMenuDropdown';

import styles from './WidgetCardInnerTop.module.scss'

import { FC } from 'react'
import { WidgetStatus } from 'src/components/Widget/types';

interface WidgetCardInnerTopProps {
  widgetId: string;
  widgetTitle: string;
  checked: boolean;
  status: WidgetStatus;
  onChecked: () => void;
  onDelete: () => void;
  onRename: (name: string) => void;
  onDuplicate: () => void;
  onPublish?: () => void;
  onUnpublish?: () => void;
}

export const WidgetCardInnerTop: FC<WidgetCardInnerTopProps> = (props) => {
  const { widgetId, widgetTitle, checked, status, onChecked, onDelete, onRename, onDuplicate, onUnpublish, onPublish } = props;

  return (
    <div className={styles.wrapper}>
      <div
        className={styles.inner}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Checkbox
          color="teal"
          className={styles.checkbox}
          checked={checked}
          size={16}
          onChange={onChecked}
        />

        <CardMenuDropdown
          itemId={widgetId}
          itemTitle={widgetTitle}
          showRename
          showInstall={status === WidgetStatus.notInstalled}
          showPublish={status === WidgetStatus.draft}
          showUnpublish={status === WidgetStatus.published}
          onRename={onRename}
          onDelete={onDelete}
          onDuplicate={onDuplicate}
          onPublish={onPublish}
          onUnpublish={onUnpublish}
        />
      </div>
    </div>
  )
}
