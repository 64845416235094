const maintenanceEndDateUTC = '2024-08-06T23:00:00Z'

export const getMaintenanceStatus = async () => {
  const isInMaintenance = false
  if (isInMaintenance) {
    const endDate = new Date(maintenanceEndDateUTC);

    return { isInMaintenance, endDate};
  }
  return null;
};

