import {Button} from 'antd';
import styles from './LoginPage.module.scss';
import google from '../../shared/icons/google.png';
import React, {useEffect, useRef} from 'react';
import Cookies from 'js-cookie';
import {baseURL} from '../../api/client';
import {Navigate, useLocation, useNavigate} from 'react-router-dom';
import {Loader} from '@mantine/core';
import {useLogin} from "../../shared/hooks/useLogin";

const GoogleIcon = () => <img src={google} className={styles.icon}/>;

export const LoginPage = () => {
  const location = useLocation();

  const {isLoggedIn} = useLogin();
  if (isLoggedIn) {
    return <Navigate to="/" state={{from: location}}/>
  }

  return (
    <div className={styles.root}>
      <Loader size={'xl'}/>
    </div>
  );
};

export const SignupPage = () => {
  return (
    <div className={styles.root}>
      <a href={`${baseURL}auth/google/connect`}>
        <Button size="large">
          <div className={styles.row}>
            <GoogleIcon/>
            <span>Continue with Google</span>
          </div>
        </Button>
      </a>
    </div>
  );
};