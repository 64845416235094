import {WidgetVideo, WidgetViewType} from 'src/components/Widget/types';
import {getOptimizedThumbnail} from "../helpers/getOptimizedThumbnail";

export const useWidgetCardThumbnail = (
  isCreatedManually: boolean,
  videos: WidgetVideo[],
  widgetViewType: WidgetViewType
) => {


  const widgetVideos = videos

  const templateArray =
    widgetViewType === WidgetViewType.carousel
      ? Array.from([0, 1, 2, 3])
      : Array.from([0, 1, 2, 3, 4]);

  const thumbnails: ({
    gifUrl?: string,
    webpUrl?: string,
    previewUrl?: string
  } | undefined)[] = templateArray.map((index) => {
    const video = widgetVideos[index];
    if (video) {
      const gif = video.gif ?? undefined;
      const preview = video.preview ?? undefined;

      const optimizedThumbnail = getOptimizedThumbnail(gif);
      return {
        gifUrl: optimizedThumbnail?.gifUrl,
        webpUrl: optimizedThumbnail?.webpUrl,
        previewUrl: preview
      }
    }


  });

  return thumbnails;
};
