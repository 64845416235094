import { ThemeIcon } from '@mantine/core'
import { IconProgressCheck } from '@tabler/icons-react'

import { OnboardingStepperList } from '../OnboardingStepperList/OnboardingStepperList'

import styles from './OnboardingStepperCollapsed.module.scss'

import { FC } from 'react'
import { OnboardingStep } from '../onboarding-stepper.interfaces'

interface OnboardingStepperCollapsedProps {
    steps: OnboardingStep[]
}

export const OnboardingStepperCollapsed: FC<OnboardingStepperCollapsedProps> = (props) => {
  const { steps } = props

  return (
    <div className={styles.wrapper}>
        <ThemeIcon variant="dark" size={30}>
            <IconProgressCheck size="1.2rem" />
        </ThemeIcon>

        <OnboardingStepperList className={styles.list} steps={steps} />
    </div>
  )
}
