import { combineReducers } from '@reduxjs/toolkit';
import { UserSlice } from 'src/components/User/store';
import { WidgetsSlice } from 'src/components/Widget/store';
import { videosListSlice } from '../components/Library/store';

export const rootReducer = combineReducers({
  videos: videosListSlice.reducer,
  widgets: WidgetsSlice.reducer,
  user: UserSlice.reducer,
});
