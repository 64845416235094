import {Card, Tooltip} from '@mantine/core';

import { WidgetCardInner } from './WidgetCardInner/WidgetCardInner'
import { WidgetCardButton } from './WidgetCardButton/WidgetCardButton'

import { WidgetCardProps } from './WidgetCard.types';

import React, { FC } from 'react';
import styles from './WidgetCard.module.scss';

export const WidgetCard:FC<WidgetCardProps> = (props) => {
  const {
    id,
    title,
    status,
    checked,
    type,
    page,
    videos,
    isCreatedManually,
    onChecked,
    onDelete,
    onDuplicate,
    onRename,
    editWidget,
    allPages,
    onUnpublish,
    onPublish,
  } = props

  return (
    <Card className={styles.wrapper} shadow="sm" padding="lg" onClick={editWidget}>
      <WidgetCardInner
        id={id}
        checked={checked}
        onChecked={onChecked}
        onDelete={onDelete}
        onRename={onRename}
        onDuplicate={onDuplicate}
        title={title}
        status={status}
        page={page}
        type={type}
        videos={videos}
        isCreatedManually={isCreatedManually}
        allPages={allPages}
        onUnpublish={onUnpublish}
        onPublish={onPublish}
      />

      <Tooltip label={title}>
        <div className={styles.title}>{title}</div>
      </Tooltip>

      <WidgetCardButton
        widgetId={id}
        title={title}
        status={status}
        onUnpublish={onUnpublish}
        onPublish={onPublish}
      />
    </Card>
  )
}
