import React, {useEffect, useMemo} from 'react';
import { Box, Text, Button } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconPlus, IconVideoPlus } from '@tabler/icons-react';
import { AddVideosModal } from './AddVideoModal';
import { useDispatch, useSelector } from 'react-redux';
import { widgetsActions } from 'src/components/Widget/store';
import { SelectedVideosList } from '../SelectedVideosList';
import {WidgetVideo} from "../../../../types";

interface OptionManualProps {
  videos: WidgetVideo[];
  widgetId: string;
}

export const OptionManual: React.FC<OptionManualProps> = ({videos, widgetId}) => {
  const [open, modalHandler] = useDisclosure(false);
  const dispatch = useDispatch();



  useEffect(() => {
    const videoIds = videos.map(v => v.id);
    dispatch(widgetsActions.checkVideo(videoIds));
  }, [videos, dispatch]);

  const onConfirm = (videoIds: WidgetVideo[]) => {
    dispatch(widgetsActions.setVideosToWidget(videoIds));
    dispatch(widgetsActions.clearSelectedWidgetsList());
    modalHandler.close();
  };
  const closeModal = () => {
    dispatch(widgetsActions.uncheckAllVideos());
    modalHandler.close();
  };
  const openModal = () => {
    modalHandler.open();
  };
  return (
    <>
      <Box mt={12} sx={{
        height: '100%',
      }}>
        <Text color="greyLight.2" mb={18}>
          Select videos from the Library and add them to your widget.
        </Text>
        <Button color="green.1" leftIcon={<IconVideoPlus size={16} />} onClick={openModal}>
          Add videos
        </Button>
        <Box sx={{
          marginTop: '28px',
          minHeight: '100%',
          display: 'flex',
          flex: 1,
          paddingRight: '48px'
        }}>
          {videos.length > 0 && <SelectedVideosList draggable canDelete videos={videos} emptyText="No videos tagged" />}
        </Box>
        <AddVideosModal
          open={open}
          onClose={closeModal}
          onCancel={closeModal}
          onConfirm={onConfirm}
        />
      </Box>
    </>
  );
};
