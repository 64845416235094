import { useMemo } from 'react';
import {useGetWidgets} from "./tanstackQuery/useGetWidgets";
import {Widget} from "../../components/Widget/types";

const getLengthErrorMessage = (stringLength: number) => {
  if (stringLength < 1) {
    return 'Name must be at least 1 characters long.';
  }
  if (stringLength > 256) {
    return 'Name cannot exceed 256 characters.';
  }
  return null;
};

export const useValidateWidgetNameInput = (value: string, id?: string) => {
  const {data: allWidgetsPages} = useGetWidgets();
  const allWidgets = allWidgetsPages?.pages.flat(2) as  Widget[]  || [];

  const lengthMessage = useMemo(() => getLengthErrorMessage(value.length), [value]);
  const nameExistenceMessage = useMemo(
    () =>
      allWidgets.some((w) => w.name === value && w.id !== id)
        ? 'This widget name is already used'
        : null,
    [value, allWidgets, id]
  );

  const error = [lengthMessage, nameExistenceMessage].find(
    (item) => typeof item === 'string'
  );
  return {
    error,
  };
};
