import { Box } from '@mantine/core';

import { WidgetCardGalleryCarousel } from './WidgetCardGalleryCarousel/WidgetCardGalleryCarousel'
import { WidgetCardGalleryStories } from './WidgetCardGalleryStories/WidgetCardGalleryStories'

import { useWidgetCardThumbnail } from 'src/shared/hooks/useWidgetCardThumbnail';

import styles from './WidgetCardInnerGallery.module.scss'

import { FC } from 'react'
import {WidgetVideo, WidgetViewType} from 'src/components/Widget/types';

interface WidgetCardInnerGalleryProps {
    isCreatedManually: boolean
    videos: WidgetVideo[]
    type: WidgetViewType
}

export const WidgetCardInnerGallery: FC<WidgetCardInnerGalleryProps> = (props) => {
    const { isCreatedManually, videos, type } = props;

    const thumbnails = useWidgetCardThumbnail(isCreatedManually, videos, type);

    return (
        <Box className={styles.wrapper}>
            {thumbnails.map((item, index) => {
                const previewUrl = item?.gifUrl || item?.previewUrl;
                return type === 'carousel' ? (
                    <WidgetCardGalleryCarousel
                        key={item?.gifUrl || index}
                        thumbnail={previewUrl}
                        thumbnailWebp={item?.webpUrl}
                    />
                ) : (
                    <WidgetCardGalleryStories
                        key={item?.gifUrl || index}
                        thumbnail={previewUrl}
                        thumbnailWebp={item?.webpUrl}
                    />
                );
            })}
        </Box>
    )
}
