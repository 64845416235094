import React, { forwardRef, HTMLAttributes, CSSProperties } from 'react';
import {WidgetVideo} from "../../../../../types";
import {RenderVideoCard} from "../interface";
import {Box} from "@mantine/core";
import {IconGripVertical} from "@tabler/icons-react";
import styles from './DraggableVideo.module.scss';

export type ItemProps = HTMLAttributes<HTMLDivElement> & {
  video: WidgetVideo;
  renderVideo: RenderVideoCard;
  isDragging?: boolean;
  withOpacity?: boolean;
};

const DraggableVideo = forwardRef<HTMLDivElement, ItemProps>(({ id, style,renderVideo,isDragging, ...props  }, ref) => {

  const video = props.video
  return <Box  ref={ref}  className={styles.videoCardWrapper} style={style} >
    <Box sx={{
      cursor: isDragging ? 'grabbing' : 'grab',
    }} className={styles.gripHandler} {...props}>
      <IconGripVertical className={styles.grip} color="#C1C2C5" size="fit-content" stroke={1.5}/>
    </Box>
    <Box sx={{
      boxShadow: isDragging  ? 'rgb(63 63 68 / 5%) 0px 2px 0px 2px, rgb(34 33 81 / 15%) 0px 2px 3px 2px' : 'rgb(63 63 68 / 5%) 0px 0px 0px 1px, rgb(34 33 81 / 15%) 0px 1px 3px 0px',
      transform: isDragging ? 'scale(1.05)' : 'scale(1)',
      opacity: props.withOpacity ? '0.5' : '1',
    }}
    className={styles.videoCard}
    >
    <Box className={styles.videoCard}>
      {renderVideo && renderVideo({
        video,
        isDragging,
      }) }
    </Box>
    </Box>
  </Box>;
});

export default DraggableVideo;
