import { ReactElement, useState, FC } from 'react';
import { ActionIcon, Box, Center, createStyles, Text } from '@mantine/core';
import { IconDeviceMobile, IconDeviceLaptop } from '@tabler/icons-react';
import { SegmentSwitch } from 'src/components/SegmentSwitch/SegmentSwitch';
import { HeadingAlignment } from 'src/components/Widget/types';
import { selectWidgetSettingsLayout } from 'src/components/Widget/selectors';
import { useSelector } from 'react-redux';
import { widgetSettingsInit } from 'src/components/Widget/store';
import { ArrowsDiagonal } from 'tabler-icons-react';
const mobileView =   {
  value: 'mobile',
  label: (
    <Center>
      <IconDeviceMobile size={18} />
      <Box ml={5}>Mobile</Box>
    </Center>
  ),
}
const desctopView =   {
    value: 'desktop',
    label: (
      <Center>
        <IconDeviceLaptop size={18} />
        <Box ml={5}>Desktop</Box>
      </Center>
    ),
  }

const useStyles = createStyles(() => ({
  root: {
    marginBottom: '25px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  wrapper: {
    width: '212px',
  },
}));

interface ViewManagerProps {
  mobile: ReactElement[] | ReactElement;
  desktop?: ReactElement[] | ReactElement;
}
export const ViewManager: FC<ViewManagerProps> = ({ mobile, desktop }) => {
  const { classes } = useStyles();
  const [view, setView] = useState('mobile');
  const views = []
  if (mobile) views.push(mobileView)
  if (desktop) views.push(desctopView)
  return (
    <>
      <Box className={classes.root}>
        <Box className={classes.wrapper}>
          <SegmentSwitch data={views} variant="secondary" value={view} onChange={setView} />
        </Box>
      </Box>
      {view === 'desktop' ? desktop : mobile}
    </>
  );
};
export const NoVideosDefined = () => {
  return <Text color="greyLight.2">Please define videos to make settings preview visible.</Text>;
};

const getContentHorizontalAlign = (position: HeadingAlignment) => {
  switch (position) {
    case HeadingAlignment.left:
      return 'flex-start';
    case HeadingAlignment.right:
      return 'flex-end';
    case HeadingAlignment.center:
    default:
      return 'center';
  }
};
const layoutHeadingFontWeight = {
  light: 300,
  regular: 400,
  bold: 700,
};
interface HeadingTextProps {
  paddingBottom: number;
  fontSize: number;
}
export const HeadingText: FC<HeadingTextProps> = ({ paddingBottom, fontSize }) => {
  const layout = useSelector(selectWidgetSettingsLayout) ?? widgetSettingsInit.layout;

  const headingFontWeight =
    layoutHeadingFontWeight[layout.headingFontWeight as keyof typeof layoutHeadingFontWeight];
  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: getContentHorizontalAlign(layout.headingAlignment),
        width: '100%',
        padding: '0 5px',
      }}
    >
      <Box
        sx={{
          //fontSize: `${layout.headingTextSize}pt`,
          fontSize: `${fontSize}pt`,
          fontWeight: headingFontWeight,
          color: layout.headingTextColor,
          paddingBottom,
          //paddingBottom: layout.headingBottomPadding,
        }}
      >
        {layout.headingText}
      </Box>
    </Box>
  );
};
