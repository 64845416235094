import {Accordion, Box, createStyles, Navbar, ActionIcon} from '@mantine/core';
import {useNavigate} from 'react-router-dom';
import styles from './LibraryLayout.module.scss';
import {useSelector} from 'react-redux';
import {filterVideos, getLinkStyles} from '../utils';
import {FC, useEffect, useMemo} from 'react';
import {useCheckInstagramConnection} from "../../../shared/hooks/useCheckInstagramConnection";
import {ReactComponent as AddGreenOutlineIcon} from 'src/shared/icons/addGreenOutlineButton.svg';
import {FacebookConnectWrapper} from "../../Facebook/FacebookConnectWrapper";
import {Typography} from "antd";
import {useInfiniteQuery, useQuery} from "@tanstack/react-query";
import {queryKey} from "../../../shared/query/keys";
import {getVideos, QueryVideoFilter} from "../../../api/video/getVideos";
import {getVideosCountForAllFilters} from "../../../api/video/getVideosCountForAllFilters";
import {useVideosCountForAllFilters} from "../../../shared/hooks/useVideosCountForAllFilters";

export enum SourceFilter {
  Instagram = 'Instagram',
  Upload = 'Upload',
}

export enum MainVideoFilter {
  Inbox = 'inbox',
  Shoppable = 'shoppable',
  All = 'all',
  Source = 'source',
}

export type SubFilter = SourceFilter;

export type VideoFilter = {
  type: MainVideoFilter;
  subFilter?: SubFilter[];
};

interface LibraryLayoutProps {
  isPartOfModal?: boolean;
  filterOnChange: (filter: VideoFilter) => void;
  currentFilter: VideoFilter;
  onSuccessfulInstagramConnection?: () => void;
}

const useStyles = createStyles(() => ({
  accordion__item: {
    borderBottom: 'none',
  },
  accordion__control: {
    padding: '0 8px 0 16px',
  },
  accordion__chevron: {
    transform: 'rotate(-90deg)',
    '&[data-rotate]': {
      transform: 'rotate(-180deg)'
    }
  },
  accordion__label: {
    display: 'flex',
    alignItems: 'center',
  },
  accordion__content: {
    padding: '0',
    paddingLeft: '16px',
  },
}));

export const LibraryLayout: FC<LibraryLayoutProps> = ({
                                                        isPartOfModal,
                                                        filterOnChange,
                                                        currentFilter,
                                                        onSuccessfulInstagramConnection
                                                      }) => {
  const navigate = useNavigate();


  const {data: videoCount} = useVideosCountForAllFilters();

  const {classes} = useStyles();
  const {instagram_token} = useCheckInstagramConnection({
    isNavigateToInbox: !isPartOfModal
  });
  const filterOnChangeHandler = (filter: MainVideoFilter, subFilter?: SourceFilter) => {
    filterOnChange({
      type: filter,
      subFilter: subFilter ? [subFilter] : [],
    });

  }


  return (
    <>
      <Navbar
        width={{sm: 240}}
        style={{
          padding: '0',
          borderBlockColor: '#c1c2c5',
          borderRight: '1px solid #2C2E33',
          zIndex: 1,
        }}
      >
        <Navbar.Section
          style={{background: '#1A1B1E', height: '60px', borderBottom: '1px solid #25262B'}}
        >
          <h1 className={styles.title}>Library</h1>
        </Navbar.Section>
        <Navbar.Section style={{background: '#373A40'}} grow>
          <div className={styles.filter}>
            <Box
              className={getLinkStyles(currentFilter, {
                type: MainVideoFilter.Inbox,
              })}
              onClick={() => {
                if (!isPartOfModal) {
                  navigate('/library/inbox');

                } else {
                  filterOnChangeHandler(MainVideoFilter.Inbox);
                }
              }}
            >
              <span>Inbox</span>
              <span>{videoCount?.INBOX}</span>
            </Box>
            <Box
              className={getLinkStyles(currentFilter, {
                type: MainVideoFilter.Shoppable,
              })}
              onClick={() => {
                if (!isPartOfModal) {
                  navigate('/library/shoppable');

                } else {
                  filterOnChangeHandler(MainVideoFilter.Shoppable);
                }
              }}
            >
              <span>Shoppable</span>
              <span>{videoCount?.SHOPPABLE}</span>
            </Box>
            <Accordion
              multiple
              defaultValue={['sources']}
              classNames={{
                chevron: classes.accordion__chevron,
                control: classes.accordion__control,
                item: classes.accordion__item,
                label: styles.filter__accordionLabel,
                content: classes.accordion__content,
              }}
            >
              <Accordion.Item value="sources">
                <Accordion.Control>
                  Sources
                </Accordion.Control>
                <Accordion.Panel>
                  <Box className={styles.accordingFilter}>
                    <Box onClick={() => filterOnChangeHandler(MainVideoFilter.Source, SourceFilter.Instagram)}
                         className={getLinkStyles(currentFilter || null, {
                           type: MainVideoFilter.Source,
                           subFilter: [SourceFilter.Instagram],
                         })}>
                      <Box sx={{
                        display: 'flex',
                        alignItems: 'center',

                        gap: '6.7px',
                      }}>
                        Instagram
                        {!instagram_token && (
                          <FacebookConnectWrapper onSuccessfulInstagramConnection={onSuccessfulInstagramConnection}>
                            <Box sx={{
                              display: 'flex',
                              height: '100%',
                              alignItems: 'center',
                              cursor: 'pointer',
                              marginTop: '2px',
                            }}>
                              <AddGreenOutlineIcon/>
                            </Box>
                          </FacebookConnectWrapper>
                        )}
                      </Box>
                      <span>{videoCount?.INSTAGRAM}</span>

                    </Box>
                    <Box onClick={() => filterOnChangeHandler(MainVideoFilter.Source, SourceFilter.Upload)}
                         className={getLinkStyles(currentFilter || null, {
                           type: MainVideoFilter.Source,
                           subFilter: [SourceFilter.Upload],
                         },)}>
                      Upload
                      <span>{videoCount?.UPLOAD}</span>
                    </Box>
                  </Box>
                </Accordion.Panel>
              </Accordion.Item>
            </Accordion>
            <Box
              className={getLinkStyles(currentFilter, {
                type: MainVideoFilter.All,
              })}
              onClick={() => {
                if (!isPartOfModal) {
                  navigate('/library/all');

                } else {
                  filterOnChangeHandler(MainVideoFilter.All);
                }
              }}
            >
              <span>All</span>
              <span>{videoCount?.ALL}</span>
            </Box>
          </div>
        </Navbar.Section>
      </Navbar>
    </>
  );
};
