import { SegmentedControl, SegmentedControlItem } from '@mantine/core';
import { ReactElement } from 'react';

interface SegmentSwitchProps<T extends SegmentedControlItem> {
  value: string;
  onChange: (val: T['value']) => void;
  data: T[];
  size?: 'md' | 'sm' | 'lg';
  variant?: 'primary' | 'secondary';
}

export const SegmentSwitch = <T extends SegmentedControlItem>({
  value,
  onChange,
  data,
  size = 'sm',
  variant = 'primary',
}: SegmentSwitchProps<T>): ReactElement<SegmentSwitchProps<T>> => {
  return (
    <SegmentedControl
      value={value}
      onChange={onChange}
      data={data}
      size={size}
      data-variant={variant}
    />
  );
};
