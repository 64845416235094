import React, {useEffect, useRef} from 'react';
import {MantleProvider, useMantle} from "@heymantle/react";
import {getEnv} from "../shared/lib/getEnv";
import {useDispatch, useSelector} from "react-redux";
import {userActions} from "../components/User/store";
import {selectUser} from "../components/User/selectors";
import {Box, Center, Loader, Text} from "@mantine/core";
import {exchangeLoginTokenForJwt} from "../api/exchangeLoginTokenForJwt";
import Cookies from "js-cookie";
import {Navigate, useLocation} from "react-router-dom";
import {useGetUser} from "../shared/hooks/tanstackQuery/useGetUser";

const mantleAppId = getEnv('REACT_APP_MANTLE_APP_ID') as string;

const CheckIsSubscribed: React.FC<{
  children: React.ReactNode
}> = ({children}) => {
  // current page location
  const location = useLocation();
  const isTariffPlans = location.pathname === '/pricing-plans';
  const {data: user} = useGetUser()
  const {subscription, loading} = useMantle()
  if (loading){
    return (
      <Center sx={{ height: '100vh' }}>
        <Loader variant="oval" size="lg" />
      </Center>
    )
  }
  const isActiveSubscribe = subscription?.active || !!user?.isSubscribe;
  const isNavigateToTariffPlans = !isActiveSubscribe && !isTariffPlans
  if(isNavigateToTariffPlans){
    return <Navigate to="/pricing-plans"  state={{ from: location }}  />;
  }
  return <>{children}</>;
}

export const RequireMantleAccessToken: React.FC<{
  children: React.ReactNode
}> = ({children}) => {
  // const dispatch = useDispatch();
  const {data: user, isLoading} = useGetUser()
  const location = useLocation();

  if (isLoading) {
    return (
      <Center sx={{ height: '100vh' }}>
        <Loader  />
      </Center>
    );
  }


  if(user?.mantleAccessToken) {
    return (
      <MantleProvider  customerApiToken={user.mantleAccessToken} appId={mantleAppId} i18n={{}}>
        <CheckIsSubscribed>
            {children}
        </CheckIsSubscribed>
      </MantleProvider>
    );
  }
  return <Navigate to="/unauthorized?type=mantl" state={{ from: location }}  />;
};
