import styles from './WidgetStartBlock.module.scss';
import carousel from 'src/shared/images/carousel.png';
import stories from 'src/shared/images/stories.png';
import { WidgetViewType } from 'src/components/Widget/types';
import { GradientButton } from 'src/components/GradientButton/GradientButtom';
import { FC } from 'react';
import {clsx} from "@mantine/core";

const widgetTypes = [
  {
    img: carousel,
    alt: 'type carousel',
    value: WidgetViewType.carousel,
    btnText: 'Carousel',
  },
  {
    img: stories,
    alt: 'type stories',
    value: WidgetViewType.stories,
    btnText: 'Stories',
  },
];

interface WidgetStartBlockProps {
    onSelect: (val: WidgetViewType) => void;
    marginTop?: boolean;
}

export const WidgetStartBlock: FC<WidgetStartBlockProps> = (props) => {
  const { onSelect, marginTop} = props;

  return (
    <div className={styles.container}>
      <h1 className={clsx(styles.header,{
        [styles.header__marginTop]: marginTop
      })}>To start creating a new widget select your widget type</h1>
      <div className={styles.types}>
        {widgetTypes.map((t) => (
          <div className={styles.type} key={t.value}>
            <div className={styles.type__preview}>
              <img src={t.img} alt={t.alt} />
            </div>
            <div className={styles.type__btnWrapper}>
              <GradientButton onClick={() => onSelect(t.value)}>{t.btnText}</GradientButton>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
