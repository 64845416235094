import { ChangeEvent, useState, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectActiveWidget, selectCreateWidget } from 'src/components/Widget/selectors';
import { widgetsActions } from 'src/components/Widget/store';
import { useValidateWidgetNameInput } from './useValidateWidgetNameInput';

export const useRenameWidget = () => {
  const dispatch = useDispatch();
  const activeWidget = useSelector(selectActiveWidget);
  const newWidget = useSelector(selectCreateWidget);

  const [renaming, setRenaming] = useState(false); // if true show textfield

  const defaultInputValue = useMemo(
    () => (newWidget ? newWidget.name : activeWidget?.name),
    [newWidget, activeWidget]
  );

  const [value, setValue] = useState(defaultInputValue ?? '');
  const { error } = useValidateWidgetNameInput(
    value,
    activeWidget?.id
  );

  const newWidgetConfirm = () => {
    dispatch(widgetsActions.setNewWidget({ key: 'name', value }));
    setRenaming(false);
  };
  const activeWidgetConfirm = () => {
    if (!activeWidget || error) return;
    dispatch(widgetsActions.setWidgetName({newName: value, widgetId: null}));
    setRenaming(false);
  };
  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };
  const onCancel = () => {
    setValue(defaultInputValue ?? '');
    setRenaming(false);
  };
  const onRename = () => {
    setRenaming(true);
  };

  useEffect(() => {
    if (!activeWidget?.name) return;
    setValue(activeWidget.name);
  }, [activeWidget]);
  useEffect(() => {
    if (defaultInputValue) {
      setValue(defaultInputValue);
    }
  }, [defaultInputValue]);
  return {
    disableConfirmButton: !!error,
    error,
    value,
    onChange,
    onCancel,
    onConfirm: newWidget ? newWidgetConfirm : activeWidgetConfirm,
    renaming,
    onRename,
  };
};
