import cn from 'classnames'

import { Accordion } from '@mantine/core'

import styles from './OnboardingStepperHeader.module.scss'

import { FC } from 'react'

interface OnboardingStepperHeaderProps {
  count: number
  passedCount: number
}

export const OnboardingStepperHeader: FC<OnboardingStepperHeaderProps> = (props) => {
  const { count, passedCount } = props

  return (
    <div>
      <Accordion.Control className={styles.control}>
        <div className={styles.inner}>
          <div className={styles.title}>Get Started</div>
          <div className={styles.counter}>{`${passedCount}/${count}`}</div>
        </div>
      </Accordion.Control>

      <div className={styles.progress}>
        {Array.from({ length: count }).map((_, index) => (
          <div
            key={index}
            className={cn(styles.progressItem, index < passedCount && styles.progressItem__passed)}
          />
        ))}
      </div>
    </div>
  )
}
